import React from 'react'

function SbAddBtn(props) {
    return (
        <div>
            <button
                type="button"
                className="btn btn-add-meeting"
                onClick={props.onClickEffect} >
                <div className="btn-inline-item d-flex">
                    <i className="fa fa-handshake-o" />
                    <h6>{props.bntName}</h6>
                </div>
            </button>
        </div>
    )
}

export default SbAddBtn
