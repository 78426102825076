import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
// import { travelizeAuth } from "../HeaderAuthentication";

export default function AddRole(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let subscriptionId = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [roleList, setRoleList] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [reportingRole, setReportingRole] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  useEffect(() => {
    fetchRoleDropdown();
  }, []);

  const fetchRoleDropdown = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
  };

  const addroles = () => {
    const roleDetails = {
      subscriptionID: subscriptionId,
      roleName: roleName,
      roleDesciption: roleDescription,
      reportingRoleID: reportingRole,
    };

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(roleDetails),
    };
    fetch(`${baseURL}/api/Role/AddRole`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShowAlert(true);
          setAlertMessage({
            alertType: "success",
            message: res.message,
          });
          setTimeout(() => {
            props.fetchRoleswithReporting();
            props.handleClose();
          }, 1000);
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: res.errorMessage,
          });
        }
      });
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD ROLE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="model-inputs">
              <div className="mb-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form add-input"
                  placeholder="Role Name"
                  onChange={(e) => setRoleName(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form add-input"
                  placeholder="Role Description"
                  onChange={(e) => setRoleDescription(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <Select
                  className="select-add-user-model"
                  options={roleList}
                  placeholder="Reporting to"
                  value={roleList.value}
                  onInputChange={roleList.label}
                  onChange={(data) => setReportingRole(data.value)}
                  isSearchable={true}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="model-save-button">
            <button
              onClick={addroles}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="model-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
