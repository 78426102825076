import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonMeetDetails() {
  return (
    <>
      <tr>
        <td>
          <p>
            <Skeleton />
          </p>
          <p>
            <Skeleton />
          </p>
          <p>
            <Skeleton />
          </p>
        </td>
        <td style={{ maxWidth: "220px" }}>
          <span>
            <p>
              <Skeleton />
            </p>
          </span>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <span>
            <p>
              <Skeleton />
            </p>
            <p>
              <Skeleton />
            </p>
          </span>
        </td>
        <td>
          <span className="d-flex">
            <p>
              <Skeleton />
            </p>
            &nbsp;
            <p>
              <Skeleton />
            </p>
          </span>
          <p>
            <Skeleton />
          </p>
          <p>
            <Skeleton />
          </p>
          <p>
            <Skeleton />
          </p>
          <p>
            <Skeleton />
          </p>
          <p>
            <Skeleton />
            <Skeleton />
          </p>
          <p>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
        </td>
        <td>
          <Skeleton />
        </td>
        <td style={{ maxWidth: "200px" }}>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <span className="meeting-detail-action-td">
            <Skeleton />
            <Skeleton />
          </span>
        </td>
      </tr>
    </>
  );
}

export default SkeletonMeetDetails;
