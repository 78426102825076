import React, { useState, useEffect } from "react";
import "../../styles/Claims.scss";
import profileimg from "../../images/profile-1.png";
import { Link } from "react-router-dom";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import {
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import AlertModel from "../AlertModel";

function PendingClaimByManager(props) {
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [pendingForApprovalData, setPendingForApprovalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [localClaimAlertMsg, setLocalClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [outStationClaimAlertMsg, setOutStationClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [foodClaimAlertMsg, setFoodClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [hotelClaimAlertMsg, setHotelClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [otherClaimAlertMsg, setOtherClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [MeetingID, setMeetingID] = useState("");
  const [totalbySection, setTotalbySection] = useState({
    localTotal: 0,
    totalHotel: 0,
    totalFood: 0,
    totalOutstation: 0,
    totalOthers: 0,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // GET MANAGER PENDING FOR APPROVAL CLAIM DETAILS START
  useEffect(() => {
    fetchManagerPendingApproval();
  }, [fromDate, toDate, PageSize, currentPage]);

  const fetchManagerPendingApproval = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/OSClaim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${props.claimUserId
      }&StatusID=42&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("CLAIM-PENDING-APPROVAL::", res.result);
        if (res.success) {
          setIsLoading(false);
          res.result.forEach((element) => {
            element.inputState = {
              chkSelectClaim: false,
              approvedFareClaimAmount: 0,
              fareClaimRemark: "",
              approvedLocalConveyanceClaimAmount: 0,
              localConveyanceClaimRemark: "",
              approvedFoodClaimAmount: 0,
              foodClaimRemark: "",
              approvedHotelClaimAmount: 0,
              hotelClaimRemark: "",
              approvedOthersClaimAmount: 0,
              othersClaimRemark: "",
              approvedTotalClaimAmount: 0,
              rejectedTotalClaimAmount: 0,
              totalAppliedClaimAmmount: element.totalAmount,
              remark: "",
              date: "",
              approvalType: 2, //Manager Prooval
              claimID: element.claimID,
            };
          });

          const localTotal = res.result.map((data) => data.localClaim.amount);
          const hotelTotal = res.result.map((data) => data.hotelClaim.amount);
          const foodTotal = res.result.map((data) => data.foodClaim.amount);
          const outstationTotal = res.result.map(
            (data) => data.fareClaim.amount
          );
          const othersTotal = res.result.map((data) => data.othersClaim.amount);
          // let sumLocal = localTotal.reduce((a, b) => a + b, 0);
          setTotalbySection({
            localTotal: localTotal.reduce((a, b) => a + b, 0),
            totalHotel: hotelTotal.reduce((a, b) => a + b, 0),
            totalFood: foodTotal.reduce((a, b) => a + b, 0),
            totalOutstation: outstationTotal.reduce((a, b) => a + b, 0),
            totalOthers: othersTotal.reduce((a, b) => a + b, 0),
            grandTotal: 0,
          });
          setPendingForApprovalData(res?.result);
          setPageCount(Math.ceil(res?.recordCount[0] / PageSize));
        } else {
          setPendingForApprovalData("");
          setIsLoading(false);
        }
      });
  };
  // GET MANAGER PENDING FOR APPROVAL CLAIM DETAILS END

  const setClaimNumber = (state, claimID) => {
    if (claimID != 0) {
      let claim = pendingForApprovalData.filter((x) => x.claimID == claimID);
      claim[0].inputState.chkSelectClaim = state;
      calclulateDefaultAmount(claim[0]);
    } else {
      pendingForApprovalData.forEach((claim) => {
        claim.inputState.chkSelectClaim = state;
        calclulateDefaultAmount(claim);
      });
    }
    setPendingForApprovalData([...pendingForApprovalData]);
  };

  const calclulateDefaultAmount = (claim) => {
    if (claim.inputState.chkSelectClaim) {
      claim.inputState.approvedFareClaimAmount = claim.fareClaim.amount;
      claim.inputState.approvedLocalConveyanceClaimAmount =
        claim.localClaim.amount;
      claim.inputState.approvedFoodClaimAmount = claim.foodClaim.amount;
      claim.inputState.approvedHotelClaimAmount = claim.hotelClaim.amount;
      claim.inputState.approvedOthersClaimAmount = claim.othersClaim.amount;
      claim.inputState.approvedTotalClaimAmount = claim.totalAmount;
      claim.inputState.rejectedTotalClaimAmount = 0;
    } else {
      claim.inputState.approvedFareClaimAmount = 0;
      claim.inputState.approvedLocalConveyanceClaimAmount = 0;
      claim.inputState.approvedFoodClaimAmount = 0;
      claim.inputState.approvedHotelClaimAmount = 0;
      claim.inputState.approvedOthersClaimAmount = 0;
      claim.inputState.approvedTotalClaimAmount = 0;
      claim.inputState.rejectedTotalClaimAmount = 0;
    }
  };

  const claimCalculation = (claim) => {
    claim.inputState.approvedTotalClaimAmount =
      claim.inputState.approvedFareClaimAmount +
      claim.inputState.approvedLocalConveyanceClaimAmount +
      claim.inputState.approvedFoodClaimAmount +
      claim.inputState.approvedHotelClaimAmount +
      claim.inputState.approvedOthersClaimAmount;
    claim.inputState.rejectedTotalClaimAmount =
      claim.totalAmount - claim.inputState.approvedTotalClaimAmount;
  };

  // input handlechange
  const handleInput = (value, name, claimid) => {
    let claim = pendingForApprovalData.filter(
      (data) => data.claimID == claimid
    );
    claim[0].inputState[name] = value;
    claimCalculation(claim[0]);
    setPendingForApprovalData([...pendingForApprovalData]);
  };
  // input handlechange

  const approveClaimAmount = (e) => {
    let singleClaimData = pendingForApprovalData.filter(
      (data) => data.inputState.chkSelectClaim == true
    );

    if (singleClaimData.length == 1) {
      if (
        singleClaimData[0].inputState.approvedFareClaimAmount <=
        singleClaimData[0].fareClaim.amount &&
        singleClaimData[0].inputState.approvedLocalConveyanceClaimAmount <=
        singleClaimData[0].localClaim.amount &&
        singleClaimData[0].inputState.approvedFoodClaimAmount <=
        singleClaimData[0].foodClaim.amount &&
        singleClaimData[0].inputState.approvedHotelClaimAmount <=
        singleClaimData[0].hotelClaim.amount &&
        singleClaimData[0].inputState.approvedOthersClaimAmount <=
        singleClaimData[0].othersClaim.amount
      ) {
        // if (
        //   singleClaimData[0].inputState.approvedLocalClaimAmount <
        //     singleClaimData[0].localClaim.amount &&
        //   singleClaimData[0].inputState.localClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedOSClaimAmount <
        //     singleClaimData[0].osClaim.amount &&
        //   singleClaimData[0].inputState.osClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedFoodClaimAmount <
        //     singleClaimData[0].foodClaim.amount &&
        //   singleClaimData[0].inputState.foodClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedHotelClaimAmount <
        //     singleClaimData[0].hotelClaim.amount &&
        //   singleClaimData[0].inputState.hotelClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedOthersClaimAmount <
        //     singleClaimData[0].othersClaim.amount &&
        //   singleClaimData[0].inputState.othersClaimRemark != ""
        // ) {
        // if (
        //   localClaimAlertMsg.message == "" &&
        //   outStationClaimAlertMsg.message == "" &&
        //   foodClaimAlertMsg.message == "" &&
        //   hotelClaimAlertMsg.message == "" &&
        //   otherClaimAlertMsg.message == ""
        // ) {
        let claimApprovedData = {
          date: new Date(),
          approvedFareClaimAmount:
            singleClaimData[0].inputState.approvedFareClaimAmount,
          approvedFareClaimRemark:
            singleClaimData[0].inputState.fareClaimRemark,
          approvedLocalClaimAmount:
            singleClaimData[0].inputState.approvedLocalConveyanceClaimAmount,
          approvedLocalClaimRemark:
            singleClaimData[0].inputState.localConveyanceClaimRemark,
          // OS Claim
          approvedOSClaimAmount: 0,
          // OS Claim
          approvedFoodClaimAmount:
            singleClaimData[0].inputState.approvedFoodClaimAmount,
          approvedFoodClaimRemark:
            singleClaimData[0].inputState.foodClaimRemark,
          approvedHotelClaimAmount:
            singleClaimData[0].inputState.approvedHotelClaimAmount,
          approvedHotelClaimRemark:
            singleClaimData[0].inputState.hotelClaimRemark,
          approvedOthersClaimAmount:
            singleClaimData[0].inputState.approvedOthersClaimAmount,
          approvedOthersClaimRemark:
            singleClaimData[0].inputState.othersClaimRemark,
          approvedTotalClaimAmount:
            singleClaimData[0].inputState.approvedTotalClaimAmount,
          rejectedTotalClaimAmount:
            singleClaimData[0].inputState.rejectedTotalClaimAmount,
          claimID: singleClaimData[0].inputState.claimID,
          approvalType: 2, // Manager Claim
          totalAppliedClaimAmmount:
            singleClaimData[0].inputState.totalAppliedClaimAmmount,
          remark: singleClaimData[0].inputState.remark,
        };
        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: travelizeAuth,
          body: JSON.stringify(claimApprovedData),
        };

        fetch(`${baseURL}/api/OSClaim/ApproveClaim`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              setShowAlert(true);
              setAlertMessage({
                alertType: "success",
                message: `Claim Approved ${res.message}`,
              });
              setTimeout(() => {
                props.fetchManagerClaimDetails();
                props.goBackToClaimfromPendingPage();
              }, 1000);
            } else {
              setShowAlert(true);
              setAlertMessage({
                alertType: "fails",
                message: res.errorMessage,
              });
            }
          })
          .catch((error) => console.error(error));
        // } else {
        //   setShowAlert(true);
        //   setAlertMessage({
        //     alertType: "fails",
        //     message: "Please enter remarks",
        //   });
        // }
      } else {
        setShowAlert(true);
        setAlertMessage({
          alertType: "fails",
          message: "Please clear the error",
        });
      }
    } else {
      let claimApprovedData = [];
      for (let i = 0; i < singleClaimData.length; i++) {
        if (
          singleClaimData[i].inputState.approvedLocalConveyanceClaimAmount <=
          singleClaimData[i].localClaim.amount &&
          singleClaimData[i].inputState.approvedFareClaimAmount <=
          singleClaimData[i].fareClaim.amount &&
          singleClaimData[i].inputState.approvedFoodClaimAmount <=
          singleClaimData[i].foodClaim.amount &&
          singleClaimData[i].inputState.approvedHotelClaimAmount <=
          singleClaimData[i].hotelClaim.amount &&
          singleClaimData[i].inputState.approvedOthersClaimAmount <=
          singleClaimData[i].othersClaim.amount
        ) {
          claimApprovedData.push({
            date: new Date(),
            approvedFareClaimAmount:
              singleClaimData[i].inputState.approvedFareClaimAmount,
            approvedFareClaimRemark:
              singleClaimData[i].inputState.fareClaimRemark,
            approvedLocalClaimAmount:
              singleClaimData[i].inputState.approvedLocalConveyanceClaimAmount,
            approvedLocalClaimRemark:
              singleClaimData[i].inputState.localConveyanceClaimRemark,
            approvedOSClaimAmount: 0,
            approvedOSClaimRemark: "",
            approvedFoodClaimAmount:
              singleClaimData[i].inputState.approvedFoodClaimAmount,
            approvedFoodClaimRemark:
              singleClaimData[i].inputState.foodClaimRemark,
            approvedHotelClaimAmount:
              singleClaimData[i].inputState.approvedHotelClaimAmount,
            approvedHotelClaimRemark:
              singleClaimData[i].inputState.hotelClaimRemark,
            approvedOthersClaimAmount:
              singleClaimData[i].inputState.approvedOthersClaimAmount,
            approvedOthersClaimRemark:
              singleClaimData[i].inputState.othersClaimRemark,
            approvedTotalClaimAmount:
              singleClaimData[i].inputState.approvedTotalClaimAmount,
            rejectedTotalClaimAmount:
              singleClaimData[i].inputState.rejectedTotalClaimAmount,
            claimID: singleClaimData[i].inputState.claimID,
            approvalType: 2, // Manager Claim
            totalAppliedClaimAmmount:
              singleClaimData[i].inputState.totalAppliedClaimAmmount,
            remark: singleClaimData[i].inputState.remark,
          });

          // console.log("APPROVE-SEND-DATA > 1:", claimApprovedData);

          let requestoption = {
            method: "POST",
            mode: "cors",
            headers: travelizeAuth,
            body: JSON.stringify(claimApprovedData),
          };

          fetch(`${baseURL}/api/OSClaim/BulkApproveClaim`, requestoption)
            .then((res) => res.json())
            .then((res) => {
              if (res.success) {
                setShowAlert(true);
                setAlertMessage({
                  alertType: "success",
                  message: `BulkClaim Approved ${res.message}`,
                });
                setTimeout(() => {
                  props.fetchManagerClaimDetails();
                  props.goBackToClaimfromPendingPage();
                }, 1000);
              } else {
                setShowAlert(true);
                setAlertMessage({
                  alertType: "fails",
                  message: res.errorMessage,
                });
              }
            })
            .catch((error) => console.error(error));
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: "Please clear the error",
          });
        }
      }
      e.preventDefault();
    }
  };

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  return (
    <div id="main-page">
      {props.pendingforApproval ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* LEADS TABLE START */}
            <div className="claims-table">
              <div className="seleting-menus">
                <div className="page-title claim-approval">
                  {/* PENDING FOR APPROVAL BY MANAGER */}
                  Pending for Approval by Manager
                </div>
                <div className="page-top-bar-dropdowns">
                  <div className="input-group from-to-date-select">
                    <span className="input-group-text" id="from-label">
                      From
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={toDate}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>

                    <span className="input-group-text" id="to-label">
                      To
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-search-track"
                    onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-user"
                    onClick={props.goBackToClaimfromPendingPage}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-arrow-left"> </i>
                      <h6>Back</h6>
                    </span>
                  </button>
                </div>
              </div>

              <div className="pendingfor-approval">
                <>
                  {isLoading ? (
                    <SkeletonPendingClaimHead />
                  ) : pendingForApprovalData !== "" &&
                    pendingForApprovalData !== null ? (
                    <div className="pendingapproval-records">
                      <div className="claim-user-details-img">
                        <img
                          className="pendingapproval-user-imgage"
                          src={profileimg}
                          alt="User Image"
                        />
                        <div className="pendingapproval-username-details">
                          <span>
                            <b>User Name :</b> &nbsp;
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>{pendingForApprovalData[0]?.fullName}</>
                            )}
                          </span>
                          <span>
                            <b>Email ID :</b> &nbsp;
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>{pendingForApprovalData[0]?.email}</>
                            )}
                          </span>
                          <span>
                            <b>Contact No.:</b> &nbsp;
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>
                                {pendingForApprovalData[0]?.countryCode}
                                {pendingForApprovalData[0]?.contact}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="claim-total-by-section1">
                        <div>
                          Total Outstation : {totalbySection.totalOutstation}
                        </div>
                        <div>Total Lodging : {totalbySection.totalHotel}</div>
                        <div>Total Food : {totalbySection.totalFood}</div>
                      </div>
                      <div className="claim-total-by-section2">
                        <div>Total Local : {totalbySection.localTotal}</div>
                        <div>Total Others : {totalbySection.totalOthers}</div>
                        <div>
                          Grand Total : &nbsp;
                          <b>
                            {totalbySection.localTotal +
                              totalbySection.totalHotel +
                              totalbySection.totalFood +
                              totalbySection.totalOutstation +
                              totalbySection.totalOthers}{" "}
                          </b>
                        </div>
                      </div>

                      <span className="claim-select-all">
                        Select All &nbsp;
                        <input
                          className="select-all-checkbox"
                          type="checkbox"
                          value=""
                          id="0"
                          onClick={(e) => {
                            setClaimNumber(e.target.checked, +0);
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
                <div className="table-responsive pendingapproval-scrollbar">
                  {isLoading ? (
                    <SkeletonPendingTableData />
                  ) : pendingForApprovalData !== "" &&
                    pendingForApprovalData !== null ? (
                    pendingForApprovalData?.map((data, i) => (
                      <table className="table pendingapproval-table">
                        <thead className="thead-light pendingapproval-thead">
                          <tr>
                            <th>
                              {i + 1}. Applied Date : &nbsp;
                              {moment(data?.updateDate).format("DD/MM/YYYY")}
                            </th>
                            <th>
                              Visit Purpose : &nbsp;
                              {data?.visitPurpose}
                            </th>
                            <th>
                              FromDate :{" "}
                              {moment(data?.fromDate).format("DD/MM/YYYY")}{" "}
                              &nbsp;
                            </th>
                            <th>
                              ToDate :{" "}
                              {moment(data?.toDate).format("DD/MM/YYYY")}
                            </th>
                            <th>No.Of Days : {data?.noOfDays}</th>
                            <th>
                              <input
                                className="select-checkbox"
                                type="checkbox"
                                onClick={(e) =>
                                  setClaimNumber(
                                    e.target.checked,
                                    data?.claimID
                                  )
                                }
                                checked={data?.inputState?.chkSelectClaim}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>
                              FromLocation : &nbsp;
                              {data?.fromLocation}
                            </th>
                            <th>
                              ToLocation : &nbsp;
                              {data?.toLocation}
                            </th>
                            <th>Company Name : {data?.clientName} &nbsp;</th>
                            <th>Contact Person : {data?.contactPerson}</th>
                            <th></th>
                            <th></th>
                          </tr>
                          <tr>
                            <th>Type</th>
                            <th>Attachment</th>
                            <th>Applied Amount</th>
                            <th>Approved Amount</th>
                            <th>Remarks</th>
                            <th></th>
                          </tr>
                        </thead>
                        {/* {data?.inputState?.claimID == data?.claimID && ( */}
                        <tbody>
                          <tr className="pendingapproval-row-data">
                            <td>{data?.fareClaim?.mot} Fare</td>
                            <td>
                              <i
                                className="fa fa-paperclip"
                                onClick={() => {
                                  setClaimAttachmentmodel(
                                    !claimAttachmentmodel
                                  );
                                  setAttachmentUrl(
                                    data?.fareClaim?.attachment ?? ""
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <>{data?.fareClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                className="form-control input-approve-amount"
                                type="number"
                                id={data?.claimID}
                                name="local"
                                value={
                                  data?.inputState?.approvedFareClaimAmount
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedFareClaimAmount",
                                    data?.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.fareClaim?.amount) {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.fareClaim?.amount) {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setOutStationClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <input
                                id={data?.claimID}
                                className="form-control form-control-sm remarks-td"
                                autoComplete="off"
                                type="text"
                                placeholder="Remarks"
                                value={data.inputState.fareClaimRemark}
                                onChange={(e) =>
                                  handleInput(
                                    e.target.value,
                                    "fareClaimRemark",
                                    data.claimID
                                  )
                                }
                              />
                              {outStationClaimAlertMsg?.claimId ==
                                data?.claimID ? (
                                <span className="error-input-message">
                                  {data.inputState.fareClaimRemark == "" ||
                                    data?.fareClaim?.amount <
                                    data?.inputState?.approvedFareClaimAmount
                                    ? outStationClaimAlertMsg?.message
                                    : ""}
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Local Conveyance </td>
                            <td>
                              <i
                                className="fa fa-paperclip"
                                onClick={() => {
                                  setClaimAttachmentmodel(
                                    !claimAttachmentmodel
                                  );
                                  setAttachmentUrl(
                                    data?.localClaim?.attachment ?? ""
                                  );
                                }}
                              />
                            </td>
                            <td>{data?.localClaim?.amount}</td>
                            <td>
                              <input
                                type="number"
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                name="outStation"
                                value={
                                  data.inputState
                                    .approvedLocalConveyanceClaimAmount
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedLocalConveyanceClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.localClaim?.amount) {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.localClaim?.amount) {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setLocalClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={
                                    data.inputState.localConveyanceClaimRemark
                                  }
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "localConveyanceClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {localClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data.inputState
                                      .localConveyanceClaimRemark == "" ||
                                      data?.localClaim?.amount <
                                      data?.inputState
                                        ?.approvedLocalConveyanceClaimAmount
                                      ? localClaimAlertMsg?.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Food</td>
                            <td>
                              <i
                                className="fa fa-paperclip"
                                onClick={() => {
                                  setClaimAttachmentmodel(
                                    !claimAttachmentmodel
                                  );
                                  setAttachmentUrl(
                                    data?.foodClaim?.attachment ?? ""
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <>{data?.foodClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                type="number"
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                name="food"
                                value={data.inputState.approvedFoodClaimAmount}
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedFoodClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.foodClaim?.amount) {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.foodClaim?.amount) {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setFoodClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={data.inputState.foodClaimRemark}
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "foodClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {foodClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data?.inputState?.foodClaimRemark == "" ||
                                      data?.foodClaim?.amount <
                                      data.inputState.approvedFoodClaimAmount
                                      ? foodClaimAlertMsg?.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Lodging</td>
                            <td>
                              <i
                                className="fa fa-paperclip"
                                onClick={() => {
                                  setClaimAttachmentmodel(
                                    !claimAttachmentmodel
                                  );
                                  setAttachmentUrl(
                                    data?.hotelClaim?.attachment ?? ""
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <>{data?.hotelClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                type="number"
                                name="hotel"
                                value={data.inputState.approvedHotelClaimAmount}
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedHotelClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.hotelClaim?.amount) {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.hotelClaim?.amount) {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setHotelClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={data.inputState.hotelClaimRemark}
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "hotelClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {hotelClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data?.inputState?.hotelClaimRemark == "" ||
                                      data?.hotelClaim?.amount <
                                      data?.inputState?.approvedHotelClaimAmount
                                      ? hotelClaimAlertMsg.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Others</td>
                            <td>
                              <i
                                className="fa fa-paperclip"
                                onClick={() => {
                                  setClaimAttachmentmodel(
                                    !claimAttachmentmodel
                                  );
                                  setAttachmentUrl(
                                    data?.othersClaim?.attachment ?? ""
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <> {data?.othersClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                type="number"
                                name="others"
                                value={
                                  data.inputState.approvedOthersClaimAmount
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedOthersClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (
                                    value > data?.othersClaim?.amount
                                  ) {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (
                                    value < data?.othersClaim?.amount
                                  ) {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setOtherClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={data.inputState.othersClaimRemark}
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "othersClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {otherClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data?.inputState?.othersClaimRemark ==
                                      "" ||
                                      data?.othersClaim?.amount <
                                      data.inputState.approvedOthersClaimAmount
                                      ? otherClaimAlertMsg?.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          {/* <tr className="pendingapproval-row-data">
                            <td>Remarks</td>
                            <td colSpan={4}>
                              <input
                                id={data?.claimID}
                                className="form-control form-control-sm remarks-td"
                                autoComplete="off"
                                type="text"
                                placeholder="Remarks"
                                value={data.inputState.remark}
                                onChange={(e) =>
                                  handleInput(
                                    e.target.value,
                                    "remark",
                                    data.claimID
                                  )
                                }
                              />
                              {data?.totalAmount !==
                              data?.inputState?.approvedTotalClaimAmount ? (
                                data?.inputState?.remark === "" ? (
                                  <span className="error-input-message">
                                    Please enter remarks
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </td>
                          </tr> */}
                          <tr className="pendingapproval-row-data total-value">
                            <td></td>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td>
                              <b>{data?.totalAmount}</b>
                            </td>
                            <td>
                              <b>
                                {data?.inputState?.approvedTotalClaimAmount}
                              </b>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                        {/* )} */}
                      </table>
                    ))
                  ) : (
                    "------No Data Found------"
                  )}
                </div>
              </div>
              <div className="download-and-pagination">
                <button
                  type="button"
                  className="btn btn-download-excel"
                // onClick={exportExcelClaim}
                >
                  <i className="fa fa-download" /> &nbsp; Download
                </button>
                <button
                  type="button"
                  className="btn btn-approve-claim"
                  onClick={approveClaimAmount}
                >
                  <i className="fa fa-check" /> &nbsp; Approve
                </button>
                <span className="user-pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />
                </span>
              </div>
            </div>
            {/* PENDING CLAIM MANAGER TABLE END */}

            {/* PAGE CONTENT END*/}

            <>
              {claimAttachmentmodel ? (
                <ViewClaimAttachmentmodel
                  claimAttachmentmodel={claimAttachmentmodel}
                  handleCloseviewattachment={handleCloseviewattachment}
                  attachmentUrl={attachmentUrl}
                />
              ) : (
                ""
              )}
            </>
          </div>
        </>
      ) : (
        ""
      )}
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
export default PendingClaimByManager;
