import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Account.scss";
import { Link } from "react-router-dom";
// import { travelizeAuth } from "../HeaderAuthentication";
import { baseURL } from "../../BaseUrl";
import moment from "moment";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import { Button } from "react-bootstrap";

function AccountDetails() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);

  let subscribeDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let subscriptionId = subscribeDetails?.subscriptionId;

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchSubscriptionDeatils();
  }, []);

  const fetchSubscriptionDeatils = () => {
    const requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscription?SubscriptionID=${subscriptionId}&PageSize=1&CurrentPage=1`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("SUBSCRIPTION-DETAILS:", res.result);
        if (res.success) {
          setSubscriptionDetails(res.result);
        } else {
          setSubscriptionDetails([]);
        }
      });
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole !== 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* LEADS TABLE START */}
                <div className="account-table">
                  <span className="seleting-menus">
                    <span className="account-page-title">ACCOUNT</span>
                  </span>

                  <div className="account-subscription-data-row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="current-subscription-part">
                          <div className="card subscription-card">
                            <div className="card-body">
                              <h5 className="card-title current-subscription">
                                CURRENT SUBSCRIPTION
                              </h5>
                              <span className="curr-sub-details">
                                <h6 className="subscription-details-head plan">
                                  Plan &nbsp;&nbsp;&nbsp;:
                                </h6>
                                <h6 className="subscription-details-data plan">
                                  {
                                    subscriptionDetails[0]?.subscriptionType
                                      ?.subscriptionName
                                  }
                                </h6>
                              </span>
                              <span className="curr-sub-details">
                                <h6 className="subscription-details-head no-of-users">
                                  No. Of Users &nbsp;&nbsp;&nbsp;:
                                </h6>
                                <h6 className="subscription-details-data plan">
                                  {subscriptionDetails[0]?.maxUserCount}
                                </h6>
                              </span>
                              <span className="curr-sub-details">
                                <h6 className="subscription-details-head sub-date">
                                  Subscription Date &nbsp;&nbsp;&nbsp;:
                                </h6>
                                <h6 className="subscription-details-data plan">
                                  {moment(
                                    subscriptionDetails[0]?.subscribedDate
                                  ).format("DD/MM/YYYY")}
                                </h6>
                              </span>

                              <span className="curr-sub-details">
                                <h6 className="subscription-details-head exp-date">
                                  Expiry Date &nbsp;&nbsp;&nbsp;:
                                </h6>
                                <h6 className="subscription-details-data plan">
                                  {moment(
                                    subscriptionDetails[0]?.expirydate
                                  ).format("DD/MM/YYYY")}
                                </h6>
                              </span>
                              <span className="curr-sub-details">
                                <h6 className="subscription-details-head days-left">
                                  Days Left &nbsp;&nbsp;&nbsp;:
                                </h6>
                                <h6 className="subscription-details-data plan">
                                  {subscriptionDetails[0]?.daysleft}
                                </h6>
                              </span>
                            </div>
                            <div className="subscribe-now-button mt-2">
                              {subscriptionDetails[0]?.daysleft < 30 ? (
                                <button className="btn btn-subscribe-now">
                                  SUBSCRIBE NOW
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {subscriptionId == "SUB0000000001" ? (
                          <div className="private-part-right">
                            <div className="create-subscription mb-2">
                              <p>{`${baseURL}/create/salesbee/subscription`}</p>
                              <Link
                                className="private-links"
                                target="_blank"
                                to={`/create/salesbee/subscription`}
                              >
                                CREATE SUBSCRIPTION
                              </Link>
                            </div>
                            <div className="user-activity mb-2">
                              <p>{`${baseURL}/useractivities`}</p>
                              <Link
                                className="private-links"
                                // target="_blank"
                                to={`/useractivities`}
                              >
                                USER ACTIVITY
                              </Link>
                            </div>
                            <div className="kmrefresh-meetings mb-2">
                              <p>{`${baseURL}/kmrefresh/meetings`}</p>
                              <Link
                                className="private-links"
                                // target="_blank"
                                to={`/kmrefresh/meetings`}
                              >
                                MEETING KILOMETER REFRESH
                              </Link>
                            </div>
                            <div className="kmrefresh-attendance mb-2">
                              <p>{`${baseURL}/kmrefresh/attendance`}</p>
                              <Link
                                className="private-links"
                                // target="_blank"
                                to={`/kmrefresh/attendance`}
                              >
                                ATTENDANCE KILOMETER REFRESH
                              </Link>
                            </div>
                            <div className="subscription-details mb-2">
                              <p>{`${baseURL}/allsubscriptiondetails`}</p>
                              <Link
                                className="private-links"
                                // target="_blank"
                                to={`/allsubscriptiondetails`}
                              >
                                ALL SUBSCRIPTION DETAILS
                              </Link>
                            </div>
                            <div className="add-county-state-city mb-2">
                              <p>{`${baseURL}/adddropdowndata`}</p>
                              <Link
                                className="private-links"
                                // target="_blank"
                                to={`/adddropdowndata`}
                              >
                                ADD COUNTRY STATE CITY
                              </Link>
                            </div>
                            <div className="google-apikeylist">
                              <p>{`${baseURL}/googleapikeylist`}</p>
                              <Link
                                className="private-links"
                                // target="_blank"
                                to={`/googleapikeylist`}
                              >
                                GOOGLE API HIT COUNTS
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* LEADS TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default AccountDetails;
