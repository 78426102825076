import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/Designation.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";

// import { travelizeAuth } from "../HeaderAuthentication";

export default function AddDesignation(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let subscriptionId = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [designation, setDesignation] = useState("");
  const [departmentId, setDepartmentId] = useState(0);
  const [reportingRole, setReportingRole] = useState(0);
  const [reportDesignation, setReportDesignation] = useState(0);
  const [designationDescription, setDesignationDescription] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  useEffect(() => {
    fetchDropdownsData();
  }, []);

  const fetchDropdownsData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return { value: data.departmentID, label: data.departmentName };
            })
          );
        } else {
          setDepartmentList([]);
        }
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
  };

  const addDesignation = () => {
    const designationDetails = {
      designationName: designation,
      departmentID: departmentId,
      role: {
        roleID: reportingRole,
      },
      reportingDesignationID: reportDesignation,
      subscriptionID: subscriptionId,
      description: designationDescription,
      status: enableStatus,
    };

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(designationDetails),
    };
    fetch(`${baseURL}/api/Designation/AddDesignation`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShowAlert(true);
          setAlertMessage({
            alertType: "success",
            message: res.message,
          });
          setTimeout(() => {
            props.handleClose();
            props.fetchDesignations();
          }, 1000);
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: res.errorMessage,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR:", error);
      });
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">ADD DESIGNATION</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-2">
                <label>Designation Name</label>
                <input
                  type="text"
                  className="form-control form add-basefare-input"
                  placeholder="Designation Name"
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </div>
              <div className="mb-2">
                <label>Department Name</label>
                <Select
                  className="select-add-user-model"
                  options={departmentList}
                  placeholder="Select Department"
                  value={departmentList.value}
                  onInputChange={departmentList.label}
                  onChange={(data) => setDepartmentId(data.value)}
                  isSearchable={true}
                />
              </div>
              <div className="mb-2">
                <label>Role Name</label>
                <Select
                  className="select-add-user-model"
                  options={roleList}
                  placeholder="Role"
                  value={roleList.value}
                  onInputChange={roleList.label}
                  onChange={(data) => setReportingRole(data.value)}
                  isSearchable={true}
                />
              </div>
              <div className="mb-2">
                <label>Designation Description</label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form add-input"
                  placeholder="Designation Description"
                  onChange={(e) => setDesignationDescription(e.target.value)}
                />
              </div>
              <div className="mb-2">
                <label>Reporting Designation</label>
                <Select
                  className="select-add-user-model"
                  options={designationList}
                  placeholder="Reporting Designation"
                  value={designationList.value}
                  onInputChange={designationList.label}
                  onChange={(data) => setReportDesignation(data.value)}
                  isSearchable={true}
                />
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="basefare-save-button">
            <button
              onClick={addDesignation}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="products-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
