import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Clients.scss";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import moment from "moment";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import {
  optionsMeetingStatusList,
  optionsMeetingTypeList,
} from "../DropdownsData";
import { SkeletonClientHistory } from "../Skeleton Loading/SkeletonClients";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ClientHistory(props) {
  const [clientHistoryDetails, setClientHistoryDetails] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [clientId, setClientId] = useState(props.clientId);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [meetingStatus, setMeetingStatus] = useState("Completed");
  const [meetingType, setMeetingType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  // PAGINATION END

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {

          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  useEffect(() => {
    fetchClientHistory();
  }, [
    filterUserId,
    PageSize,
    currentPage,
    toDate,
    fromDate,
    meetingType,
    meetingStatus,
  ]);

  //  CLIENT HISTORY TABLE START
  const fetchClientHistory = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/GetClientUserHistory?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&status=${meetingStatus}&type=${meetingType}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {

        if (res.success) {
          setClientHistoryDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setClientHistoryDetails("");
          setIsLoading(false);
        }
      });
  };
  //  MEETING PURPOSE TABLE END

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setMeetingType("");
    setMeetingStatus("");
  };

  const exportUserHistory = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientUserHistoryExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&status=${meetingStatus}&type=${meetingType}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ClientHistory.xlsx"
    );
  };

  return (
    <div id="main-page">
      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}

        {props.historyclientmodel ? (
          <div className={`main-body ${props.toggleshift.style}`}>
            <div className="page-content">
              {/* USER TABLE START */}
              <div className="clients-table">
                <div className="seleting-menus">
                  <div className="page-title">CLIENTS HISTORY</div>
                  <div className="page-top-bar-dropdowns">
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      options={userList}
                      placeholder="Select User"
                      value={filterUserId === "" ? "" : userList.value}
                      onInputChange={userList.label}
                      onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    // isClearable={true}
                    />

                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      // defaultValue={optionsRoleList[0].label}
                      options={optionsMeetingStatusList}
                      placeholder="Select Status"
                      value={
                        meetingStatus === ""
                          ? ""
                          : optionsMeetingStatusList.value
                      }
                      onInputChange={optionsMeetingStatusList.label}
                      onChange={(data) => setMeetingStatus(data.value)}
                      isSearchable={true}
                    // isClearable={true}
                    />

                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      // defaultValue={optionsRoleList[0].label}
                      options={optionsMeetingTypeList}
                      placeholder="Meeting Type"
                      value={
                        meetingType === "" ? "" : optionsMeetingTypeList.value
                      }
                      onInputChange={optionsMeetingTypeList.label}
                      onChange={(data) => setMeetingType(data.value)}
                      isSearchable={true}
                    // isClearable={true}
                    />

                    <div className="input-group from-to-date-select">
                      <span className="input-group-text" id="from-label">
                        From
                      </span>
                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={fromDate}
                          className="select from-date"
                          id="from-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          // startDate={fromDate}
                          endDate={toDate}
                          onChange={(date) => setFromDate(date)}
                          maxDate={new Date()}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>

                      <span className="input-group-text" id="to-label">
                        To
                      </span>
                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={toDate}
                          className="select to-date"
                          id="from-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          // startDate={fromDate}
                          endDate={toDate}
                          minDate={fromDate}
                          onChange={(date) => setToDate(date)}
                          maxDate={new Date()}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>
                    </div>

                    <button
                      type="button"
                      className="btn btn-search-track"
                      onClick={clearSerachBySelect}
                    >
                      <span className="btn-inline-item d-flex">
                        <i className="fa fa-eraser"> </i>
                        <h6>Clear</h6>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn btn-add-user"
                      onClick={props.goBacktoClients}
                      style={{ marginLeft: "5px" }}
                    >
                      <span className="btn-inline-item d-flex">
                        <i className="fa fa-arrow-left"> </i>
                        <h6>Back</h6>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="table-responsive clients-scroll">
                  <table className="table clients-list-table">
                    <thead className="thead-light clients-list-thead">
                      <tr>
                        <th>COMPANY NAME</th>
                        <th>DATE</th>
                        <th>USER NAME</th>
                        <th>CONTACT PERSON</th>
                        <th>LOCATION</th>
                        <th>MEETING TYPE</th>
                        <th>MOT</th>
                        <th>PURPOSE</th>
                        <th>REMARKS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          <SkeletonClientHistory />
                          <SkeletonClientHistory />
                          <SkeletonClientHistory />
                          <SkeletonClientHistory />
                        </>
                      ) : clientHistoryDetails !== "" ? (
                        clientHistoryDetails
                          .filter(
                            (data) =>
                              meetingType == "" ||
                              data.meetingType.includes(meetingType)
                          )
                          .map((data, i) => (
                            <tr key={i}>
                              <td>
                                <h6 className="fz13">{data?.clientName}</h6>
                              </td>
                              <td>{data?.meetingDate}</td>
                              <td>{data?.userFullName} </td>
                              <td>
                                {data?.contactPersonName}
                                <br />
                                {data?.clientContact}
                              </td>
                              <td style={{ maxWidth: "120px" }}>
                                {data?.clientLocation}
                              </td>
                              <td>{data?.meetingType}</td>
                              <td>{data?.travelBy}</td>
                              <td>{data?.purpose}</td>
                              <td style={{ maxWidth: "150px" }}>
                                <OverlayTrigger
                                  placement={"left"}
                                  delay={{ show: 250, hide: 300 }}
                                  overlay={
                                    <Tooltip id={`tooltip-remarks`}>
                                      {data?.remarks}
                                    </Tooltip>
                                  }
                                >
                                  <div>{data?.lessRemarks}</div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <h6 className="no-data-found">
                          ------ NO DATA FOUND ------
                        </h6>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* USER TABLE END */}

              <div className="download-and-pagination">
                <button
                  type="button"
                  className="btn btn-download-excel"
                  onClick={exportUserHistory}
                >
                  <i className="fa fa-download" /> &nbsp; Download
                </button>
                <span className="user-pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />
                </span>
              </div>
              {/* PAGE CONTENT END*/}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ClientHistory;
