import React, { useState, useEffect } from "react";
import "../src/styles/Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "./Components/BaseUrl";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelizeSalesBeeLogo from "./images/SalesBeeLogo.png";
import moving_img from "./images/Login_Page/laptop-motion.gif";
import travelize from "./images/Travelize_To_Salesbee.svg";
import InternetIssue from "./Components/InternetIssue";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [loginResponse, setLoginResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const navigateToDashboard = () => {
    // setTimeout(() => {
    navigate("/dashboard");
    window.location.reload()
    // }, 100);
  };

  const loginValidation = (e) => {
    if (email && password) {
      const userNamePassword = {
        UserName: email,
        Password: password,
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userNamePassword),
      };
      fetch(`${baseURL}/api/Authentication/Login`, requestoption)
        .then((res) => res.json())
        .then((res) => {

          if (res.success) {
            setLoginResponse(res.success);
            setLoginStatus(`Login ${res.message}`);
            sessionStorage.setItem("usdtls", JSON.stringify(res.result));

            //// CHECK SUBSCRIPTION EXPIRY
            let requestOptionS = {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Travelize_Authentication: res.result,
              },
            };
            fetch(
              `${baseURL}/api/DashBoard/GetLoginUserDetails`,
              requestOptionS
            )
              .then((res) => res.json())
              .then((res) => {
                const subscriptionExpiry = res?.result?.subscription?.daysleft;
                if (subscriptionExpiry === 0) {
                  navigate("/subscriptionexpiry");
                } else {
                  const userLoginDetails = res.result;
                  sessionStorage.setItem(
                    "usrlgndtls",
                    JSON.stringify(userLoginDetails)
                  );
                  navigateToDashboard();
                }
              })
              .catch((error) => {
                console.log("ERROR :", error);
                setErrorMessage(error.message);
              });
          } else {
            setLoginResponse(res.success);
            setLoginStatus(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setErrorMessage(error.message);
        });
    } else {
      setLoginStatus("Username/Password should not be empty!");
    }
    e.preventDefault();
  };

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div className="main-body" style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}>
            {/* <div className="sb-login-logo-div">
              <Image
                src={travelizeSalesBeeLogo}
                alt="Now Travelize is SalesBee"
                className="sb-login-logo"
              />
            </div> */}
            <div className="login-page-body">
              <Row className="sb-login-row">
                <Col md={5} className="sb-gif-col col-left">
                  <div className="w-100">
                    <div className="sb-login-logo-div">
                      <Image
                        src={travelizeSalesBeeLogo}
                        alt="Now Travelize is SalesBee"
                        className="sb-login-logo"
                      />
                    </div>
                    <div className="sb-signin-text">
                      <h3 className="login-head-text">Sign In</h3>
                      <p style={{ fontWeight: "600" }}>to access SalesBee</p>
                    </div>
                    <div className="sb-form-section">
                      <form onSubmit={loginValidation} className="login-form">
                        <div className="userName-input">
                          <input
                            type="email"
                            className="form-control login-email"
                            id="login-username"
                            placeholder="Username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="password-input">
                          <input
                            type={passwordType}
                            className="form-control login-password"
                            id="login-password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {passwordType === "password" ?
                            <i className="bi bi-eye-slash pass-hide-show" onClick={togglePassword}></i>
                            :
                            <i className="bi bi-eye pass-hide-show" onClick={togglePassword}></i>
                          }

                        </div>
                        {loginResponse ? (
                          <div align="center" className="pb-2">
                            <span className="login-msg success">{loginStatus}</span>
                          </div>
                        ) : (
                          <div align="center" className="pb-2">
                            <span className="login-msg false">{loginStatus}</span>
                          </div>
                        )}
                        <Row>
                          <Col md={6} className="p-0">
                            <div style={{ padding: "3% 0px" }}>
                              <Link to="/forgotpassword" className="forgot-pass-text">
                                Forgot Password?
                              </Link>
                            </div>

                          </Col>
                          <Col md={6} className="p-0">
                            <div className="forget-pass-login-button">
                              <Button type="submit" className="btn btn-login-button">
                                Login
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                  {/* <div className="login-page">
                    <div className="login-box">
                      <div className="w-100">
                        <h3 className="login-head-text">LOGIN</h3>
                        <form onSubmit={loginValidation} className="login-form">
                          <div className="userName-input">
                            <input
                              type="email"
                              className="form-control"
                              id="login-username"
                              placeholder="Username"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                          <div className="password-input">
                            <input
                              type="password"
                              className="form-control"
                              id="login-password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>

                          <div className="forget-pass-login-button">

                            <Button type="submit" className="btn btn-login-button">
                              Login
                            </Button>
                          </div>
                          <div style={{ padding: "3% 0px" }}>
                            <Link to="/forgotpassword" className="forgot-pass-text">
                              Forgot Password?
                            </Link>
                          </div>
                        </form>
                        {loginResponse ? (
                          <span className="login-msg success">{loginStatus}</span>
                        ) : (
                          <span className="login-msg false">{loginStatus}</span>
                        )}
                      </div>
                    </div>
                  </div> */}

                </Col>
                <Col md={2} className="col-right">
                  <div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                    <div className="travelize-log">
                      <Image
                        src={travelize}
                        alt="Now Travelize is SalesBee"
                        className="sb-trv-logo"
                      />
                    </div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                  </div>
                </Col>
                <Col md={5} className="sb-gif-col col-right">
                  <div>
                    <div className="sb-login-bgimg">
                      <Image
                        src={moving_img}
                        height="350"
                        alt="Now Travelize is SalesBee"
                        className="w-100"
                      />
                    </div>

                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
