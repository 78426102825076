import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";

function AddBaseFareModel(props) {
  const [MOTName, setMOTName] = useState("");
  const [baseFare, setBaseFare] = useState("");
  const [baseFareUnit, setBaseFareUnit] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [MOTType, setMOTType] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const addBaseFare = () => {
    if (baseFareUnit && baseFare && MOTName && MOTType) {
      const addBaseFareDetails = {
        motName: MOTName,
        baseFare: baseFare,
        motUnit: baseFareUnit,
        motType: MOTType,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(addBaseFareDetails),
      };
      fetch(`${baseURL}/api/Mot/AddMot`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setShowAlert(true);
            setAlertMessage({
              alertType: "success",
              message: res.message,
            });
            setTimeout(() => {
              props.handleClose();
              props.getBaseFarebyMOT();
            }, 2000);
          } else {
            setShowAlert(true);
            setAlertMessage({
              alertType: "fails",
              message: res.errorMessage,
            });
          }
        });
    } else {
      setShowAlert(true);
      setAlertMessage({
        alertType: "fails",
        message: "Please enter all fields",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">ADD BASEFARE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  placeholder="MOT Name"
                  onChange={(e) => setMOTName(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <input
                  type="number"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  placeholder="BaseFare"
                  onChange={(e) => setBaseFare(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={optionsMOTTypes}
                  placeholder="MOT Type"
                  value={optionsMOTTypes.value}
                  onInputChange={optionsMOTTypes.label}
                  onChange={(data) => setMOTType(data.value)}
                  isSearchable={true}
                />
              </div>

              <div className="mb-2">
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={optionsUnitList}
                  placeholder="Unit"
                  value={optionsUnitList.value}
                  onInputChange={optionsUnitList.label}
                  onChange={(data) => setBaseFareUnit(data.value)}
                  isSearchable={true}
                />
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="basefare-save-button">
            <button
              onClick={addBaseFare}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="products-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default AddBaseFareModel;
