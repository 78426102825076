import React, { useState, useRef } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Profile.scss";
import { Form } from "react-bootstrap";
import profileimg from "../../../images/profile-1.png";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth, salesbeeImgAuth } from "../HeaderAuthentication";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import AlertModel from "../../AlertModel";

function Profile() {
  var resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [contactNum, setContactNum] = useState(resPassData?.contact);
  const [companyName, setCompanyName] = useState(resPassData?.fullName);
  const [email, setEmail] = useState(resPassData?.email);
  const [imgFile, setImgFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [localImage, setLocalImage] = useState(null);

  const [alertMessageInner, setAlertMessageInner] = useState([
    {
      oldPassAlert: "",
      confirmPassAlert: "",
      successAlert: "",
    },
  ]);
  const [profileUpdateMessage, setProfileUpdateMessage] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };
  let navigate = useNavigate();

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  // console.log("IMAGE-FILE:", imgFile);

  //  ADDING PROFILE IMAGE BY USER START

  const updateProfileWithPhoto = () => {
    if (imgFile !== null && imgFile !== "") {
      const formData = new FormData();
      formData.append("image", imgFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Upload/UploadProfile?directory=Profile`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("IMAGE-USER-RES::", res);
          if (res.success) {
            var imgUrl = res.result[0];
            setShowAlert(true);
            setAlertMessage({
              alertType: "success",
              message: `Profil ${res.message}`,
            });
          } else {
            setShowAlert(true);
            setAlertMessage({
              alertType: "fails",
              message: `Profil ${res.errorMessage}`,
            });
          }
          UpdateProfileDetail(imgUrl);
        });
    } else {
      UpdateProfileDetail();
    }
    setFileName("");
  };
  //  ADDING PROFILE IMAGE BY USER END

  const UpdateProfileDetail = (imgUrl) => {
    const profileData = {
      FullName: companyName,
      Email: email,
      Contact: JSON.parse(contactNum),
      ProfilePic: imgUrl,
    };
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(profileData),
    };
    fetch(`${baseURL}/api/User/UpdateSelfProfile`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setProfileUpdateMessage(`Profile ${res.message}`);
          getLoginUserDetails();
          setImgFile("");
        } else {
          setProfileUpdateMessage(`Profile ${res.errorMessage}`);
          setImgFile("");
        }
      })
      .catch((error) => console.error(error));
  };

  const updatePassword = () => {
    if (oldPassword !== "" && password !== null && confirmPassword !== "") {
      if (password === confirmPassword) {
        const resetPassData = {
          oldCred: oldPassword,
          newCred: password,
        };
        // console.log("RESET-PASS-JSON:", resetPassData);
        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: travelizeAuth,
          body: JSON.stringify(resetPassData),
        };
        fetch(`${baseURL}/api/User/UpdateSelfPassword`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            // console.log("RESET-PASSWORD-RES::", res);
            if (res.success) {
              setAlertMessageInner({
                oldPassAlert: "",
                confirmPassAlert: "",
                successAlert: `${res.message}`,
              });
              setTimeout(() => navigate("/"), 2000);
            } else {
              setAlertMessageInner({
                oldPassAlert: `${res.errorMessage}`,
                confirmPassAlert: "",
                successAlert: "",
              });
            }
          });
      } else {
        setAlertMessageInner({
          oldPassAlert: "",
          confirmPassAlert: "New Password & Confirm Password must be same",
          successAlert: "",
        });
      }
    } else {
      setAlertMessageInner({
        oldPassAlert: "",
        confirmPassAlert: "Please fill the fields",
        successAlert: "",
      });
    }
  };

  const getLoginUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/DashBoard/GetLoginUserDetails`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("USER-LOGIN-DETAILS::", res.result);
        const userLoginDetails = res.result;
        sessionStorage.setItem("usrlgndtls", JSON.stringify(userLoginDetails));
      });
    setTimeout(() => setProfileUpdateMessage(""), 3000);
    navigate("/settings/profile");
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* LEADS TABLE START */}
                <div className="profile-table">
                  <span className="seleting-menus">
                    <span className="profile-page-title">PROFILE</span>
                  </span>

                  <div className="profile-edit-info-row">
                    <div className="row">
                      {/* PROFILE LEFT PART START */}
                      <div className="col-md-7 profile-left-part">
                        <div className="edit-info-password-part">
                          <div className="edit-info-head">
                            <h6>Edit Info</h6>
                          </div>
                          <div className="row edit-info">
                            <div className="col-md-4">
                              <div className="profile-image-section">
                                {localImage ? (
                                  <img
                                    src={localImage}
                                    alt="Uploaded Image"
                                    className="profile-page profile-img"
                                  />
                                ) : (
                                  <img
                                    className="profile-page profile-img"
                                    src={
                                      resPassData?.profilePic !== "" &&
                                      resPassData?.profilePic !== null &&
                                      resPassData?.profilePic !== "no_img.png"
                                        ? `${baseURL}/${resPassData?.profilePic}`
                                        : profileimg
                                    }
                                    alt="Profile Image"
                                  />
                                )}
                                <button
                                  type="button"
                                  className="btn btn-add-user-img-profile"
                                  onClick={uploadClick}
                                >
                                  <span className="change-profile-img d-flex">
                                    <i className="bi bi-pencil-fill"></i>
                                    <p>Change Picture</p>
                                  </span>
                                </button>
                                <input
                                  id="getFile"
                                  type="file"
                                  name="profileImage"
                                  accept="image/*"
                                  className="btn-primary upload"
                                  ref={hiddenFileInput}
                                  onChange={handleChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                              <small className="file-name-display">
                                {fileName}
                              </small>
                            </div>
                            <div className="col-md-8">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control edit-info-inputs"
                                  value={companyName}
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                />
                                <span
                                  className="input-group-text"
                                  id="user-icon"
                                >
                                  <i className="bi bi-person-fill"></i>
                                </span>
                              </div>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control edit-info-inputs"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                <span
                                  className="input-group-text"
                                  id="email-icon"
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </span>
                              </div>
                              <div className="input-group mb-3">
                                <input
                                  type="number"
                                  className="form-control edit-info-inputs"
                                  maxLength={12}
                                  value={contactNum}
                                  onChange={(e) =>
                                    setContactNum(e.target.value)
                                  }
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="phone-icon"
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </span>
                              </div>
                              <div className="form-group"></div>
                              <div className="input-group mb-3">
                                <button
                                  className="btn btn-success update"
                                  onClick={updateProfileWithPhoto}
                                >
                                  Update
                                </button>
                                {profileUpdateMessage != "" && (
                                  <small
                                    id="kilometerMsgSuccess"
                                    className="form-text text-muted"
                                  >
                                    {profileUpdateMessage}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* PROFILE LEFT PART END */}

                      {/* PROFILE RIGHT PART START */}
                      <div className="col-md-5 profile-right-part">
                        <div className="change-pass-part">
                          <div className="change-password-head">
                            <h6>Change Password</h6>
                          </div>
                          <div className="row change-password">
                            <div style={{ position: "relative" }}>
                              <div className="input-group mb-3">
                                <input
                                  type="password"
                                  className="form-control edit-info-inputs"
                                  value={oldPassword}
                                  placeholder="Old Password"
                                  onChange={(e) =>
                                    setOldPassword(e.target.value)
                                  }
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="user-icon"
                                >
                                  <i className="bi bi-key-fill"></i>
                                </span>
                              </div>

                              {alertMessageInner.oldPassAlert && (
                                <small
                                  id="inputFieldErrorMsg"
                                  className="form-text text-muted "
                                >
                                  {oldPassword != ""
                                    ? alertMessageInner.oldPassAlert
                                    : "Required !"}
                                </small>
                              )}
                            </div>
                            <div style={{ position: "relative" }}>
                              <div className="input-group mb-3">
                                <input
                                  type="password"
                                  className="form-control edit-info-inputs"
                                  placeholder="New Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="email-icon"
                                >
                                  <i className="bi bi-key-fill"></i>
                                </span>
                              </div>
                              {(alertMessageInner.oldPassAlert ||
                                alertMessageInner.confirmPassAlert) &&
                              password == "" ? (
                                <small
                                  id="inputFieldErrorMsg"
                                  className="form-text text-muted"
                                >
                                  Required !
                                </small>
                              ) : (
                                ""
                              )}
                            </div>

                            <div style={{ position: "relative" }}>
                              <div className="input-group mb-3">
                                <input
                                  type="password"
                                  className="form-control edit-info-inputs"
                                  placeholder="Confirm Password"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="phone-icon"
                                >
                                  <i className="bi bi-key-fill"></i>
                                </span>
                              </div>
                              {alertMessageInner.confirmPassAlert && (
                                <small
                                  id="inputFieldErrorMsg"
                                  className="form-text text-muted"
                                >
                                  {confirmPassword != ""
                                    ? alertMessageInner.confirmPassAlert
                                    : "Required !"}
                                </small>
                              )}
                            </div>

                            <div className="input-group mb-3">
                              <button
                                className="btn btn-success update"
                                onClick={(e) => updatePassword(e)}
                              >
                                Update Password
                              </button>
                              {alertMessageInner.successAlert && (
                                <small
                                  id="inputFieldErrorMsgSuccess"
                                  className="form-text text-muted"
                                >
                                  {alertMessageInner.successAlert}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* PROFILE RIGHT PART END */}
                    </div>
                  </div>
                </div>
                {/* LEADS TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default Profile;
