import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Users.scss";
import "../../styles/CommonStyle.scss";
import { Form } from "react-bootstrap";
import profileimg from "../../images/profile-1.png";
import { Link } from "react-router-dom";
import Addusermodel from "./Addusermodel";
import Editusermodel from "./Editusermodel";
import ReactPaginate from "react-paginate";
import ViewSubordinates from "./ViewSubbordinates";
import { baseURL } from "../BaseUrl";
import AddBulkUser from "./AddBulkUser";
import UserHistory from "./UserHistory";
// import { travelizeAuth } from "./HeaderAuthentication";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";

function Users() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [addusermodelshow, setAddusermodelshow] = useState(false);
  const [addBulkUserModel, setAddBulkUserModel] = useState(false);
  const [editusermodelshow, setEditusermodelshow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [activeUserCount, setActiveUserCount] = useState("");
  const [rolename, setRoleName] = useState("");
  const [activeStatus, setActiveStatus] = useState(2);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [subordinateActive, setSubordinateActive] = useState(false);
  const [userHistoryPage, setUserHistoryPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState(0);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const goBacktoUser = () => {
    setSubordinateActive(!subordinateActive);
  };

  const userHistoryView = () => {
    setUserHistoryPage(!userHistoryPage);
  };

  const handleClose = () => {
    setAddusermodelshow(!addusermodelshow);
  };

  const showEditModel = () => {
    setEditusermodelshow(!editusermodelshow);
  };

  const handleCloseBulkUserModel = () => {
    setAddBulkUserModel(!addBulkUserModel);
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return {
                value: data.userId,
                label: data.userFullName,
              };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return { value: data.departmentID, label: data.departmentName };
            })
          );
        } else {
          setDepartmentList([]);
        }
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
  };
  //* FETCH DROPDOWN DATA END *//

  //* GET USER DETAIL START  *//
  useEffect(() => {
    getUserDetails();
  }, [
    PageSize,
    currentPage,
    filterUserId,
    reportDesignation,
    departmentId,
    roleId,
    activeStatus,
  ]);

  const getUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/User/UserDetails?userId=${filterUserId}&ManagerID=${managerId}&DesignationID=${reportDesignation}&DepartmentID=${departmentId}&RoleID=${roleId}&enableStatus=${activeStatus}&pageSize=${PageSize}&currentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUsers(res.result);
          setTotalUserCount(res.recordCount[0]);
          setPageDataSize(res.result.length);
          setActiveUserCount(res.recordCount[1]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setUsers("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* GET USER DETAIL END *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerId("");
    setReportDesignation(0);
    setDepartmentId(0);
    setRoleId(0);
    setActiveStatus(2);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportUserDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/UserExcelExport?userId=${filterUserId}&ManagerID=${managerId}&DesignationID=${reportDesignation}&DepartmentID=${departmentId}&RoleID=${roleId}&enableStatus=${activeStatus}&pageSize=${PageSize}&currentPage=${currentPage}`,
      "Users.xlsx"
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <div className="top-and-sidebar">
                <Sidenavbar
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />

                <Topbar
                  toggleMenu={toggleMenu}
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
              </div>
              {/* MAIN BODY START */}

              {/* PAGE CONTENT START */}
              <div className="page-wrapper">
                {!subordinateActive && !userHistoryPage ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                      {/* USER TABLE START */}
                      <div className="user-table">
                        <div className="seleting-menus">
                          <div className="page-title">
                            USERS ({activeUserCount}){/* {totalUserCount} */}
                          </div>
                          <div className="page-top-bar-dropdowns">
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userList}
                              placeholder="Select User"
                              value={filterUserId == "" ? "" : userList.value}
                              onInputChange={userList.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              defaultValue={designationList[null]}
                              options={designationList}
                              placeholder="Designation"
                              value={
                                reportDesignation == 0
                                  ? ""
                                  : designationList.value
                              }
                              onInputChange={designationList.label}
                              onChange={(data) =>
                                setReportDesignation(data.value)
                              }
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={departmentList}
                              placeholder="Department"
                              value={
                                departmentId == 0 ? "" : departmentList.value
                              }
                              onInputChange={departmentList.label}
                              onChange={(data) => setDepartmentId(data.value)}
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={roleList}
                              placeholder="Select Role"
                              value={roleId == 0 ? "" : roleList.value}
                              onInputChange={roleList.label}
                              onChange={(data) => setRoleId(data.value)}
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={optionsStatusList}
                              placeholder="Select Status"
                              value={
                                activeStatus == 2 ? "" : optionsStatusList.value
                              }
                              onInputChange={optionsStatusList.label}
                              onChange={(data) => setActiveStatus(data.value)}
                              isSearchable={true}
                            />

                            <button
                              type="button"
                              className="btn btn-search-track"
                              onClick={clearSerachBySelect}
                            >
                              <span className="btn-inline-item d-flex">
                                <i className="fa fa-eraser"> </i>
                                <h6>Clear</h6>
                              </span>
                            </button>

                            <Select
                              className="react-select-container-list per-page"
                              classNamePrefix="react-select-list"
                              options={optionsPageSizeList}
                              placeholder="Show Per Page"
                              value={optionsPageSizeList.value}
                              onInputChange={optionsPageSizeList.label}
                              onChange={(data) => setPageSize(data.value)}
                              isSearchable={true}
                            />

                            <div className="dropdown add-single-bulk-user">
                              <button
                                className="btn btn-add-user"
                                id="adduser-single-blulk"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span className="btn-inline-item d-flex">
                                  <i className="fa fa-user-plus"> </i>
                                  <h6>Add User</h6>
                                </span>
                              </button>
                              <ul
                                className="dropdown-menu single-bulk-upload"
                                aria-labelledby="adduser-single-blulk"
                              >
                                <li>
                                  <button
                                    className="dropdown-item single-user"
                                    onClick={() =>
                                      setAddusermodelshow(!addusermodelshow)
                                    }
                                  >
                                    <i className="fa fa-user-plus" /> &nbsp;
                                    Single User
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item bulk-user"
                                    onClick={() =>
                                      setAddBulkUserModel(!addBulkUserModel)
                                    }
                                  >
                                    <i className="fa fa-users" /> &nbsp; Bulk
                                    Upload
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive users-scrollbar">
                          <table className="table users-list-table">
                            <thead className="thead-light users-list-thead">
                              <tr>
                                <th>NAME</th>
                                <th>CONTACT DETAILS</th>
                                <th>DESIGNATION</th>
                                <th>ROLE</th>
                                <th>DEPARTMENT</th>
                                <th>REPORTING</th>
                                <th>STATUS</th>
                                <th>ACTIONS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <>
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                </>
                              ) : users !== "" && users != null ? (
                                users.map((user, i) => (
                                  <tr className="users-list-row-data" key={i}>
                                    <td>
                                      <span className="users-list-name">
                                        <img
                                          className="table-data-users-imgages"
                                          src={profileimg}
                                          alt="John Doe"
                                        />
                                        <div>
                                          <div>
                                            <strong>Name : </strong>
                                            {user.fullName}
                                          </div>
                                          <div>
                                            <strong className="color-yellow">
                                              {user.code}
                                            </strong>
                                          </div>
                                        </div>
                                      </span>
                                    </td>

                                    <td style={{ maxWidth: "180px" }}>
                                      <div>
                                        <strong>Email : </strong>
                                        {user.email}
                                      </div>
                                      <div>
                                        <strong>Phone : </strong>
                                        {user.countryCode} &nbsp;
                                        {user.contact}
                                      </div>
                                    </td>
                                    <td>
                                      L{user?.desingation?.designationLevel}-{" "}
                                      {user?.desingation?.designationName}
                                    </td>
                                    <td>{user.desingation?.role?.roleName}</td>
                                    <td>{user.desingation?.departmentName}</td>
                                    <td>
                                      {user.hasSubordinates > 0 ? (
                                        <p className="subbordinate-link">
                                          {user?.managerName}
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setManagerId(user.userId);
                                              setManagerName(user.fullName);
                                              goBacktoUser();
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement={"right"}
                                              delay={{ show: 200, hide: 250 }}
                                              overlay={
                                                <Tooltip id={`tooltip-title`}>
                                                  View Subordinates
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                <i className="fa fa-users user-icon" />
                                              </span>
                                            </OverlayTrigger>
                                          </Link>
                                        </p>
                                      ) : (
                                        user.managerName
                                      )}
                                    </td>
                                    <td>
                                      {user.enableStatus == 1
                                        ? "Active"
                                        : "InActive"}
                                    </td>
                                    <td>
                                      <span className="user-action-td">
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            showEditModel();
                                            setEditUserId(user.userId);
                                          }}
                                          className="user-edit-link"
                                        >
                                          <i className="bi bi-pencil-fill"></i>{" "}
                                          Edit
                                        </Link>

                                        <Link
                                          to="#"
                                          className="user-view-link"
                                          onClick={() => {
                                            setEditUserId(user.userId);
                                            userHistoryView();
                                          }}
                                        >
                                          <i className="fa fa-eye"></i> History
                                        </Link>
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr className="no-data-found">
                                  <td colSpan={8}>
                                    ------ NO DATA FOUND ------
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* PAGINATION START */}
                        <div className="download-and-pagination">
                          <div className="data-count-per-page">
                            {pageDataFrom} - {pageDataTill} of {totalUserCount}
                          </div>
                          <button
                            type="button"
                            className="btn btn-download-excel"
                            onClick={exportUserDetails}
                          >
                            <i className="fa fa-download" /> &nbsp; Download
                          </button>
                          <span className="user-pagination">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel={
                                <i className="fa fa-angle-double-right" />
                              }
                              onPageChange={handlePageClick}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              pageCount={pageCount}
                              previousLabel={
                                <i className="fa fa-angle-double-left" />
                              }
                              renderOnZeroPageCount={null}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                              disabledClassName={"disabled"}
                            />
                          </span>
                        </div>
                        {/* PAGINATION END */}
                      </div>
                      {/* USER TABLE END */}

                      {/* PAGE CONTENT END*/}
                    </div>
                  </div>
                ) : (
                  <>
                    {subordinateActive ? (
                      <ViewSubordinates
                        roleId={roleId}
                        managerId={managerId}
                        managerName={managerName}
                        toggleshift={toggleshift}
                        goBacktoUser={goBacktoUser}
                        subordinateActive={subordinateActive}
                      />
                    ) : (
                      <>
                        {userHistoryPage ? (
                          <UserHistory
                            userHistoryView={userHistoryView}
                            toggleshift={toggleshift}
                            userId={editUserId}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}
                <>
                  {/* ADD USER MODEL START */}
                  {addusermodelshow ? (
                    <Addusermodel
                      getUserDetails={getUserDetails}
                      addusermodelshow={addusermodelshow}
                      handleClose={handleClose}
                    />
                  ) : (
                    ""
                  )}

                  {/* ADD USER MODEL END */}

                  {/* ADD BULK USER MODEL START */}
                  {addBulkUserModel ? (
                    <AddBulkUser
                      addBulkUserModel={addBulkUserModel}
                      handleCloseBulkUserModel={handleCloseBulkUserModel}
                    />
                  ) : (
                    ""
                  )}
                  {/* ADD BULK USER MODEL END */}

                  {/* EDIT USER MODEL START */}
                  {editusermodelshow ? (
                    <Editusermodel
                      userID={editUserId}
                      getUserDetails={getUserDetails}
                      editusermodelshow={editusermodelshow}
                      showEditModel={showEditModel}
                    />
                  ) : (
                    ""
                  )}
                  {/* EDIT USER MODEL END */}
                </>
                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Users;
