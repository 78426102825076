import React, { useState, useEffect, useRef } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import { Image } from "react-bootstrap";
import Viewmeetgattachmodel from "./Viewmeetgattachmodel";
import TrackuserRoute from "./TrackuserRoute";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import SkeletonMeetDetails from "../Skeleton Loading/SkeletonMeetDetails";
import bikeicon from "../../images/bike.svg";
import caricon from "../../images/car.svg";
import busicon from "../../images/bus.svg";
import autoicon from "../../images/auto.svg";
import ReactPaginate from "react-paginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InternetIssue from "../InternetIssue";

function Meetingdetails(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [meetingattachmentmodel, setMeetingattachmentmodel] = useState(false);
  const [viewonuserroute, setViewonuserroute] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [userList, setUserList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [filterUserId, setFilterUserId] = useState(props.userID);
  const [fromDate, setFromDate] = useState(new Date(props.meetingdetailsDate));
  const [toDate, setToDate] = useState(new Date(props.meetingdetailsDate));
  const [stateId, setStateId] = useState(0);
  const [meetingId, setMeetingId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseviewattachment = () => setMeetingattachmentmodel(false);

  const goBacktomeetingsdetails = () => {
    setViewonuserroute(!viewonuserroute);
  };

  const tableRef = useRef(null);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  // PAGINATION END

  const [selectedUserId, setSelectedUserId] = useState("");

  useEffect(() => {
    if (filterUserId) {
      userList.map((user) => {
        if (filterUserId == user.value) {
          setSelectedUserId(user);
        }
      });
    }
  }, [filterUserId, userList]);

  // FETCH SELECT DROPDOWN START
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Zone/GetStates`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN START

  //FETCH MEETING DETAILS START
  useEffect(() => {
    fetchMeetingDetailsByUser();
  }, [fromDate, toDate, filterUserId, stateId, PageSize, currentPage]);

  const fetchMeetingDetailsByUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetMeetingDetails?PageSize=${PageSize}&CurrentPage=${currentPage}&UserId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&stateId=${stateId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetingDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //FETCH MEETING DETAILS END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId(props.userID);
    setFromDate(new Date(props.meetingdetailsDate));
    setToDate(new Date(props.meetingdetailsDate));
    setStateId(0);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportMeetingDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingDetailExcelExport?UserID=${filterUserId}&MeetingID=${meetingId}&StateId=${stateId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `MeetingDetails-${moment(fromDate).format("DD/MMM/YYYY")}-${moment(
        toDate
      ).format("DD/MMM/YYYY")}.xlsx`
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {props.meetingdetails && !viewonuserroute ? (
              <div className={`main-body ${props.toggleshift.style}`}>
                <div className="page-content">
                  {/* USER TABLE START */}
                  <div className="meetings-table">
                    <div className="seleting-menus">
                      <div className="page-title">MEETING DETAILS</div>
                      <div className="page-top-bar-dropdowns">
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          defaultValue={userList[null]}
                          options={userList}
                          placeholder="Select User"
                          value={selectedUserId}
                          onInputChange={userList.label}
                          onChange={(data) => setFilterUserId(data.value)}
                          isSearchable={true}
                        />

                        <span className="input-group from-to-date-select">
                          <span className="input-group-text" id="from-label">
                            From
                          </span>
                          <span className="date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select from-date"
                              id="from-datepicker"
                              dateFormat="dd/MM/yyyy"
                              selectsEnd
                              // startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) => setFromDate(date)}
                              maxDate={new Date()}
                            />
                            <i className="fa fa-calendar-o calander-icon" />
                          </span>

                          <span className="input-group-text" id="to-label">
                            To
                          </span>
                          <span className="date-picker-with-icon">
                            <DatePicker
                              selected={toDate}
                              className="select to-date"
                              id="from-datepicker"
                              dateFormat="dd/MM/yyyy"
                              selectsEnd
                              // startDate={fromDate}
                              endDate={toDate}
                              minDate={fromDate}
                              onChange={(date) => setToDate(date)}
                              maxDate={new Date()}
                            />
                            <i className="fa fa-calendar-o calander-icon" />
                          </span>
                        </span>

                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={stateList}
                          placeholder="Select State"
                          value={stateId === 0 ? "" : stateList.value}
                          onInputChange={stateList.label}
                          onChange={(data) => setStateId(data.value)}
                          isSearchable={true}
                        // isClearable={true}
                        />

                        <button
                          className="refresh-button"
                          onClick={fetchMeetingDetailsByUser}
                          title="Refresh"
                        >
                          <i className="fa fa-refresh refresh-meeting-details" />
                        </button>

                        <button
                          type="button"
                          className="btn btn-search-track"
                          onClick={clearSerachBySelect}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="fa fa-eraser"> </i>
                            <h6>Clear</h6>
                          </span>
                        </button>

                        {/* <button
                      type="button"
                      className="btn btn-clear-select"
                      onClick={clearSerachBySelect}
                    >
                      <i className="bi bi-x-circle-fill"></i>
                    </button> */}

                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          // defaultValue={optionsRoleList[0].label}
                          options={optionsPageSizeList}
                          placeholder="Show Per Page"
                          value={optionsPageSizeList.value}
                          onInputChange={optionsPageSizeList.label}
                          onChange={(data) => setPageSize(data.value)}
                          isSearchable={true}
                        // isClearable={true}
                        />

                        <button
                          type="button"
                          className="btn btn-add-user"
                          onClick={props.goBacktomeetings}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="fa fa-arrow-left"> </i>
                            <h6>Back</h6>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div
                      className="table-responsive meetings-scrollbar"
                      ref={tableRef}
                    >
                      <table className="table meetings-list-table">
                        <thead className="thead-light meetings-list-thead">
                          <tr>
                            <th>NAME</th>
                            <th>COMPANY NAME</th>
                            <th>MEETING</th>
                            <th>MOT</th>
                            <th>REMARKS</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonMeetDetails />
                              <SkeletonMeetDetails />
                              <SkeletonMeetDetails />
                              <SkeletonMeetDetails />
                              <SkeletonMeetDetails />
                            </>
                          ) : meetingDetails !== "" ? (
                            meetingDetails.map((data, i) => (
                              <tr
                                className="meeting-details-list-row-data"
                                key={i}
                              >
                                <td>
                                  <p>
                                    <b>{data?.fullName}</b>
                                  </p>
                                  <p> {data?.userCode}</p>
                                  <p>{data?.stateName}</p>
                                </td>
                                <td style={{ maxWidth: "220px" }}>
                                  <span className="client-meet d-flex">
                                    <p>
                                      <b>{data?.clientName} - </b>
                                    </p>
                                    &nbsp;
                                    <p className="client-meet-status">
                                      {data?.meetingType}
                                    </p>
                                  </span>
                                  <i className="fa fa-user mtdtls-icon" />
                                  &nbsp;
                                  {data?.contactPersonName ??
                                    data?.contactPerson ??
                                    data?.clientName}
                                  <br />
                                  <i className="fa fa-mobile mtdtls-icon" />{" "}
                                  &nbsp;
                                  {data?.contactPersonNumber ?? data?.contact}
                                  <br />
                                  <i className="fa fa-thumb-tack mtdtls-icon" />{" "}
                                  &nbsp;
                                  {data?.clientName === "Check-Out"
                                    ? data?.endLoc?.location
                                    : data?.clientLoc?.location}
                                  <span className="client-meet-location">
                                    <p className="client-meet-lat">
                                      <i className="bi bi-geo-alt" />
                                      {data?.startLoc?.latitude ?? "NA"},
                                      {data?.startLoc?.longitude ?? "NA"}
                                    </p>
                                    <p className="client-meet-lan">
                                      <i className="bi bi-geo-alt" />
                                      {data?.endLoc?.latitude ?? "NA"},
                                      {data?.endLoc?.longitude ?? "NA"}
                                    </p>
                                  </span>
                                </td>
                                <td className="td-meeting-details">
                                  <span className="client-meet-purpose d-flex">
                                    <p>
                                      <b>Purpose :</b>
                                    </p>
                                    &nbsp;
                                    <p className="client-meet-purpose-status">
                                      {data?.purpose ?? "NA"}
                                    </p>
                                  </span>
                                  <p>Date : {data?.meetingDate} </p>
                                  <p>Scheduled : {data?.meetingTime}</p>
                                  <p>
                                    In Time :{" "}
                                    {data?.inTime === null ||
                                      data?.inTime === ""
                                      ? "NA"
                                      : data?.inTime}
                                  </p>
                                  <p>
                                    Out Time :{" "}
                                    {data?.outTime === null ||
                                      data?.outTime === ""
                                      ? "NA"
                                      : data?.outTime}
                                  </p>
                                  <p>
                                    <b>Duration : </b> &nbsp;{data?.timeSpent}
                                  </p>
                                  <p>
                                    <b>Distance Travelled : </b>
                                    &nbsp;
                                    {data?.distanceTravelled === null ||
                                      data?.distanceTravelled === ""
                                      ? 0
                                      : data?.distanceTravelled}
                                  </p>
                                  <p>
                                    <b>Manual Route : </b> &nbsp;
                                    {data?.setRootStatus ?? "NA"}
                                  </p>
                                </td>
                                <td>
                                  <div style={{ textAlign: "left" }}>
                                    <Image
                                      src={
                                        data?.mot == "Bike" ||
                                          data?.mot == "bike"
                                          ? bikeicon
                                          : data?.mot == "Car" ||
                                            data?.mot == "car"
                                            ? caricon
                                            : data?.mot == "Bus" ||
                                              data?.mot == "bus"
                                              ? busicon
                                              : data?.mot == "Auto" ||
                                                data?.mot == "auto"
                                                ? autoicon
                                                : data?.mot == "NA" ||
                                                  data?.mot == null
                                                  ? ""
                                                  : ""
                                      }
                                      className={
                                        data?.mot == "Bus" ||
                                          data?.mot == "bus" ||
                                          data?.mot == "NA" ||
                                          data?.mot == null
                                          ? "mot-icon-sml"
                                          : "mot-icon"
                                      }
                                    />
                                    <br />
                                    {data?.mot}
                                  </div>
                                </td>
                                <td style={{ maxWidth: "200px" }}>
                                  {data?.lessRemark === "NA" ? (
                                    data?.lessRemark
                                  ) : (
                                    <OverlayTrigger
                                      placement={"bottom"}
                                      delay={{ show: 250, hide: 300 }}
                                      overlay={
                                        <Tooltip id={`tooltip-remarks`}>
                                          {data?.remark}
                                        </Tooltip>
                                      }
                                    >
                                      <div>{data?.lessRemark}</div>
                                    </OverlayTrigger>
                                  )}
                                </td>
                                <td>
                                  <p className={`meet-status ${data?.status}`}>
                                    {data?.status === "PCancelled"
                                      ? "Pre-Cancelled"
                                      : data?.status === "ReSchedule"
                                        ? "Re-Scheduled"
                                        : data?.status}
                                  </p>
                                </td>
                                <td>
                                  <span
                                    className="meeting-detail-action-td"
                                    style={{
                                      display:
                                        data.meetingType === "Check-Out" ||
                                          data.status === "Check-Out" ||
                                          data.status === "PCancelled" ||
                                          data.status === "Pcancelled" ||
                                          data.status === "Pending" ||
                                          data.status === "pending" ||
                                          data.status === "ReSchedule" ||
                                          data.status === "Reschedule" ||
                                          data.status === "Expired" ||
                                          data.status === "expired"
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <button
                                      onClick={() => {
                                        setMeetingattachmentmodel(
                                          !meetingattachmentmodel
                                        );
                                        setMeetingId(data?.meetingID);
                                      }}
                                      className="meeting-details-view"
                                    >
                                      <i className="fa fa-paperclip" /> View
                                    </button>
                                    <button
                                      className="meetingdetail-viewroute-link"
                                      onClick={() => {
                                        setViewonuserroute(!viewonuserroute);
                                        setMeetingId(data?.meetingID);
                                      }}
                                    >
                                      <i className="bi bi-geo-alt-fill"></i>{" "}
                                      View Route
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            "-----NO DATA FOUND----"
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="download-and-pagination">
                      <button
                        type="button"
                        className="btn btn-download-excel"
                        onClick={exportMeetingDetails}
                      >
                        <i className="fa fa-download" /> &nbsp; Download
                      </button>
                      <span className="user-pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fa fa-angle-double-left" />
                          }
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />
                      </span>
                    </div>
                  </div>
                  {/* USER TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* MAIN BODY END */}
            <>
              {meetingattachmentmodel ? (
                <Viewmeetgattachmodel
                  meetingattachmentmodel={meetingattachmentmodel}
                  handleCloseviewattachment={handleCloseviewattachment}
                  meetingId={meetingId}
                />
              ) : (
                ""
              )}
              {viewonuserroute ? (
                <TrackuserRoute
                  toggleshift={props.toggleshift}
                  viewonuserroute={viewonuserroute}
                  goBacktomeetingsdetails={goBacktomeetingsdetails}
                  meetingId={meetingId}
                />
              ) : (
                ""
              )}
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default Meetingdetails;
