export const Get_Main_Modules = "Get_Main_Modules";
export const Add_Designation = "Add_Designation";
export const Get_Subscription = "Get_Subscription";
export const Get_All_Subscription = "Get_All_Subscription";
export const Get_Subscription_Module = "Get_Subscription_Module";
export const Get_All_Menus = "Get_All_Menus";

export const Post_Menus_Subscription = "Post_Menus_Subscription";

export const Get_Track_User = "Get_Track_User";

export const Get_Successs_Msg = "Get_Successs_Msg";
export const Get_Distance_Matrix = "Get_Distance_Matrix";