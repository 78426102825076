import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Meetings.scss";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import { exportExcel } from "../../DownloadFile";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { optionsPageSizeList } from "../../DropdownsData";
import AccessDenied from "../../../AccessDenied";
import { Image } from "react-bootstrap";
import mobileimg from "../../../images/iphone.svg";

function DeviceDetails() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [subType, setSubType] = useState(0);
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [companyName, setCompanyName] = useState(0);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    fetchDropdownData();
  }, [subType]);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/BindUsersDropDown?SubscriptionId=${subType}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const fetchDropdowns = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/BindCompanyDropdown?SubscriptionTypeID=0`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCompanyList(
            res.result.map((data) => {
              return {
                value: data.subscriptionID,
                label: data.companyName,
              };
            })
          );
        } else {
          setCompanyList([]);
        }
      });
  };

  // GET SUBSCRIPTION PAGE START
  useEffect(() => {
    fetchdeviceDetails();
  }, [PageSize, currentPage, subType, filterUserId, companyName]);

  const fetchdeviceDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/SupportTeam/GetAllDevices?SubscriptionId=${subType}&UserId=${filterUserId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDeviceDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
        } else {
          setDeviceDetails("");
        }
      });
  };

  // GET SUBSCRIPTION PAGE END

  const clearSerachBySelect = () => {
    setSubType(0);
    setFilterUserId("");
    // setCompanyName("");
  };

  const exportExcelDeviceDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/DeviceExcelExport?SubscriptionId=${companyName}&UserId=${filterUserId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "DeviceDetails.xlsx"
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="meetings-table">
                  <div className="seleting-menus">
                    <div className="page-title"> DEVICE DETAILS</div>
                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={companyList}
                        placeholder="Select Company"
                        value={subType === 0 ? "" : companyList.value}
                        onInputChange={companyList.label}
                        onChange={(data) => setSubType(data.value)}
                        isSearchable={true}
                      />

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={userList}
                        placeholder="Select User"
                        value={filterUserId === "" ? "" : userList.value}
                        onInputChange={userList.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      />
                    </div>
                  </div>

                  <div className="table-responsive meetings-scrollbar">
                    <table className="table meetings-list-table">
                      <thead className="thead-light meetings-list-thead">
                        <tr>
                          <th>USER NAME</th>
                          <th>DEVICE INFO</th>
                          <th>BATTERY</th>
                          <th>STORAGE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deviceDetails !== "" ? (
                          deviceDetails?.map((data, i) => (
                            <tr className="meeting-list-row-data" key={i}>
                              <td>
                                <p className="d-flex">
                                  <Image
                                    src={mobileimg}
                                    alt="Mobile-device"
                                    className="mobile-img"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      //   paddingRight: "5px",
                                    }}
                                  />
                                  <p>
                                    <b>{data?.userFullName}</b>
                                    <br />
                                    {moment(data?.date).format("DD/MM/YYYY")}
                                  </p>
                                </p>
                              </td>
                              <td>
                                <p>
                                  <b>Name : </b>
                                  {data?.name}
                                </p>
                                <p>
                                  <b>Modal : </b> {data?.modal ?? "NA"}
                                </p>
                                <p>
                                  <b>OS : </b> {data?.os}
                                </p>
                                <p>
                                  <b>Version : </b> {data?.osVersion}
                                </p>
                              </td>
                              <td>
                                <b>Tech : </b> {data?.batteryTech} <br />
                                <b>Health : </b> {data?.batteryHealth}
                                <br />
                                <b>Power : </b> {data?.batteryPower} <br />
                              </td>
                              <td>
                                <b>RAM : </b> {data?.ram}
                                <br />
                                <b>Rooted : </b>
                                {data?.rooted}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h6 className="no-data-found">
                            ------ NO DATA FOUND ------
                          </h6>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      onClick={exportExcelDeviceDetails}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default DeviceDetails;
