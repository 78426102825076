import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import SkeletonAttendance from "../Skeleton Loading/SkeletonAttendance";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";

function AttendanceFullday() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterUserId, setFilterUserId] = useState("");
  const [manager, setManager] = useState("");
  const [stateName, setStateName] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [attendanceByDay, setAttendanceByDay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  let tableHeader = [
    "NAME",
    "DATE",
    "LOGIN",
    "LOGOUT",
    "TIME SPENT"
  ]

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/User/BindManagerDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Zone/GetStates/`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  //* FETCH ATTENDANCE DATA START *//
  useEffect(() => {
    fetchAttendanceByDay();
  }, [
    fromDate,
    toDate,
    filterUserId,
    currentPage,
    PageSize,
    manager,
    stateName,
  ]);

  const fetchAttendanceByDay = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetAttendanceListAll?CurrentPage=${currentPage}&PageSize=${PageSize}&UserId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&managerId=${manager}&stateId=${stateName}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAttendanceByDay(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res?.recordCount[0]);
          setPageCount(Math.ceil(res?.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setAttendanceByDay(res.result);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ATTENDANCE DATA END *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManager("");
    setStateName(0);
    setFromDate(new Date());
    setToDate(new Date());
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelAttendance = () => {
    exportExcel(
      `${baseURL}/api/Export/AttendanceExcelExport?UserID=${filterUserId}&ManagerId=${manager}&StateID=${stateName}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `Attendance-FullDay${filterUserId}.xlsx`
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* USER TABLE START */}
                    <div className="attendance-table">
                      <div className="seleting-menus">
                        <div className="page-title atten-fullday">
                          ATTENDANCE-FULLDAY
                        </div>
                        <div className="page-top-bar-dropdowns">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userList}
                            placeholder="Select User"
                            value={filterUserId === "" ? "" : userList.value}
                            onInputChange={userList.label}
                            onChange={(data) => setFilterUserId(data.value)}
                            isSearchable={true}
                          />

                          {loggedInRole !== 2 ? (
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={managerList}
                              placeholder="Select Manager"
                              value={manager === "" ? "" : managerList.value}
                              onInputChange={managerList.label}
                              onChange={(data) => setManager(data.value)}
                              isSearchable={true}
                            />
                          ) : (
                            ""
                          )}

                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={stateList}
                            placeholder="Select State"
                            value={stateName === 0 ? "" : stateList.value}
                            onInputChange={stateList.label}
                            onChange={(data) => setStateName(data.value)}
                            isSearchable={true}
                          />

                          <div className="input-group from-to-date-select">
                            <span className="input-group-text" id="from-label">
                              From
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date"
                                id="from-datepicker"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                // startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setFromDate(date)}
                                maxDate={new Date()}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </span>

                            <span className="input-group-text" id="to-label">
                              To
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={toDate}
                                className="select to-date"
                                id="from-datepicker"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                // startDate={fromDate}
                                endDate={toDate}
                                minDate={fromDate}
                                onChange={(date) => setToDate(date)}
                                maxDate={new Date()}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </span>
                          </div>

                          <button
                            type="button"
                            className="btn btn-search-track"
                            onClick={clearSerachBySelect}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-eraser"> </i>
                              <h6>Clear</h6>
                            </span>
                          </button>

                          <Select
                            className="react-select-container-list per-page"
                            classNamePrefix="react-select-list"
                            // defaultValue={optionsRoleList[0].label}
                            options={optionsPageSizeList}
                            placeholder="Per Page"
                            value={optionsPageSizeList.value}
                            onInputChange={optionsPageSizeList.label}
                            onChange={(data) => setPageSize(data.value)}
                            isSearchable={true}
                          />
                        </div>{" "}
                      </div>
                      <div className="table-responsive attendance-daily-scroll">
                        <table className="table attendance-list-table">
                          {/* <thead className="thead-light attendance-list-thead">
                            <tr>
                              <th>NAME</th>
                              <th>DATE</th>
                              <th>LOGIN</th>
                              <th>LOGOUT</th>
                              <th>TIME SPENT</th>
                            </tr>
                          </thead> */}
                          <SbTableHeader tableHeader={tableHeader} />
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                              </>
                            ) : attendanceByDay != "" ? (
                              attendanceByDay.map((data, i) => (
                                <tr
                                  className="attendance-list-row-data"
                                  key={i}
                                >
                                  <td>
                                    <span className="attendance-list-name d-flex">
                                      <img
                                        className="table-data-attendance-imgages"
                                        src={profileimg}
                                        alt="John Doe"
                                      />
                                      <div>
                                        <p>
                                          <b>{data?.userFullName}</b>
                                        </p>
                                        <p>{data?.userCode}</p>
                                        <p>{data?.stateName}</p>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.attendanceDate}</td>
                                  <td style={{ maxWidth: "200px" }}>
                                    <span className="login-details d-flex">
                                      <p className="login-time-status">
                                        <b>{data?.inTime}</b>
                                      </p>
                                    </span>

                                    {data?.checkInLoc.location}
                                    <span className="login-time-location">
                                      <p className="login-time-lat-lan">
                                        <i className="bi bi-geo-alt"></i>
                                        {data?.checkInLoc?.latitude},
                                        {data?.checkInLoc?.longitude}
                                      </p>
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: "200px" }}>
                                    <span className="logout-details d-flex">
                                      <p className="logout-time-status">
                                        <b>{data?.outTime}</b>
                                      </p>
                                    </span>

                                    {data?.checkOutLoc?.location}
                                    <span className="logout-time-location">
                                      <p className="logout-time-lat-lan">
                                        <i className="bi bi-geo-alt"></i>
                                        {data?.checkOutLoc?.latitude},
                                        {data?.checkOutLoc?.longitude}
                                      </p>
                                    </span>
                                  </td>
                                  <td>{data?.duration}</td>
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                <td colSpan={5}>------ NO DATA FOUND ------</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    <div className="download-and-pagination">
                      <div className="data-count-per-page">
                        {pageDataFrom} - {pageDataTill} of {totalData}
                      </div>
                      <button
                        type="button"
                        className="btn btn-download-excel"
                        onClick={exportExcelAttendance}
                      >
                        <i className="fa fa-download" /> &nbsp; Download
                      </button>
                      <span className="user-pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fa fa-angle-double-left" />
                          }
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />
                      </span>
                    </div>

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default AttendanceFullday;
