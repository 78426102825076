import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GoogleMap, Marker, StandaloneSearchBox } from "@react-google-maps/api";

function GoogleMapAddressModel(props) {
  const [defaultLatLng, setDefaultLatLng] = useState({
    lat: 28.6448,
    lng: 77.216721,
  });

  const containerStyle = {
    width: "100%",
    height: "370px",
  };

  const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `260px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `0px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `12px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    top: "10px",
    right: "60px",
  };



  return (
    <div>
      <div>
        <Modal
          show={props.googleMapsModel}
          onHide={props.openGoogleMaps}
          className="main_model google-maps-model"
        >
          <div className="add-user-heading">
            <h6 className="add-user-head">GOOGLE MAPS</h6>

            <button
              type="button"
              className="google-address-ok"
              onClick={props.addressFromGoogle}
              data-bs-dismiss="modal"
            >
              OK
            </button>
            <i className="fa fa-times" onClick={props.openGoogleMaps} />
          </div>
          <div className="google-map-box">
            {/* <h5>GOOGLE MAP VIEW</h5> */}
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={
                props.latLan.lat === null || props.latLan.lat === null
                  ? defaultLatLng
                  : props.latLan
              }
              zoom={12}
              clickableIcons={true}
            >
              <>
                {/* <StandaloneSearchBox> */}
                <input
                  type="text"
                  placeholder={
                    props.address != "" ? props.address : "Search..."
                  }
                  style={inputStyle}
                  value={props.searchVal}
                  onChange={props.searchLocation}
                />
                {/* </StandaloneSearchBox> */}
                <Marker
                  position={
                    props.latLan.lat === null || props.latLan.lat === null
                      ? defaultLatLng
                      : props.latLan
                  }
                  clickable={true}
                  draggable={true}
                  onDragEnd={props.onMarkerDragEnd}
                />
              </>
            </GoogleMap>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default GoogleMapAddressModel;
