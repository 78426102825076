import React, { useState } from "react";
import "../../styles/Login.scss";
import { Button, Form, Alert, Image } from "react-bootstrap";
import salesbeebasiclogo from "../../images/SalesBeeColor.svg";
import AccessDenied from "../../AccessDenied";
import { baseURL } from "../BaseUrl";

function CreateSubscription() {
  const [companyName, setCompanyName] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [contactNo, setContactNo] = useState(null);
  const [subscriptionResMessage, setSubscriptionResMessage] = useState({
    success: null,
    message: null,
  });

  // const baseURL = "https://api.travelize.in";
  // const baseURL = "https://accounts.salesbee.io";

  let userLoginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let countryCodeList = [
    { label: "+1", value: "+1" },
    { label: "+233", value: "+233" },
    { label: "+52", value: "+52" },
    { label: "+91", value: "+91" },
    { label: "+962", value: "+962" },
    { label: "+968", value: "+968" },
    { label: "+97", value: "+97" },
    { label: "+971", value: "+971" },
    { label: "+973", value: "+973" },
    { label: "+974", value: "+974" },
  ];

  let currentYear = new Date().getFullYear();

  // VALIDATIONS START
  const checkValidation = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setContactNo(e.target.value);
    } else {
      alert("Accept only numbers");
    }
  };
  // VALIDATIONS END

  const createSubscription = (e) => {
    if (email && companyName && contactNo && countryCode) {
      const subscriptionData = {
        subscriptionTypeID: 0,
        companyName: companyName,
        clientName: clientName,
        email: email,
        countryCode: countryCode,
        contact: contactNo,
        password: `salesbee_${currentYear}`, // Password for New Subscriptions
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subscriptionData),
      };
      fetch(`${baseURL}/api/Subscription/AddSubscription`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          console.log("SUBSCRIPTION-RES::", res);
          if (res.success) {
            setSubscriptionResMessage({
              success: res.success,
              message: "Your Subscription has Created Successfully",
            });
          } else {
            setSubscriptionResMessage({
              success: res.success,
              message: res.errorMessage,
            });
          }
        });
    } else {
      setSubscriptionResMessage({
        success: false,
        message: "Please fill all the fields",
      });
    }
    setTimeout(
      () =>
        setSubscriptionResMessage({
          success: null,
          message: null,
        }),
      5000
    );
    e.preventDefault();
  };

  return (
    <div>
      <div>
        {/* {userLoginDetails?.subscriptionId == "SUB0000000001" ? ( */}
        <div>
          <div className="main-body">
            <div className="subscription-page-body">
              <Image
                src={salesbeebasiclogo}
                alt="SalesBee-Basic Logo"
                className="salesbeebasiclogo"
              />
              <div className="subscription-page">
                <div className="subscription-box">
                  <h3 className="login-head-text">SUBSCRIBE</h3>
                  <form onSubmit={createSubscription}>
                    <div className="companyname-input sub-inputs">
                      <input
                        type="text"
                        className="form-control"
                        id="cpname"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="clientname-input sub-inputs">
                      <input
                        type="text"
                        className="form-control"
                        id="clname"
                        placeholder="Client Name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="email-input sub-inputs">
                      <input
                        type="email"
                        className="form-control"
                        id="subemail"
                        placeholder="Email ID"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="contact-input sub-inputs">
                      <Form.Select
                        aria-label="Default select example"
                        name="countrycode"
                        className="form-select cntry-code"
                        value={countryCode}
                        onChange={(event) => setCountryCode(event.target.value)}
                      >
                        {countryCodeList.map((data, i) => (
                          <option key={i}>{data?.value}</option>
                        ))}
                      </Form.Select>
                      <input
                        type="text"
                        className="form-control"
                        id="contact"
                        placeholder="Mobile Number"
                        maxLength={10}
                        value={contactNo}
                        onChange={(e) => checkValidation(e)}
                        required
                        autoComplete="off"
                      />
                    </div>

                    <div className="subscription-submit-button">
                      <Button type="submit" className="btn btn-submit-button">
                        Submit
                      </Button>
                    </div>
                  </form>
                  <div className="subscription-res-message">
                    {subscriptionResMessage.success ? (
                      <Alert variant="success">
                        {subscriptionResMessage.message}
                      </Alert>
                    ) : subscriptionResMessage.success == false ? (
                      <Alert variant="danger">
                        {subscriptionResMessage.message}
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ) : (
          <AccessDenied />
        )} */}
      </div>
    </div>
  );
}

export default CreateSubscription;
