import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Designation.scss";
import { Form } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import Skeleton from "react-loading-skeleton";
import AddDepartment from "../Department/AddDepartment";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import AddDesignation from "./AddDesignation";
import EditDesignation from "./EditDesignation";

export default function Designation() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editDesignation, setEditDesignation] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  console.log(designationData, "designationData")
  const [activeStatus, setActiveStatus] = useState(1);
  const [designationId, setDesignationId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [editDepartmentId, setEditDepartmentId] = useState(0);
  const [roleId, setRoleId] = useState(0);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditDesignation(false);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return {
                value: data.departmentID,
                label: data.departmentName,
              };
            })
          );
        } else {
          setDepartmentList([]);
        }
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
  };

  // GET BASEFARE PAGE START
  useEffect(() => {
    fetchDesignations();
  }, [designationId, roleId, departmentId, activeStatus]);

  const fetchDesignations = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Designation/Get?DesignationID=${designationId}&RoleID=${roleId}&DepartmentID=${departmentId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("DEPARTMENT::", res.result);
        if (res.success) {
          setDesignationData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setDesignationData("");
          setIsLoading(false);
        }
      });
  };
  // GET BASEFARE PAGE END

  const clearSerachBySelect = () => {
    setDepartmentId(0);
    setDesignationId(0);
    setRoleId(0);
    setActiveStatus(0);
  };

  //   const exportExcelBaseFare = () => {
  //     exportExcel(
  //       `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
  //       "BaseFare.xlsx"
  //     );
  //   };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="designation-table">
                  <div className="seleting-menus">
                    <div className="page-title">DESIGNATIONS</div>

                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={departmentList[0].label}
                        options={departmentList}
                        placeholder="Select Department"
                        value={departmentId == 0 ? "" : departmentList.value}
                        onInputChange={departmentList.label}
                        onChange={(data) => setDepartmentId(data.value)}
                        isSearchable={true}
                      />

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={roleList[0].label}
                        options={roleList}
                        placeholder="Select Role"
                        value={roleId == 0 ? "" : roleList.value}
                        onInputChange={roleList.label}
                        onChange={(data) => setRoleId(data.value)}
                        isSearchable={true}
                      />

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsStatusList}
                        placeholder="Select Status"
                        value={activeStatus == 0 ? "" : optionsStatusList.value}
                        onInputChange={optionsStatusList.label}
                        onChange={(data) => setActiveStatus(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser" />
                          <h6>Clear</h6>
                        </span>
                      </button>

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-add-designation"
                        onClick={() => setShow(!show)}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-plus-square" /> &nbsp;
                          <h6>Add New</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive designation-scrollbar">
                    <table className="table designation-table-data">
                      <thead className="thead-light designation-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>SL.NO.</th>
                            <th>DESIGNATION</th>
                            <th>LEVEL</th>
                            <th>DEPARTMENT</th>
                            <th>ROLE</th>
                            <th>REPORTING DESIGNATION</th>
                            <th>DESCRIPTION</th>
                            <th>ADDED BY</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        )}
                      </thead>
                      <tbody >
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : designationData !== "" ? (
                          designationData.map((data, i) => (
                            <tr key={i} className="designation-table-row-data">
                              <td>{i + 1}</td>
                              <td>{data?.designationName}</td>
                              <td>{data?.designationLevel}</td>
                              <td>{data?.departmentName}</td>
                              <td>{data?.role.roleName}</td>
                              <td>{data?.reportingDesignationName}</td>

                              <td>{data?.description}</td>
                              <td>{data?.addedByUserFullName}</td>
                              <td>
                                {data?.status == 1 ? "Active" : "In-Active"}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setEditDesignation(!editDesignation);
                                    setEditDepartmentId(data.designationID);
                                  }}
                                  className="btn designation-edit-link"
                                >
                                  <i className="bi bi-pencil-fill" /> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9}> --- NO DATA FOUND --- </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                    // onClick={exportExcelActiivity}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* ADD MODEL START */}
                {show ? (
                  <AddDesignation
                    show={show}
                    handleClose={handleClose}
                    fetchDesignations={fetchDesignations}
                  />
                ) : (
                  ""
                )}
                {/* ADD MODEL END */}

                {/* EDIT MODEL START */}
                {editDesignation ? (
                  <EditDesignation
                    editDesignation={editDesignation}
                    editDepartmentId={editDepartmentId}
                    handleClose={handleClose}
                    fetchDesignations={fetchDesignations}
                  />
                ) : (
                  " "
                )}
                {/* EDIT  MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
