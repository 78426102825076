import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import Sidenavbar from '../Sidenavbar'
import Topbar from '../Topbar';
import '../../styles/subscriptionmodule.css'
import { loadAllSubscriptions, loadSubscription } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';

function SubscriptionModule(props) {
    let dispatch = useDispatch()

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const { allSubcriptions } = useSelector(state => state.data)

    const [menuItems, setMenuItems] = useState([])
    const [submenuAtt, setSubmenuAtt] = useState([])
    const [submenuClm, setSubmenuClm] = useState([])
    const [submenuStg, setSubmenuStg] = useState([])

    useEffect(() => {
        dispatch(loadSubscription())
        dispatch(loadAllSubscriptions())
    }, [])

    // let menuDatas = []
    // useEffect(() => {
    //     menuDatas = allSubcriptions;
    // }, [allSubcriptions])

    useEffect(() => {
        let activities = [];
        let subActAtt = [];
        let subActClm = [];
        let subActStng = [];
        let subscriptionList = [...allSubcriptions]
        if (subscriptionList?.length != 0) {

            subscriptionList?.map((item) => {
                if (item.parentModuleName === null) {
                    activities.push({ name: item.moduleName, subNames: [], status: false })
                }
                else if (item.parentModuleName === "Attendance") {
                    subActAtt.push({ name: item.moduleName, status: false })
                }
                else if (item.parentModuleName === "Claims") {
                    subActClm.push({ name: item.moduleName, status: false })
                }
                else if (item.parentModuleName === "Settings") {
                    subActStng.push({ name: item.moduleName, status: false })
                }
            })


        } else {
            activities.push({ name: "Dashboard", subNames: [] })
        }
        setMenuItems(activities)
        setSubmenuAtt(subActAtt)
        setSubmenuClm(subActClm)
        setSubmenuStg(subActStng)
    }, [allSubcriptions])

    useEffect(() => {
        if (menuItems) {
            // let list = [...menuItems]
            menuItems.map((activities) => {
                if (activities.name === "Attendance") {
                    activities.subNames = submenuAtt
                }
                if (activities.name === "Claims") {
                    activities.subNames = submenuClm
                }
                if (activities.name === "Settings") {
                    activities.subNames = submenuStg
                }
            })
            // setMenuItems(list)
        }
    }, [menuItems, submenuAtt, submenuClm, submenuStg])

    const { subcriptionList } = useSelector(state => state.data);

    const [userList, setUserList] = useState([]);
    useEffect(() => {
        if (subcriptionList) {
            let optiondata = []
            subcriptionList.map((data) => {
                if (data.subscriptionId != null) {
                    optiondata.push({
                        value: data.subscriptionId, label: data.subscriptionId, client: data?.company?.companyName
                    });
                }
            })
            setUserList(optiondata)
        }
    }, [subcriptionList])

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const [selectedSubscribe, setSelectedSubscribe] = useState('')
    const setFilterUserId = (selected) => {
        // console.log(selected, "select")
        setSelectedSubscribe(selected)
    }

    const handleInputChange = (i, status, name) => {
        let list = [...menuItems];
        list[i].status = !status;
        let attMenu = [...submenuAtt]
        let clmMenu = [...submenuClm]
        let stgMenu = [...submenuStg]
        if (name === "Attendance" && !status === true) {
            attMenu.map(item => {
                item.status = true
            })
        } else {
            attMenu.map(item => {
                item.status = false
            })
        }
        if (name === "Claims" && !status === true) {
            clmMenu.map(item => {
                item.status = true
            })
        } else {
            clmMenu.map(item => {
                item.status = false
            })
        }
        if (name === "Settings" && !status === true) {
            stgMenu.map(item => {
                item.status = true
            })
        } else {
            stgMenu.map(item => {
                item.status = false
            })
        }
        setMenuItems(list)
    }

    const subMenuInputChange = (i, status, parent) => {
        let menulist = [...menuItems]
        if (parent === "Attendance") {
            let list = [...submenuAtt];
            list[i].status = !status;
            setSubmenuAtt(list)
            let isFound = list.some(element => {
                if (element.status === true) {
                    return true;
                }
                return false;
            });

            if (isFound) {

                menulist.map(item => {
                    if (item.name === parent && item.status === false) {
                        item.status = true;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.name === parent && item.status === true) {
                        item.status = false;
                    }
                })
            }
        } else if (parent === "Claims") {
            let list = [...submenuClm];
            list[i].status = !status;
            setSubmenuClm(list)
            let isFound = list.some(element => {
                if (element.status === true) {
                    return true;
                }
                return false;
            });

            if (isFound) {
                menulist.map(item => {
                    if (item.name === parent && item.status === false) {
                        item.status = true;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.name === parent && item.status === true) {
                        item.status = false;
                    }
                })
            }
        } else if (parent === "Settings") {
            let list = [...submenuStg];
            list[i].status = !status;
            setSubmenuStg(list)
            let isFound = list.some(element => {
                if (element.status === true) {
                    return true;
                }
                return false;
            });

            if (isFound) {
                menulist.map(item => {
                    if (item.name === parent && item.status === false) {
                        item.status = true;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.name === parent && item.status === true) {
                        item.status = false;
                    }
                })
            }
        }
    }

    let matrix = [
        "By google",
        "By Salesbee(GPS)",
        "By manual",
        "By odo meter"
    ]

    const [matrixValue, setMatrixValue] = useState('')

    return (
        <div id="main-page">
            {/* top bar and sidebar start */}
            <div className="top-and-sidebar">
                <Sidenavbar
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />

                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
            </div>
            {/* top bar and sidebar end */}

            {/* page content start */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="user-table">
                            <div className="seleting-menus">
                                <div className="page-title">
                                    {props.pageName} Module
                                </div>
                                <div className="page-top-bar-dropdowns">
                                    {/* <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userList}
                                        placeholder="Select Subscription Id"
                                        // value={filterUserId === "" ? "" : userList.value}
                                        // onInputChange={userList.label}
                                        onChange={setFilterUserId}
                                        isSearchable={true}
                                    />
                                     */}
                                </div>
                            </div>
                            {/* {selectedSubscribe &&
                                <div className="table-responsive meetings-scrollbar" >
                                    <div className='menu-div-header'>
                                        <h2 style={{ fontSize: '20px', fontWeight: '700' }}>Assign Modules to the Client {selectedSubscribe.client}</h2>
                                    </div>
                                    <div>
                                        <div className='menu-div-header'>
                                            <h5 style={{ fontSize: '16px', fontWeight: '700' }}>List of Modules</h5>
                                        </div>
                                        <div className='menu-item-div'>
                                            <Row >
                                                {menuItems.map((item, i) => (
                                                    <Col md={3} key={i} >
                                                        <div className="menu-list-row">
                                                            <Row className='module-head-div'>
                                                                <Col xs={7} >
                                                                    <div>
                                                                        <p className='menu-name'>{item.name}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={5} className="toggle-btn-div">
                                                                    <div className="subacribe-toggle w-100">
                                                                        <Form.Group className="facility-details">
                                                                            <div className={`checkbox ${item.status && "checkbox--on"}`}
                                                                                name="status"
                                                                                onClick={(e) => handleInputChange(i, item.status, item.name)}>
                                                                                <div className="checkbox__ball">
                                                                                </div>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div id="submenu-section-div" className={`section-sunmenus ${item.subNames.length > 3 ? "scroll" : "submenus"}`} >
                                                                {item.subNames.length === 0 ?
                                                                    <div className="submenu-row d-flex">
                                                                        <h5 style={{ color: "#110C1C", fontSize: "15px", fontWeight: "700" }}>No submenus available</h5>
                                                                    </div>
                                                                    :
                                                                    <div style={{ width: "100%" }}>
                                                                        {item.subNames.map((subItem, index) => (
                                                                            <Row key={index} className="submenu-row">
                                                                                <Col xs={7} >
                                                                                    <div>
                                                                                        <p className='submenu-name'>{subItem.name}</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col xs={5} className="toggle-btn-div">
                                                                                    <div className="subacribe-toggle w-100">
                                                                                        <Form.Group className="facility-details">
                                                                                            <div className={`checkbox ${subItem.status && "checkbox--on"}`}
                                                                                                name="status"
                                                                                                onClick={(e) => subMenuInputChange(index, subItem.status, item.name)}>
                                                                                                <div className="checkbox__ball">
                                                                                                </div>
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </div>

                                                                                </Col>
                                                                            </Row>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <div className='menu-div-header'>
                                            <h5 style={{ fontSize: '16px', fontWeight: '700' }}>Distance Matrix</h5>
                                        </div>
                                        <div className='menu-item-div '>
                                            <Row className='matrix-div'>
                                                {matrix.map((item, i) => (
                                                    <Col md={3} key={i} >
                                                        <div >
                                                            <input
                                                                type="checkbox"
                                                                className="styled-checkbox" id="styled-checkbox-1"
                                                                checked={matrixValue === item}
                                                                onChange={() => setMatrixValue(item)}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span style={{ fontSize: '16px', fontWeight: '700' }}>{item}</span>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            {/* page content end */}
        </div>
    )
}

export default SubscriptionModule;
