import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Spares.scss";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import productimg from "../../images/products/samsungbattery1.png";
import productimg1 from "../../images/products/MouseWired.png";
import { optionsPageSizeList } from "../DropdownsData";
import AddSpare from "./AddSpare";
import EditSpare from "./EditSpare";
import ViewSpareDetails from "./ViewSpareDetails";
// import AddProductmodel from "./AddProductmodel";
// import EditProductModel from "./EditProductModel";
// import ViewProductDetails from "./ViewProductDetails";

export default function Spares() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editproductmodel, setEditproductmodel] = useState(false);
  const [viewProductDetail, setViewProductDetail] = useState(false);

  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditproductmodel(false);
    setViewProductDetail(false);
  };

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            {/* USER TABLE START */}
            <div className="spares-table">
              <div className="seleting-menus">
                <div className="page-title">SPARES (2)</div>

                <div className="page-top-bar-dropdowns">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select Product"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select Spare"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Price"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-search-track"
                    // onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-spare"
                    onClick={() => setShow(!show)}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-plus-square" /> &nbsp;
                      <h6>Add New</h6>
                    </span>
                  </button>
                </div>
              </div>
              <div className="table-responsive spares-scrollbar">
                <table className="table spares-table-data">
                  <thead className="thead-light spares-thead">
                    <tr>
                      <th>SPARE NAME</th>
                      <th>CODE</th>
                      <th>PRODUCT NAME</th>
                      <th>WARRANTY</th>
                      <th>DIMENSION (H x W x L)</th>
                      <th>WEIGHT</th>
                      <th>
                        (
                        <i className="fa fa-inr" style={{ fontSize: "12px" }} />
                        ) MRP
                      </th>
                      <th>STOCKS</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="spare-table-row-data">
                      <td>
                        <p className="row-img-spare">
                          <span className="spare-img-box">
                            <img
                              className="row-spare-images"
                              src={productimg}
                              alt="Spare Images"
                            />{" "}
                          </span>
                          &nbsp; &nbsp;{" "}
                          <p>
                            <strong>Battery</strong> <br />
                            {/* <small>SN : LM123456465</small> */}
                          </p>
                        </p>
                      </td>
                      <td>MB-0581</td>
                      <td>Mobile</td>
                      <td>1 Year</td>
                      <td>20mm x 1209mm x 1220mm</td>
                      <td>500gm</td>
                      <td>1,100.00</td>
                      <td>
                        <button
                          //   onClick={() =>
                          //     setViewProductDetail(!viewProductDetail)
                          //   }
                          className="btn spares-stocks-link"
                        >
                          <i className="fa fa-cubes" /> 10
                        </button>
                      </td>

                      <td>
                        <button
                          onClick={() => setEditproductmodel(!editproductmodel)}
                          className="btn spare-edit-link"
                        >
                          <i className="bi bi-pencil-fill"></i> Edit
                        </button>
                        <button
                          onClick={() =>
                            setViewProductDetail(!viewProductDetail)
                          }
                          className="btn spare-edit-link"
                        >
                          <i className="fa fa-eye" /> View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
                // onClick={exportExcelActiivity}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
              <span className="user-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </span>
            </div>

            {/* ADD PRODUCT MODEL START */}
            <AddSpare show={show} handleClose={handleClose} />
            {/* ADD PRODUCT MODEL END */}

            {/* EDIT PRODUCT MODEL START */}
            {editproductmodel ? (
              <EditSpare
                editproductmodel={editproductmodel}
                handleClose={handleClose}
              />
            ) : (
              ""
            )}
            {/* EDIT PRODUCT MODEL END */}

            {/* VIEW PRODUCT MODEL START */}
            {viewProductDetail ? (
              <ViewSpareDetails
                viewProductDetail={viewProductDetail}
                handleClose={handleClose}
              />
            ) : (
              ""
            )}
            {/* VIEW PRODUCT MODEL END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>

        {/* MAIN BODY END */}
      </div>
    </div>
  );
}
