import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";

export default function EditDepartment(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [deptDetails, setDeptDetails] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [enableStatus, setEnableStatus] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  useEffect(() => {
    getDepartmentById();
  }, [props.deptId]);

  const getDepartmentById = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Department/Get/${props.deptId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDeptDetails(res.result);
          setDepartmentName(res?.result?.name);
          setEnableStatus(res?.result?.statusID);
        } else {
          setDeptDetails("");
          setDepartmentName("");
        }
      });
  };

  const editDepartment = () => {
    const departmentDetail = {
      id: props.deptId,
      name: departmentName,
      statusID: enableStatus,
    };

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(departmentDetail),
    };
    fetch(`${baseURL}/api/Department/UpdateDepartment`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShowAlert(true);
          setAlertMessage({
            alertType: "success",
            message: res.message,
          });
          setTimeout(() => {
            props.handleClose();
            props.fetchDepartments();
          }, 1000);
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: res.errorMessage,
          });
        }
      });
  };

  return (
    <div>
      <Modal
        show={props.editDepartmentModel}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">EDIT DEPARTMENT</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-basefare-input"
                  placeholder="Department Name"
                  value={departmentName}
                  onChange={(e) => setDepartmentName(e.target.value)}
                />
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="basefare-save-button">
            <button
              onClick={editDepartment}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="products-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
