let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

export const travelizeAuth = {
  "Content-Type": "application/json",
  Travelize_Authentication: userDetails,
};

export const getRequestOptions = {
  headers: travelizeAuth,
  "method": "GET",
  "mode": "cors"
};

export const salesbeeImgAuth = {
  Travelize_Authentication: userDetails,
};
