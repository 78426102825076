import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Clients.scss";
import { Link } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import AddClientModel from "./AddClientModel";
import EditclientsModel from "./EditclientsModel";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import AddBulkClient from "./AddBulkClient";
import ClientHistory from "./ClientHistory";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import { SkeletonClients } from "../Skeleton Loading/SkeletonClients";
import MappedToUsers from "./MappedToUsers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import SbTableHeader from "../SbTables/SbTableHeader";

function Clients() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editclientmodel, setEditclientmodel] = useState(false);
  const [clientDetails, setClientDetails] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [filterUserId, setFilterUserId] = useState("");
  const [managerId, setManagerId] = useState("");
  const [stateName, setStateName] = useState("");
  const [searchval, setSearchval] = useState("");
  const [clientId, setClientId] = useState(0);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [addClientmodelShow, setAddClientmodelShow] = useState(false);
  const [addBulkClientModel, setAddBulkClientModel] = useState(false);
  const [historyclientmodel, setHistoryclientmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMappedTo, setShowMappedTo] = useState(false);

  let tableHeader = [
    "COMPANY NAME",
    "CONTACT PERSON",
    "MOBILE / EMAIL",
    "LOCATION",
    "ADDED BY",
    "STATUS",
    "ACTION"
  ]

  const [totalCounts, setTotalCounts] = useState([]);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleCloseBulkClient = () => {
    setAddBulkClientModel(!addBulkClientModel);
  };

  const handleClose = () => {
    setAddClientmodelShow(false);
    setEditclientmodel(false);
    setShowMappedTo(false);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);


  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  // PAGINATION END

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    fetchClientDetails();
  }, [searchval, filterUserId, activeStatus, stateName, PageSize, currentPage]);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
      // body: JSON.stringify(userNamePassword),
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
    fetch(`${baseURL}/api/Zone/GetStates/`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateName, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      });
  };

  //  CLIENT TABLE START
  const fetchClientDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Text=${searchval}&UserID=${filterUserId}&EnabledStatus=${activeStatus}&StateName=${stateName}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setClientDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalCounts(res.recordCount);
          setIsLoading(false);
        } else {
          setClientDetails("");
          setIsLoading(false);
        }
      });
  };
  //  CLIENT PURPOSE TABLE END

  const goBacktoClients = () => {
    setHistoryclientmodel(!historyclientmodel);
  };

  const addClientHandler = () => {
    setAddClientmodelShow(!addClientmodelShow);
  };

  const clearSerachBySelect = () => {
    setSearchval("");
    setFilterUserId("");
    setStateName("");
    setActiveStatus(2);
  };

  const exportExcelClient = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientExcelExport?UserID=${filterUserId}&Text=${searchval}&ClientID=${clientId}&ManagerID=${managerId}&EnabledStatus=${activeStatus}&StateName=${stateName}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Clients.xlsx"
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            {!historyclientmodel ? (
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* USER TABLE START */}
                  <div className="clients-table">
                    <div className="seleting-menus">
                      <div className="page-title">
                        COMPANY ({totalCounts[1]}/{totalCounts[0]})
                      </div>
                      <div className="page-top-bar-dropdowns">
                        <div className="client-search-input">
                          <input
                            type="search"
                            placeholder="Search Client"
                            // aria-describedby="button-addon3"
                            className="form-control select input-search-client"
                            onChange={(e) => setSearchval(e.target.value)}
                          />
                          <i className="fa fa-search" />
                        </div>

                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={userList}
                          placeholder="Select User"
                          value={filterUserId === "" ? "" : userList.value}
                          onInputChange={userList.label}
                          onChange={(data) => setFilterUserId(data.value)}
                          isSearchable={true}
                        // isClearable={true}
                        />

                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={stateList}
                          placeholder="Select State"
                          value={stateName === "" ? "" : stateList.value}
                          onInputChange={stateList.label}
                          onChange={(data) => setStateName(data.value)}
                          isSearchable={true}
                        // isClearable={true}
                        />

                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          // defaultValue={optionsRoleList[0].label}
                          options={optionsStatusList}
                          placeholder="Select Status"
                          value={
                            activeStatus === 2 ? "" : optionsStatusList.value
                          }
                          onInputChange={optionsStatusList.label}
                          onChange={(data) => setActiveStatus(data.value)}
                          isSearchable={true}
                        // isClearable={true}
                        />

                        <button
                          type="button"
                          className="btn btn-search-track"
                          onClick={clearSerachBySelect}
                          style={{ marginRight: "5px" }}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="fa fa-eraser"> </i>
                            <h6>Clear</h6>
                          </span>
                        </button>

                        {/* <div className="dropdown add-single-bulk-user">
                          <button
                            // type="button"
                            className="btn btn-add-client"
                            id="addclient-single-blulk"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-user-plus" />
                              <h6>Add Client</h6>
                            </span>
                          </button>
                          <ul
                            className="dropdown-menu single-bulk-upload"
                            aria-labelledby="addclient-single-blulk"
                          >
                            <li>
                              <button
                                className="dropdown-item single-client"
                                // onClick={() =>
                                //   setAddClientmodelShow(!addClientmodelShow)
                                // }
                                onClick={(e) => addClientHandler(e)}
                              >
                                <i className="fa fa-user-plus" /> &nbsp; Single
                                Client
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item bulk-client"
                                onClick={() =>
                                  setAddBulkClientModel(!addBulkClientModel)
                                }
                              >
                                <i className="fa fa-users" /> &nbsp; Bulk Upload
                              </button>
                            </li>
                          </ul>
                        </div> */}
                        <SbDropDownBtn btnName="Add Client" pageName="Client" setAddusermodelshow={setAddClientmodelShow} addusermodelshow={addClientmodelShow}
                          setAddBulkUserModel={setAddBulkClientModel} addBulkUserModel={addBulkClientModel} />
                      </div>
                    </div>
                    <div className="table-responsive clients-scroll">
                      <table className="table clients-list-table">
                        <SbTableHeader tableHeader={tableHeader} />
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonClients />
                              <SkeletonClients />
                              <SkeletonClients />
                              <SkeletonClients />
                            </>
                          ) : clientDetails != "" ? (
                            clientDetails.map((data, i) => (
                              <tr className="client-list-row-data" key={i}>
                                <td style={{ maxWidth: "150px" }}>
                                  <b> {data?.clientName} </b> <br />
                                  <small className="re-tag-count">
                                    <i className="fa fa-thumb-tack" /> &nbsp;
                                    Re-Tag : {data?.retagCount}
                                  </small>
                                </td>
                                <td>{data?.contactPersonName}</td>
                                <td
                                  className="client-mobile"
                                  style={{ maxWidth: "100px" }}
                                >
                                  {data?.mobile} <br />
                                  <span className="client-email">
                                    {data?.email}
                                  </span>
                                </td>
                                <td
                                  className="client-location"
                                  style={{ maxWidth: "160px" }}
                                >
                                  {data?.clientLoc?.location}
                                  &nbsp;
                                  <span className="client-tagged">
                                    {data?.flag}
                                  </span>
                                </td>
                                <td>
                                  {data?.userFullName}
                                  {/* <br /> */}
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setShowMappedTo(!showMappedTo);
                                      setClientId(data?.clientID);
                                    }}
                                    className="view-mapped-user"
                                  >
                                    <OverlayTrigger
                                      placement={"top"}
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip id={`tooltip-title`}>
                                          Mapped To
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        <i className="fa fa-users user-icon" />
                                      </span>
                                    </OverlayTrigger>
                                  </Link>
                                </td>
                                <td>
                                  {data?.enabledStatus == 1
                                    ? "Active"
                                    : "InActive"}
                                </td>
                                <td>
                                  <button
                                    onClick={() => {
                                      setEditclientmodel(!editclientmodel);
                                      setClientId(data?.clientID);
                                    }}
                                    className="btn client-edit-link"
                                  >
                                    <i className="bi bi-pencil-fill" /> Edit
                                  </button>
                                  <button
                                    onClick={() => {
                                      setHistoryclientmodel(
                                        !historyclientmodel
                                      );
                                      setClientId(data?.clientID);
                                    }}
                                    className="btn client-history-link"
                                  >
                                    <i className="fa fa-eye" /> History
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <h6 className="no-data-found">
                              ------ NO DATA FOUND ------
                            </h6>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* USER TABLE END */}
                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      onClick={exportExcelClient}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                  {/* ADD CLIENT MODEL START */}
                  {addClientmodelShow ? (
                    <AddClientModel
                      addClientmodelShow={addClientmodelShow}
                      handleClose={handleClose}
                      fetchClientDetails={fetchClientDetails}
                    />
                  ) : (
                    ""
                  )}
                  {/* ADD CLIENT MODEL END */}

                  {/* ADD BULK CLIENT MODEL START */}
                  {addBulkClientModel ? (
                    <AddBulkClient
                      show={addBulkClientModel}
                      handleCloseBulkClient={handleCloseBulkClient}
                    />
                  ) : (
                    ""
                  )}

                  {/* ADD BULK CLIENT MODEL END */}

                  {/* EDIT CLIENT MODEL START */}
                  {editclientmodel ? (
                    <EditclientsModel
                      editclientmodel={editclientmodel}
                      handleClose={handleClose}
                      clientId={clientId}
                      fetchClientDetails={fetchClientDetails}
                    />
                  ) : (
                    ""
                  )}
                  {/* EDIT CLIENT MODEL END */}

                  {/* MAPPEDTO USER MODEL START */}
                  {showMappedTo ? (
                    <MappedToUsers
                      showMappedTo={showMappedTo}
                      handleClose={handleClose}
                      clientId={clientId}
                      filterUserId={filterUserId}
                    />
                  ) : (
                    ""
                  )}
                  {/* MAPPEDTO USER MODEL END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              <>
                {historyclientmodel ? (
                  <ClientHistory
                    historyclientmodel={historyclientmodel}
                    toggleshift={toggleshift}
                    goBacktoClients={goBacktoClients}
                    clientId={clientId}
                  // fetchClientDetails={fetchClientDetails}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          {/* MAIN BODY END */}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Clients;
