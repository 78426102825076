import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import PendingClaimByAdmin from "./PendingClaimByAdmin";
import ApprovedClaimbyAdmin from "./ApprovedClaimbyAdmin";
import RejectedClaimAdmin from "./RejectedClaimAdmin";

function ClaimAdminOutStation() {
  const [pendingforApproval, setPendingforApproval] = useState(false);
  const [approvedClaim, setApprovedClaim] = useState(false);
  const [approvedClaimByManager, setApprovedClaimBymanager] = useState(false);
  const [rejectedClaim, setRejectedClaim] = useState(false);
  const [managerClaimDetails, setManagerClaimDetails] = useState([]);
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [managerID, setManagerID] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [claimUserId, setClaimUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const goBackToClaimfromPendingPage = () => {
    setPendingforApproval(!pendingforApproval);
  };

  const goBackToClaimfromApprovedPage = () => {
    setApprovedClaim(!approvedClaim);
  };

  const goBackToClaimfromRejectedPage = () => {
    setRejectedClaim(!rejectedClaim);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
    fetch(`${baseURL}/api/User/BindManagerDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("Manager-List::", res.result);
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      });
  };

  // GET MANAGER CLAIM DETAILS START
  useEffect(() => {
    fetchManagerClaimDetails();
  }, [filterUserId, managerID, fromDate, toDate, currentPage, PageSize]);

  const fetchManagerClaimDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/OSClaim/GetClaim?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&managerId=${managerID}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CLAIM-MANAGER::", res.result);
          setManagerClaimDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setManagerClaimDetails("");
          setIsLoading(false);
        }
      });
  };
  // GET MANAGER CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  // const exportExcelClaim = () => {
  //   exportExcel(
  //     `${baseURL}/api/Export/ClaimAmountExcelExport?UserID=${filterUserId}&FromDate=${moment(
  //       fromDate
  //     ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
  //       "DD/MM/YYYY"
  //     )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
  //     "ClaimDetail-Manager.xlsx"
  //   );
  // };

  return (
    <div id="main-page">
      {!pendingforApproval && !approvedClaim && !rejectedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* USER TABLE START */}
            <div className="claims-table">
              <div className="seleting-menus">
                <div className="page-title">CLAIMS (Admin)</div>
                <div className="page-top-bar-dropdowns">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    options={userList}
                    placeholder="Select User"
                    value={filterUserId === "" ? "" : userList.value}
                    onInputChange={userList.label}
                    onChange={(data) => setFilterUserId(data.value)}
                    isSearchable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    options={managerList}
                    placeholder="Select Manager"
                    value={managerList.value}
                    onInputChange={managerList.label}
                    onChange={(data) => setManagerID(data.value)}
                    isSearchable={true}
                  />

                  <div className="input-group from-to-date-select">
                    <span className="input-group-text" id="from-label">
                      From
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>

                    <span className="input-group-text" id="to-label">
                      To
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-search-track"
                    onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />
                </div>
              </div>

              <div className="table-responsive claims-scrollbar">
                <table className="table claims-manager-table">
                  <thead className="thead-light claims-manager-thead">
                    <tr>
                      <th>USER NAME</th>
                      <th>APPLIED </th>
                      <th>
                        APPROVED{" "}
                        <div className="approved-claim-manager">Manager</div>
                      </th>
                      <th>
                        PENDING APPROVAL{" "}
                        <div className="pending-claim-admin">Admin</div>
                      </th>
                      <th>
                        APPROVED{" "}
                        <div className="pending-claim-admin">Admin</div>
                      </th>
                      <th>
                        REJECTED{" "}
                        <div className="pending-claim-admin">Admin</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                      </>
                    ) : managerClaimDetails ? (
                      managerClaimDetails.map((data, i) => (
                        <tr className="claims-list-row-data" key={i}>
                          <td>
                            {data?.fullName}
                            <div className="claim-admin-user-phoneno">
                              {data?.countryCode} {data?.contact}
                            </div>
                          </td>
                          <td>{data?.appliedClaimvalue}</td>
                          <td>
                            {data?.manager?.approvedClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setApprovedClaimBymanager(
                                    !approvedClaimByManager
                                  );
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.manager?.approvedClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                          <td>
                            {data?.admin?.pendingClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setPendingforApproval(!pendingforApproval);
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.admin?.pendingClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                          <td>
                            {data?.admin?.approvedClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setApprovedClaim(!approvedClaim);
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.admin?.approvedClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                          <td>
                            {data?.admin?.rejectedClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setRejectedClaim(!rejectedClaim);
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.admin?.rejectedClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      "No Data Found"
                    )}
                  </tbody>
                </table>
              </div>
              <div className="download-and-pagination">
                <button
                  type="button"
                  className="btn btn-download-excel"
                  // onClick={exportExcelClaim}
                >
                  <i className="fa fa-download" /> &nbsp; Download
                </button>
                <span className="user-pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />
                </span>
              </div>
            </div>
            {/* USER TABLE END */}

            {/* PAGE CONTENT END*/}
          </div>
        </>
      ) : (
        <>
          {pendingforApproval ? (
            <PendingClaimByAdmin
              pendingforApproval={pendingforApproval}
              goBackToClaimfromPendingPage={goBackToClaimfromPendingPage}
              claimUserId={claimUserId}
              fromDate={fromDate}
              toDate={toDate}
              fetchManagerClaimDetails={fetchManagerClaimDetails}
            />
          ) : (
            ""
          )}

          {approvedClaim ? (
            <ApprovedClaimbyAdmin
              approvedClaim={approvedClaim}
              goBackToClaimfromApprovedPage={goBackToClaimfromApprovedPage}
              claimUserId={claimUserId}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            ""
          )}

          {rejectedClaim ? (
            <RejectedClaimAdmin
              rejectedClaim={rejectedClaim}
              goBackToClaimfromRejectedPage={goBackToClaimfromRejectedPage}
              claimUserId={claimUserId}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}

export default ClaimAdminOutStation;
