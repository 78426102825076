import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/MediaFiles/Media.scss"
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./Components/ForgotPassword";
import ResetPasswordPage from "./Components/ResetPasswordPage";
import CheckSubscription from "./Components/CheckSubscription";
import Dashboard from "./Components/Dashboard/Dashboard";
import Activities from "./Components/Dashboard/Activities";
import TrackByUser from "./Components/Dashboard/TrackByUser";
import Users from "./Components/Users/Users";
import ViewSubordinates from "./Components/Users/ViewSubbordinates";
import Tracker from "./Components/Tracker/Tracker";
import AttendanceMonthly from "./Components/Attendance/AttendanceMonthly";
import AttendanceFullday from "./Components/Attendance/AttendanceFullday";
import Leaves from "./Components/Leaves/Leaves";
import Meetings from "./Components/Meetings/Meetings";
import Clients from "./Components/Clients/Clients";
import LocalClaim from "./Components/LocalClaim/LocalClaim";
import MeetingDetailsModel from "./Components/LocalClaim/MeetingDetailsModel";
import Schedule from "./Components/Settings/Schedule/Schedule";
import TrackClients from "./Components/Settings/TrackClients/TrackClients";
import MeetingPurpose from "./Components/Settings/MeetingPurpose/MeetingPurpose";
import BaseFare from "./Components/Settings/BaseFare/BaseFare";
import Profile from "./Components/Settings/Profile/Profile";
import AccountDetails from "./Components/Settings/AccountDetails/AccountDetails";
import PageNotFound from "./PageNotFound";
import CreateSubscription from "./Components/PrivatePages/CreateSubscription";
import OutStationClaim from "./Components/OutStationClaim/OutStationClaim";
import KilometerRefreshPage from "./Components/PrivatePages/KilometerRefreshPage";
import ExceptionHandler from "./Components/PrivatePages/ExceptionHandler";
import KilometerRefreshAttendance from "./Components/PrivatePages/KilometerRefreshAttendance";
import SubscriptionDetails from "./Components/PrivatePages/SubscriptionDetails";
import GoogleAPIHitCount from "./Components/PrivatePages/GoogleAPIHitCount";
import SessionTimeout from "./Components/SessionTimeout";
import ClaimsManager from "./Components/LocalClaim/ClaimsManager";
import ClaimsAdmin from "./Components/LocalClaim/ClaimsAdmin";
import PendingforApproval from "./Components/LocalClaim/PendingforApproval";
import AccessDenied from "./AccessDenied";
import AccountSettings from "./Components/Settings/AccountSettings/AccountSettings";
import DeviceDetails from "./Components/Settings/DeviceDetails/DeviceDetails";
import Products from "./Components/Products/Products";
import Spares from "./Components/Spares/Spares";
import AddDropdownData from "./Components/PrivatePages/AddDropdownData";
import Department from "./Components/Settings/Department/Department";
import Designation from "./Components/Settings/Designation/Designation";
import MeetingManagement from "./Components/SubscriptionModule/MeetingManagement";
import Role from "./Components/Settings/Role/Role";
import Amc from "./Components/AMC/Amc";
import SuscriberPage from "./Components/SubscriptionModule/SuscriberPage";
import ProjectManagement from "./Components/SubscriptionModule/ProjectManagement";
import HumanResource from "./Components/SubscriptionModule/HumanResource";
import OrderManagement from "./Components/SubscriptionModule/OrderManagement";
import StockManagement from "./Components/SubscriptionModule/StockManagement";
import WarehouseManagement from "./Components/SubscriptionModule/WarehouseManagement";
import ComplaintManagement from "./Components/SubscriptionModule/ComplaintManagement";
import DealerManagement from "./Components/SubscriptionModule/DealerManagement";
import DistributionManagement from "./Components/SubscriptionModule/DistributionManagement";
import SettingPage from "./Components/Settings/SettingPage";
import UserTrackerPage from "./Components/UserTrackerPage";

function App() {

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    let activities = [];

    if (resPassData) {
      resPassData?.subscription?.modules.map((item) => {
        activities.push({ name: item.moduleName })
      })
    }
    setMenuItems(activities)
  }, [])

  return (
    <div className="App">
      <SessionTimeout />

      <>

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />
          <Route path="/userTracker" element={<UserTrackerPage />} />
          <Route path="/userTracker/:id" element={<UserTrackerPage />} />
          <Route
            exact
            path="/subscriptionexpiry"
            element={<CheckSubscription />}
          />
          <>{(menuItems.length !== 0) ?

            <>{menuItems.map((items, i) => (
              <React.Fragment key={i}>
                {items.name === "Dashboard" &&
                  <Route exact path="/dashboard" element={<Dashboard />} />
                }
                <Route exact path="/useractivities" element={<Activities />} />
                <Route exact path="/tracker" element={<Tracker />} />
                <Route exact path="/trackbyuser/:id" element={<TrackByUser />} />
                {items.name === "Users" &&
                  <Route exact path="/users" element={<Users />} />
                }
                <Route
                  exact
                  path="/viewsubordinates"
                  element={<ViewSubordinates />}
                />
                {items.name === "Monthly" &&
                  <Route
                    exact
                    path="/attendance/monthly"
                    element={<AttendanceMonthly />}
                  />
                }
                {items.name === "Fullday" &&
                  <Route
                    exact
                    path="/attendance/fullday"
                    element={<AttendanceFullday />}
                  />
                }
                {items.name === "Leaves" &&
                  <Route exact path="/leaves" element={<Leaves />} />
                }
                {items.name === "Meetings" &&
                  <Route exact path="/meetings" element={<Meetings />} />
                }
                {/* <Route exact path="/meetings/:usermeetId" element={<Meetings />} /> */}
                {items.name === "Clients" &&
                  <Route exact path="/company" element={<Clients />} />
                }
                {items.name === "Products" &&
                  <Route exact path="/products" element={<Products />} />
                }
                {items.name === "Spares" &&
                  <Route exact path="/spares" element={<Spares />} />
                }
                {items.name === "Settings" &&
                  <Route exact path="/settings" element={<SettingPage />} />
                }
                {/* {items.name === "Amc" && */}
                <Route exact path="/amc" element={<Amc />} />
                {/* } */}
                {items.name === "Localclaims" &&
                  <Route exact path="/localclaim" element={<LocalClaim />} />
                }
                {/* <Route exact path="/claims/manager" element={<ClaimsManager />} />
              <Route exact path="/claims/admin" element={<ClaimsAdmin />} /> */}
                {items.name === "Outstationclaims" &&
                  <Route
                    exact
                    path="/outstationclaim"
                    element={<OutStationClaim />}
                  />
                }
                <Route
                  exact
                  path="/meetingsmodel"
                  element={<MeetingDetailsModel />}
                />
                {items.name === "Schedule" &&
                  <Route exact path="/settings/schedule" element={<Schedule />} />
                }
                {items.name === "Trackclients" &&
                  <Route
                    exact
                    path="/settings/trackclients"
                    element={<TrackClients />}
                  />
                }
                {items.name === "Department" &&
                  <Route
                    exact
                    path="/settings/department"
                    element={<Department />}
                  />
                }
                {items.name === "Designation" &&
                  <Route
                    exact
                    path="/settings/designations"
                    element={<Designation />}
                  />
                }
                {items.name === "Meetingpurpose" &&
                  <Route
                    exact
                    path="/settings/meetingpurpose"
                    element={<MeetingPurpose />}
                  />
                }
                {items.name === "Basefare" &&
                  <Route exact path="/settings/basefare" element={<BaseFare />} />
                }
                {items.name === "Profile" &&
                  <Route exact path="/settings/profile" element={<Profile />} />
                }
                {items.name === "AccountSettings" &&
                  <Route
                    exact
                    path="/settings/accountsettings"
                    element={<AccountSettings />}
                  />
                }
                {items.name === "Accountdetails" &&
                  <Route
                    exact
                    path="/settings/accountdetails"
                    element={<AccountDetails />}
                  />
                }
                {items.name === "Devicedetails" &&
                  <Route
                    exact
                    path="/settings/devicedetails"
                    element={<DeviceDetails />}
                  />
                }
                <Route
                  exact
                  path="/kmrefresh/meetings"
                  element={<KilometerRefreshPage />}
                />
                <Route
                  exact
                  path="/kmrefresh/attendance"
                  element={<KilometerRefreshAttendance />}
                />
                <Route
                  exact
                  path="/allsubscriptiondetails"
                  element={<SubscriptionDetails />}
                />
                <Route
                  exact
                  path="/adddropdowndata"
                  element={<AddDropdownData />}
                />
                <Route
                  exact
                  path="/googleapikeylist"
                  element={<GoogleAPIHitCount />}
                />
                <Route
                  exact
                  path="/create/salesbee/subscription"
                  element={<CreateSubscription />}
                />
                <Route
                  exact
                  path="/exceptionhandler"
                  element={<ExceptionHandler />}
                />
                <Route
                  exact
                  path="/subscriptionmodule"
                  element={<MeetingManagement />}
                />
                <Route
                  exact
                  path="/subscription"
                  element={<SuscriberPage />}
                />
                <Route
                  exact
                  path="/projectManagement"
                  element={<ProjectManagement />}
                />
                <Route
                  exact
                  path="/humamResource"
                  element={<HumanResource />}
                />
                <Route
                  exact
                  path="/orderManagement"
                  element={<OrderManagement />}
                />
                <Route
                  exact
                  path="/stockManagement"
                  element={<StockManagement />}
                />
                <Route
                  exact
                  path="/warehouseManagement"
                  element={<WarehouseManagement />}
                />
                <Route
                  exact
                  path="/complaintManagement"
                  element={<ComplaintManagement />}
                />
                <Route
                  exact
                  path="/dealerManagement"
                  element={<DealerManagement />}
                />
                <Route
                  exact
                  path="/distributionManagement"
                  element={<DistributionManagement />}
                />
                <Route
                  exact
                  path="/settings/role"
                  element={<Role />}
                />
              </React.Fragment>
            ))
            }
            </>
            :
            <Route exact path="/dashboard" element={<Dashboard />} />
          }
          </>

          <Route exact path="*" element={<PageNotFound />} />
        </Routes>

      </>
    </div>
  );
}

export default App;
