import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import profileimg from "../../images/profile-1.png";
import Addmeetingmodel from "./Addmeetingmodel";
import Meetingdetails from "./Meetingdetails";
import TrackMeetings from "./TrackMeetings";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import SbAddBtn from "../SbButtons/SbAddBtn";

function Meetings() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [meetingdetails, setMeetingdetails] = useState(false);
  const [viewonmap, setViewonMap] = useState(false);
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [manager, setManager] = useState("");
  const [userID, setUserID] = useState("");
  const [meetingdetailsDate, setMeetingdetailsDate] = useState("");
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [meetingsData, setMeetingsData] = useState([]);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  let tableHeader = [
    "NAME",
    "DATE",
    "DIRECT/MY VISIT",
    "SCHEDULED VISIT",
    "TOTAL VISIT",
    "DISTANCE",
    "ACTION"
  ]

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const showAddmeetingmodel = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const goBacktomeetings = () => {
    setMeetingdetails(!meetingdetails);
  };
  const goBacktomeetingsfromtrackuser = () => {
    setViewonMap(!viewonmap);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/User/BindManagerDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  // GET MEETINGS DATA START
  useEffect(() => {
    getMeetings();
  }, [PageSize, currentPage, filterUserId, manager, fromDate, toDate]);

  const getMeetings = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&userId=${filterUserId}&managerId=${manager}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format("DD/MM/YYYY")}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetingsData(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setMeetingsData("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // GET MEETINGS DATA END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManager("");
    setFromDate(new Date());
    setToDate(new Date());
    setTotalData(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportAllMeetings = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingPageExcelExport?UserID=${filterUserId}&ManagerId=${manager}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Meetings.xlsx"
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                {!meetingdetails && !viewonmap ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                      {/* USER TABLE START */}
                      <div className="meetings-table">
                        <div className="seleting-menus">
                          <div className="page-title">MEETINGS</div>

                          <div className="page-top-bar-dropdowns">
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userList}
                              placeholder="Select User"
                              value={filterUserId === "" ? "" : userList.value}
                              onInputChange={userList.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            // isClearable={true}
                            />
                            {loggedInRole != 2 ? (
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={managerList}
                                placeholder="Select Manager"
                                value={manager === "" ? "" : managerList.value}
                                onInputChange={managerList.label}
                                onChange={(data) => setManager(data.value)}
                                isSearchable={true}
                              // isClearable={true}
                              />
                            ) : (
                              ""
                            )}

                            <div className="input-group from-to-date-select">
                              <span
                                className="input-group-text"
                                id="from-label"
                              >
                                From
                              </span>
                              <span className="date-picker-with-icon">
                                <DatePicker
                                  selected={fromDate}
                                  className="select from-date"
                                  id="from-datepicker"
                                  dateFormat="dd/MM/yyyy"
                                  selectsEnd
                                  // startDate={fromDate}
                                  endDate={toDate}
                                  onChange={(date) => setFromDate(date)}
                                  maxDate={toDate}
                                />
                                <i className="fa fa-calendar-o calander-icon" />
                              </span>

                              <span className="input-group-text" id="to-label">
                                To
                              </span>
                              <span className="date-picker-with-icon">
                                <DatePicker
                                  selected={toDate}
                                  className="select to-date"
                                  id="from-datepicker"
                                  dateFormat="dd/MM/yyyy"
                                  selectsEnd
                                  // startDate={fromDate}
                                  endDate={toDate}
                                  minDate={fromDate}
                                  onChange={(date) => setToDate(date)}
                                // maxDate={new Date()}
                                />
                                <i className="fa fa-calendar-o calander-icon" />
                              </span>
                            </div>

                            {/* <button
                    type="button"
                    className="btn btn-search-track"
                    onClick={() => getMeetings()}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-search"> </i>
                      <h6>Search</h6>
                    </span>
                  </button> */}

                            <button
                              type="button"
                              className="btn btn-search-track"
                              onClick={clearSerachBySelect}
                            >
                              <span className="btn-inline-item d-flex">
                                <i className="fa fa-eraser"> </i>
                                <h6>Clear</h6>
                              </span>
                            </button>

                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={optionsPageSizeList}
                              placeholder="Show Per Page"
                              value={optionsPageSizeList.value}
                              onInputChange={optionsPageSizeList.label}
                              onChange={(data) => setPageSize(data.value)}
                              isSearchable={true}
                            // isClearable={true}
                            />

                            {/* <button
                              type="button"
                              className="btn btn-add-meeting"
                              onClick={showAddmeetingmodel} >
                              <div className="btn-inline-item d-flex">
                                <i className="fa fa-handshake-o" />
                                <h6>Add</h6>
                              </div>
                            </button> */}
                            <SbAddBtn bntName="Add" onClickEffect={showAddmeetingmodel} />
                          </div>
                        </div>

                        <div className="table-responsive meetings-scrollbar">
                          <table className="table meetings-list-table">
                            <SbTableHeader tableHeader={tableHeader} />
                            <tbody>
                              {isLoading ? (
                                <>
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                </>
                              ) : meetingsData !== "" ? (
                                meetingsData.map((data, i) => (
                                  <tr className="meeting-list-row-data" key={i}>
                                    <td>
                                      <span className="meeting-list-name d-flex">
                                        <img
                                          className="table-data-meeting-imgages"
                                          src={profileimg}
                                          alt="John Doe"
                                        />
                                        <div>
                                          <h6 className="fz13" style={{ fontWeight: "600" }}>{data?.fullName}</h6>
                                          <p style={{ fontSize: "11px", color: "#d3b900", fontWeight: "700", cursor: "pointer" }}>{data?.userCode}</p>
                                        </div>
                                      </span>
                                    </td>

                                    <td>
                                      {moment(data?.meetingDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td align="center">{data?.directVisit}</td>
                                    <td align="center">{data?.scheduleVisit}</td>
                                    <td align="center">{data?.countOfMeetings}</td>
                                    <td align="center">{(data?.totalDistance).toFixed(2)}</td>
                                    <td>
                                      <span className="meeting-action-td">
                                        <button
                                          className="meeting-detail-link"
                                          onClick={() => {
                                            setMeetingdetails(!meetingdetails);
                                            setUserID(data?.userId);
                                            setMeetingdetailsDate(
                                              data?.meetingDate
                                            );
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>{" "}
                                          Details
                                        </button>

                                        <button
                                          className="meeting-view-link"
                                          onClick={() => {
                                            setViewonMap(!viewonmap);
                                            setUserID(data?.userId);
                                            setMeetingdetailsDate(
                                              data?.meetingDate
                                            );
                                          }}
                                        >
                                          <i className="bi bi-geo-alt-fill"></i>{" "}
                                          View On Map
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr className="no-data-found">
                                  ------ NO DATA FOUND ------
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="download-and-pagination">
                          <div className="data-count-per-page">
                            {totalData === 0 ? 0 : pageDataFrom} -{" "}
                            {totalData === 0 ? 0 : pageDataTill} of {totalData}
                          </div>
                          <button
                            type="button"
                            className="btn btn-download-excel"
                            onClick={exportAllMeetings}
                          >
                            <i className="fa fa-download" /> &nbsp; Download
                          </button>
                          <span className="user-pagination">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel={
                                <i className="fa fa-angle-double-right" />
                              }
                              onPageChange={handlePageClick}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              pageCount={pageCount}
                              previousLabel={
                                <i className="fa fa-angle-double-left" />
                              }
                              renderOnZeroPageCount={null}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                              disabledClassName={"disabled"}
                            />
                          </span>
                        </div>
                      </div>
                      {/* USER TABLE END */}

                      {/* ADD MEETING MODEL START */}
                      {show ? (
                        <Addmeetingmodel
                          show={show}
                          handleClose={handleClose}
                          getMeetings={getMeetings}
                        />
                      ) : (
                        ""
                      )}
                      {/* ADD MEETING MODEL END */}

                      {/* PAGE CONTENT END*/}
                    </div>
                  </div>
                ) : (
                  <>
                    {meetingdetails ? (
                      <Meetingdetails
                        meetingdetails={meetingdetails}
                        goBacktomeetings={goBacktomeetings}
                        toggleshift={toggleshift}
                        userID={userID}
                        meetingdetailsDate={meetingdetailsDate}
                      />
                    ) : (
                      ""
                    )}

                    {viewonmap ? (
                      <TrackMeetings
                        viewonmap={viewonmap}
                        goBacktomeetings={goBacktomeetingsfromtrackuser}
                        toggleshift={toggleshift}
                        userID={userID}
                        meetingdetailsDate={meetingdetailsDate}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Meetings;
