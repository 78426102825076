import React, { useState } from 'react'
import Sidenavbar from '../Sidenavbar'
import Topbar from '../Topbar'
import { Card, Col, Row } from 'react-bootstrap';
import '../../styles/settings.scss';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function SettingPage() {

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const [menuItems, setMenuItems] = useState([])
    const [submenuStg, setSubmenuStg] = useState([])

    useEffect(() => {
        let activities = [];
        let subActStng = [];
        if (resPassData?.subscription?.modules.length !== 0) {
            resPassData?.subscription?.modules.map((item) => {
                if (item.parentModuleName === null) {
                    activities.push({ mainItem: item, subNames: [] })
                }
                else if (item.parentModuleName === "Settings") {
                    subActStng.push(item)
                }
            })


        }
        setMenuItems(activities)
        setSubmenuStg(subActStng)
    }, [])

    useEffect(() => {
        if (menuItems) {
            menuItems.map((activities) => {
                if (activities.mainItem.moduleName === "Settings") {
                    activities.subNames = submenuStg
                }
            })
        }
    }, [menuItems, submenuStg])

    const [searchValue, setSearchValue] = useState('')
    const [filteredData, setFilteredData] = useState('')

    const searchData = (value) => {
        setSearchValue(value)
        if (value) {
            const newItem = submenuStg.filter((newVal) => {
                // if (newVal.moduleName.includes.startsWith(value))
                return newVal.moduleName.toLowerCase().startsWith(value.toLowerCase())
            });
            setFilteredData(newItem);
        }
    }


    const filterItem = (curcat) => {
        const newItem = submenuStg.filter((newVal) => {
            return newVal.moduleName === curcat;
        });
        setFilteredData(newItem);
    };

    const [displayData, setDisplayData] = useState([])
    useEffect(() => {
        if (!searchValue) {
            setDisplayData(submenuStg)
        } else {
            setDisplayData(filteredData)
        }
    }, [searchValue, submenuStg, filteredData, displayData])


    return (
        <div id="main-page">
            {/* top bar and sidebar start */}
            <div className="top-and-sidebar">
                <Sidenavbar
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />

                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
            </div>
            {/* top bar and sidebar end */}

            {/* page content start */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="user-table">
                            <div className="setting-page-header">
                                <div className='input-div' style={{ width: "40%" }}>
                                    <input type="text" placeholder='Find a setting' className='form-control setting-input'
                                        value={searchValue} onChange={(e) => searchData(e.target.value)} />
                                    <i className='fa fa-search search-icon' ></i>
                                </div>
                            </div>
                            <div>
                                <Row className="sb-subscription-module" >
                                    {displayData && displayData.map((items, i) => (
                                        <Col md={4} style={{ padding: "0px 15px" }} key={i}>
                                            <Link to={"/settings/" + items.moduleName}>
                                                <Card className='sb-setting-card'>
                                                    <div align="center" className='setting-card'>
                                                        <Card.Img className='sb-module-card-img' variant="top" src={items.mainModuleIcon} />
                                                        <Card.Body className='setting-card-body'>
                                                            <Card.Title className='sb-card-title'>{items.moduleName}</Card.Title>
                                                            <Card.Text>
                                                                {items.mainModuleDescription}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>

                                    ))}


                                </Row>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* page content end */}
        </div>
    )
}

export default SettingPage
