import React, { useState, useEffect } from "react";
import "../../styles/Claims.scss";
import profileimg from "../../images/profile-1.png";
import { Link } from "react-router-dom";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { exportExcel } from "../DownloadFile";
import "react-datepicker/dist/react-datepicker.css";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import AlertModel from "../AlertModel";
import {
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import MeetingDetailsModel from "./MeetingDetailsModel";
import MeeterReadingModel from "./MeeterReadingModel";

// this page should not accesible by manager login verify that role id = 1 in login user details

function PendingClaimAdmin(props) {
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [pendingForApprovalData, setPendingForApprovalData] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [localClaimAlertMsg, setLocalClaimAlertMsg] = useState({
    claimId: null,
    message: null,
  });
  const [outStationClaimAlertMsg, setOutStationClaimAlertMsg] = useState({
    claimId: null,
    message: null,
  });
  const [foodClaimAlertMsg, setFoodClaimAlertMsg] = useState({
    claimId: null,
    message: null,
  });
  const [hotelClaimAlertMsg, setHotelClaimAlertMsg] = useState({
    claimId: null,
    message: null,
  });
  const [otherClaimAlertMsg, setOtherClaimAlertMsg] = useState({
    claimId: null,
    message: null,
  });

  const [visitDetailsModel, setVisitDetailsModel] = useState(false);
  const [meeterReadingModel, setMeeterReadingModel] = useState(false);
  const [totalbySection, setTotalbySection] = useState({
    localTotal: 0,
    totalHotel: 0,
    totalFood: 0,
    totalOutstation: 0,
    totalOthers: 0,
  });
  const [UserId, setUserId] = useState("");
  const [ViewDate, setViewDate] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  useEffect(() => {
    fetchManagerPendingApproval();
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER PENDING FOR APPROVAL CLAIM DETAILS START
  const fetchManagerPendingApproval = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Claim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${props.claimUserId
      }&StatusID=42&SearchStatusType=Admin&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setIsLoading(false);
          res.result.forEach((element) => {
            element.inputState = {
              chkSelectClaim: false,
              approvedLocalClaimAmount: 0,
              localClaimRemark: "",
              approvedOSClaimAmount: 0,
              osClaimRemark: "",
              approvedFoodClaimAmount: 0,
              foodClaimRemark: "",
              approvedHotelClaimAmount: 0,
              hotelClaimRemark: "",
              approvedOthersClaimAmount: 0,
              othersClaimRemark: "",
              approvedTotalClaimAmount: 0,
              rejectedTotalClaimAmount: 0,
              totalAppliedClaimAmmount: element.totalAmount,
              remark: "",
              date: "",
              approvalType: 1, //Admin aprooval
              claimID: element.claimID,
            };
          });
          const localTotal = res.result.map((data) => data.localClaim.amount);
          const hotelTotal = res.result.map((data) => data.hotelClaim.amount);
          const foodTotal = res.result.map((data) => data.foodClaim.amount);
          const outstationTotal = res.result.map((data) => data.osClaim.amount);
          const othersTotal = res.result.map((data) => data.othersClaim.amount);
          // let sumLocal = localTotal.reduce((a, b) => a + b, 0);
          setTotalbySection({
            localTotal: localTotal.reduce((a, b) => a + b, 0),
            totalHotel: hotelTotal.reduce((a, b) => a + b, 0),
            totalFood: foodTotal.reduce((a, b) => a + b, 0),
            totalOutstation: outstationTotal.reduce((a, b) => a + b, 0),
            totalOthers: othersTotal.reduce((a, b) => a + b, 0),
            grandTotal: 0,
          });
          setPendingForApprovalData(res?.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
        } else {
          setPendingForApprovalData("");
          setIsLoading(false);
        }
      });
  };
  // GET MANAGER PENDING FOR APPROVAL CLAIM DETAILS END

  const setClaimNumber = (state, claimID) => {
    if (claimID != 0) {
      let claim = pendingForApprovalData.filter((x) => x.claimID == claimID);
      claim[0].inputState.chkSelectClaim = state;
      calclulateDefaultAmount(claim[0]);
    } else {
      pendingForApprovalData.forEach((claim) => {
        claim.inputState.chkSelectClaim = state;
        calclulateDefaultAmount(claim);
      });
    }
    setPendingForApprovalData([...pendingForApprovalData]);
  };

  const calclulateDefaultAmount = (claim) => {
    if (claim.inputState.chkSelectClaim) {
      claim.inputState.approvedLocalClaimAmount = claim.localClaim.amount;
      claim.inputState.approvedOSClaimAmount = claim.osClaim.amount;
      claim.inputState.approvedFoodClaimAmount = claim.foodClaim.amount;
      claim.inputState.approvedHotelClaimAmount = claim.hotelClaim.amount;
      claim.inputState.approvedOthersClaimAmount = claim.othersClaim.amount;
      claim.inputState.approvedTotalClaimAmount = claim.totalAmount;
      claim.inputState.rejectedTotalClaimAmount = 0;
    } else {
      claim.inputState.approvedLocalClaimAmount = 0;
      claim.inputState.approvedOSClaimAmount = 0;
      claim.inputState.approvedFoodClaimAmount = 0;
      claim.inputState.approvedHotelClaimAmount = 0;
      claim.inputState.approvedOthersClaimAmount = 0;
      claim.inputState.approvedTotalClaimAmount = 0;
      claim.inputState.rejectedTotalClaimAmount = 0;
    }
  };

  const claimCalculation = (claim) => {
    claim.inputState.approvedTotalClaimAmount =
      claim.inputState.approvedLocalClaimAmount +
      claim.inputState.approvedOSClaimAmount +
      claim.inputState.approvedFoodClaimAmount +
      claim.inputState.approvedHotelClaimAmount +
      claim.inputState.approvedOthersClaimAmount;
    claim.inputState.rejectedTotalClaimAmount =
      claim.totalAmount - claim.inputState.approvedTotalClaimAmount;
  };

  // input handlechange
  const handleInput = (value, name, claimid) => {
    let claim = pendingForApprovalData.filter(
      (data) => data.claimID == claimid
    );
    claim[0].inputState[name] = value;
    claimCalculation(claim[0]);
    setPendingForApprovalData([...pendingForApprovalData]);
  };
  // input handlechange

  const approveClaimAmount = (e) => {
    let singleClaimData = pendingForApprovalData.filter(
      (data) => data.inputState.chkSelectClaim == true
    );

    if (singleClaimData.length == 1) {
      if (
        singleClaimData[0].inputState.approvedLocalClaimAmount <=
        singleClaimData[0].localClaim.amount &&
        singleClaimData[0].inputState.approvedOSClaimAmount <=
        singleClaimData[0].osClaim.amount &&
        singleClaimData[0].inputState.approvedFoodClaimAmount <=
        singleClaimData[0].foodClaim.amount &&
        singleClaimData[0].inputState.approvedHotelClaimAmount <=
        singleClaimData[0].hotelClaim.amount &&
        singleClaimData[0].inputState.approvedOthersClaimAmount <=
        singleClaimData[0].othersClaim.amount
      ) {
        // if (
        //   singleClaimData[0].inputState.approvedLocalClaimAmount <
        //     singleClaimData[0].localClaim.amount &&
        //   singleClaimData[0].inputState.localClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedOSClaimAmount <
        //     singleClaimData[0].osClaim.amount &&
        //   singleClaimData[0].inputState.osClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedFoodClaimAmount <
        //     singleClaimData[0].foodClaim.amount &&
        //   singleClaimData[0].inputState.foodClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedHotelClaimAmount <
        //     singleClaimData[0].hotelClaim.amount &&
        //   singleClaimData[0].inputState.hotelClaimRemark != "" &&
        //   singleClaimData[0].inputState.approvedOthersClaimAmount <
        //     singleClaimData[0].othersClaim.amount &&
        //   singleClaimData[0].inputState.othersClaimRemark != ""
        // ) {
        // if (
        //   localClaimAlertMsg.message == "" &&
        //   outStationClaimAlertMsg.message == "" &&
        //   foodClaimAlertMsg.message == "" &&
        //   hotelClaimAlertMsg.message == "" &&
        //   otherClaimAlertMsg.message == ""
        // ) {
        let claimApprovedData = {
          date: new Date(),
          approvedLocalClaimAmount:
            singleClaimData[0].inputState.approvedLocalClaimAmount,
          approvedLocalClaimRemark:
            singleClaimData[0].inputState.localClaimRemark,
          approvedOSClaimAmount:
            singleClaimData[0].inputState.approvedOSClaimAmount,
          approvedOSClaimRemark: singleClaimData[0].inputState.osClaimRemark,
          approvedFoodClaimAmount:
            singleClaimData[0].inputState.approvedFoodClaimAmount,
          approvedFoodClaimRemark:
            singleClaimData[0].inputState.foodClaimRemark,
          approvedHotelClaimAmount:
            singleClaimData[0].inputState.approvedHotelClaimAmount,
          approvedHotelClaimRemark:
            singleClaimData[0].inputState.hotelClaimRemark,
          approvedOthersClaimAmount:
            singleClaimData[0].inputState.approvedOthersClaimAmount,
          approvedOthersClaimRemark:
            singleClaimData[0].inputState.othersClaimRemark,
          approvedTotalClaimAmount:
            singleClaimData[0].inputState.approvedTotalClaimAmount,
          rejectedTotalClaimAmount:
            singleClaimData[0].inputState.rejectedTotalClaimAmount,
          claimID: singleClaimData[0].inputState.claimID,
          approvalType: 1,
          totalAppliedClaimAmmount:
            singleClaimData[0].inputState.totalAppliedClaimAmmount,
          remark: singleClaimData[0].inputState.remark,
        };
        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: travelizeAuth,
          body: JSON.stringify(claimApprovedData),
        };

        fetch(`${baseURL}/api/Claim/ApproveClaim`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              setShowAlert(true);
              setAlertMessage({
                alertType: "success",
                message: `Claim Approved ${res.message}`,
              });
              setTimeout(() => {
                props.fetchAdminClaimDetails();
                props.goBackToClaimfromPendingPage();
              }, 1000);
            } else {
              setShowAlert(true);
              setAlertMessage({
                alertType: "fails",
                message: res.errorMessage,
              });
            }
          })
          .catch((error) => console.error(error));
        // } else {
        //   setShowAlert(true);
        //   setAlertMessage({
        //     alertType: "fails",
        //     message: "Please enter remarks",
        //   });
        // }
      } else {
        setShowAlert(true);
        setAlertMessage({
          alertType: "fails",
          message: "Please clear the error",
        });
      }
      // e.preventDefault();
    } else {
      let claimApprovedData = [];
      for (let i = 0; i < singleClaimData.length; i++) {
        if (
          singleClaimData[i].inputState.approvedLocalClaimAmount <=
          singleClaimData[i].localClaim.amount &&
          singleClaimData[i].inputState.approvedOSClaimAmount <=
          singleClaimData[i].osClaim.amount &&
          singleClaimData[i].inputState.approvedFoodClaimAmount <=
          singleClaimData[i].foodClaim.amount &&
          singleClaimData[i].inputState.approvedHotelClaimAmount <=
          singleClaimData[i].hotelClaim.amount &&
          singleClaimData[i].inputState.approvedOthersClaimAmount <=
          singleClaimData[i].othersClaim.amount
        ) {
          claimApprovedData.push({
            date: new Date(),
            approvedLocalClaimAmount:
              singleClaimData[i].inputState.approvedLocalClaimAmount,
            approvedLocalClaimRemark:
              singleClaimData[i].inputState.localClaimRemark,
            approvedOSClaimAmount:
              singleClaimData[i].inputState.approvedOSClaimAmount,
            approvedOSClaimRemark: singleClaimData[i].inputState.osClaimRemark,
            approvedFoodClaimAmount:
              singleClaimData[i].inputState.approvedFoodClaimAmount,
            approvedFoodClaimRemark:
              singleClaimData[i].inputState.foodClaimRemark,
            approvedHotelClaimAmount:
              singleClaimData[i].inputState.approvedHotelClaimAmount,
            approvedHotelClaimRemark:
              singleClaimData[i].inputState.hotelClaimRemark,
            approvedOthersClaimAmount:
              singleClaimData[i].inputState.approvedOthersClaimAmount,
            approvedOthersClaimRemark:
              singleClaimData[i].inputState.othersClaimRemark,
            approvedTotalClaimAmount:
              singleClaimData[i].inputState.approvedTotalClaimAmount,
            rejectedTotalClaimAmount:
              singleClaimData[i].inputState.rejectedTotalClaimAmount,
            claimID: singleClaimData[i].inputState.claimID,
            approvalType: 1,
            totalAppliedClaimAmmount:
              singleClaimData[i].inputState.totalAppliedClaimAmmount,
            remark: singleClaimData[i].inputState.remark,
          });

          let requestoption = {
            method: "POST",
            mode: "cors",
            headers: travelizeAuth,
            body: JSON.stringify(claimApprovedData),
          };

          fetch(`${baseURL}/api/Claim/BulkApproveClaim`, requestoption)
            .then((res) => res.json())
            .then((res) => {
              if (res.success) {
                setShowAlert(true);
                setAlertMessage({
                  alertType: "success",
                  message: `BulkClaim Approved ${res.message}`,
                });
                setTimeout(() => {
                  props.fetchAdminClaimDetails();
                  props.goBackToClaimfromPendingPage();
                }, 1000);
              } else {
                setShowAlert(true);
                setAlertMessage({
                  alertType: "fails",
                  message: res.errorMessage,
                });
              }
            })
            .catch((error) => console.error(error));
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: "Please clear the error",
          });
        }
      }
      e.preventDefault();
    }
    // e.preventDefault();
    // fetchManagerPendingApproval();
  };

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const openVisitDetailsModel = (userId, date) => {
    setVisitDetailsModel(!visitDetailsModel);
    setUserId(userId);
    setViewDate(date);
  };

  const openMeeterReading = (userId, date) => {
    setMeeterReadingModel(!meeterReadingModel);
    setUserId(userId);
    setViewDate(date);
  };

  const closeVisitModel = () => {
    setVisitDetailsModel(!visitDetailsModel);
  };

  const closeMeetrReadModel = () => {
    setMeeterReadingModel(!meeterReadingModel);
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimDetailExcelExport?UserID=${props.claimUserId
      }&StatusID=42&SearchStatusType=Admin&FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Pending-ClaimData.xlsx"
    );
  };

  return (
    <div id="main-page">
      {props.pendingforApproval ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* LEADS TABLE START */}
            <div className="claims-table">
              <div className="seleting-menus">
                <div className="page-title claim-approval">
                  Pending for Approval by Admin
                </div>
                <div className="page-top-bar-dropdowns">
                  <div className="input-group from-to-date-select">
                    <span className="input-group-text" id="from-label">
                      From
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={toDate}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>

                    <span className="input-group-text" id="to-label">
                      To
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-search-track"
                    onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                  // isClearable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-user"
                    onClick={props.goBackToClaimfromPendingPage}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-arrow-left"> </i>
                      <h6>Back</h6>
                    </span>
                  </button>
                </div>
              </div>

              <div className="pendingfor-approval">
                {isLoading ? (
                  <SkeletonPendingClaimHead />
                ) : pendingForApprovalData !== "" &&
                  pendingForApprovalData !== null ? (
                  <div className="pendingapproval-records">
                    <div className="claim-user-details-img">
                      <img
                        className="pendingapproval-user-imgage"
                        src={profileimg}
                        alt="User Image"
                      />
                      <div className="pendingapproval-username-details">
                        <span>
                          <b>User Name :</b> &nbsp;
                          {pendingForApprovalData[0]?.fullName}
                        </span>
                        <span>
                          <b>Email ID :</b> &nbsp;
                          {pendingForApprovalData[0]?.email}
                        </span>
                        <span>
                          <b>Contact No.:</b> &nbsp;
                          {pendingForApprovalData[0]?.countryCode}
                          {pendingForApprovalData[0]?.contact}
                        </span>
                      </div>
                    </div>

                    <div className="claim-total-by-section1">
                      <div>Total Local : {totalbySection.localTotal}</div>
                      <div>Total Hotel : {totalbySection.totalHotel}</div>
                      <div>Total Food : {totalbySection.totalFood}</div>
                    </div>
                    <div className="claim-total-by-section2">
                      <div>
                        Total Outstation : {totalbySection.totalOutstation}
                      </div>
                      <div>Total Others : {totalbySection.totalOthers}</div>
                      <div>
                        Grand Total : &nbsp;
                        <b>
                          {totalbySection.localTotal +
                            totalbySection.totalHotel +
                            totalbySection.totalFood +
                            totalbySection.totalOutstation +
                            totalbySection.totalOthers}{" "}
                        </b>
                      </div>
                    </div>

                    <span className="claim-select-all">
                      Select All &nbsp;
                      <input
                        className="select-all-checkbox"
                        type="checkbox"
                        value=""
                        id="0"
                        onClick={(e) => {
                          setClaimNumber(e.target.checked, +0);
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <div className="table-responsive pendingapproval-scrollbar">
                  {isLoading ? (
                    <SkeletonPendingTableData />
                  ) : pendingForApprovalData !== "" &&
                    pendingForApprovalData !== null ? (
                    pendingForApprovalData.map((data, i) => (
                      <table className="table pendingapproval-table">
                        <thead className="thead-light pendingapproval-thead">
                          <tr>
                            <th>
                              {i + 1}. Date : &nbsp;
                              {moment(data?.claimDate).format("DD/MM/YYYY")}
                            </th>
                            <th>
                              No.Of Visits :{" "}
                              <Link
                                to="#"
                                onClick={() =>
                                  openVisitDetailsModel(
                                    data?.userID,
                                    data?.claimDate
                                  )
                                }
                              >
                                {data?.noOfVisit}
                              </Link>
                            </th>
                            <th>
                              Kms Travelled : {data?.kMsTravelled} KM{" "}
                              <Link
                                to="#"
                                onClick={() =>
                                  openMeeterReading(
                                    data?.userID,
                                    data?.claimDate
                                  )
                                }
                              >
                                Meter Reading
                              </Link>
                            </th>
                            <th>Status : {data?.byAdmin?.status}</th>
                            <th></th>
                            <th>
                              <input
                                className="select-checkbox"
                                type="checkbox"
                                // id={data?.claimID}
                                onClick={(e) =>
                                  setClaimNumber(
                                    e.target.checked,
                                    data?.claimID
                                  )
                                }
                                checked={data?.inputState?.chkSelectClaim}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>Type</th>
                            <th>Attachment</th>
                            <th>Applied Amount</th>
                            <th>Approved Amount</th>
                            <th>Remarks</th>
                            <th></th>
                          </tr>
                        </thead>
                        <>
                          <>
                            {data?.inputState?.claimID == data?.claimID && (
                              <tbody>
                                <tr className="pendingapproval-row-data">
                                  <td>Local By {data?.localClaim?.mot}</td>
                                  <td>
                                    <i
                                      className="fa fa-paperclip"
                                      onClick={() => {
                                        setClaimAttachmentmodel(
                                          !claimAttachmentmodel
                                        );
                                        setAttachmentUrl(
                                          data?.localClaim?.attachment ?? ""
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{data?.localClaim?.amount}</td>
                                  <td>
                                    <input
                                      className="form-control input-approve-amount"
                                      type="number"
                                      id={data?.claimID}
                                      name="local"
                                      value={
                                        data?.inputState
                                          ?.approvedLocalClaimAmount
                                      }
                                      onChange={(e) => {
                                        const value =
                                          e.target.value.trim() != ""
                                            ? +e.target.value
                                            : 0;
                                        handleInput(
                                          +e.target.value,
                                          "approvedLocalClaimAmount",
                                          data.claimID
                                        );
                                        if (e.target.value.trim() == "") {
                                          setLocalClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter a value",
                                          });
                                        } else if (
                                          value > data?.localClaim?.amount
                                        ) {
                                          setLocalClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not more than claim amount !",
                                          });
                                        } else if (
                                          value < data?.localClaim?.amount
                                        ) {
                                          setLocalClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter remarks",
                                          });
                                        } else if (value < 0) {
                                          setLocalClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not be less that 0 !",
                                          });
                                        } else {
                                          setLocalClaimAlertMsg({
                                            claimId: "",
                                            message: "",
                                          });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control form-control-sm remarks-td"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Remarks"
                                      value={data.inputState.localClaimRemark}
                                      onChange={(e) =>
                                        handleInput(
                                          e.target.value,
                                          "localClaimRemark",
                                          data.claimID
                                        )
                                      }
                                    />

                                    {localClaimAlertMsg?.claimId ==
                                      data?.claimID ? (
                                      <span className="error-input-message">
                                        {data.inputState.localClaimRemark ==
                                          "" ||
                                          data?.localClaim?.amount <
                                          data?.inputState
                                            ?.approvedLocalClaimAmount
                                          ? localClaimAlertMsg?.message
                                          : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr className="pendingapproval-row-data">
                                  <td>Outstation By {data?.osClaim?.mot}</td>
                                  <td>
                                    <i
                                      className="fa fa-paperclip"
                                      onClick={() => {
                                        setClaimAttachmentmodel(
                                          !claimAttachmentmodel
                                        );
                                        setAttachmentUrl(
                                          data?.osClaim?.attachment ?? ""
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{data?.osClaim?.amount}</td>
                                  <td>
                                    <input
                                      type="number"
                                      id={data?.claimID}
                                      className="form-control input-approve-amount"
                                      name="outStation"
                                      value={
                                        data.inputState.approvedOSClaimAmount
                                      }
                                      onChange={(e) => {
                                        const value =
                                          e.target.value.trim() != ""
                                            ? +e.target.value
                                            : 0;
                                        handleInput(
                                          +e.target.value,
                                          "approvedOSClaimAmount",
                                          data.claimID
                                        );
                                        if (e.target.value.trim() == "") {
                                          setOutStationClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter a value",
                                          });
                                        } else if (
                                          value > data?.osClaim?.amount
                                        ) {
                                          setOutStationClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not more than claim amount !",
                                          });
                                        } else if (
                                          value < data?.osClaim?.amount
                                        ) {
                                          setOutStationClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter remarks",
                                          });
                                        } else if (value < 0) {
                                          setOutStationClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not be less that 0 !",
                                          });
                                        } else {
                                          setOutStationClaimAlertMsg({
                                            claimId: "",
                                            message: "",
                                          });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control form-control-sm remarks-td"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Remarks"
                                      value={data.inputState.osClaimRemark}
                                      onChange={(e) =>
                                        handleInput(
                                          e.target.value,
                                          "osClaimRemark",
                                          data.claimID
                                        )
                                      }
                                    />
                                    {outStationClaimAlertMsg.claimId ==
                                      data.claimID ? (
                                      <span className="error-input-message">
                                        {data.inputState.osClaimRemark == "" ||
                                          data?.osClaim?.amount <
                                          data?.inputState
                                            ?.approvedOSClaimAmount
                                          ? outStationClaimAlertMsg.message
                                          : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr className="pendingapproval-row-data">
                                  <td>Food</td>
                                  <td>
                                    <i
                                      className="fa fa-paperclip"
                                      onClick={() => {
                                        setClaimAttachmentmodel(
                                          !claimAttachmentmodel
                                        );
                                        setAttachmentUrl(
                                          data?.foodClaim?.attachment ?? ""
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{data?.foodClaim?.amount}</td>
                                  <td>
                                    <input
                                      type="number"
                                      id={data?.claimID}
                                      className="form-control input-approve-amount"
                                      name="food"
                                      value={
                                        data.inputState.approvedFoodClaimAmount
                                      }
                                      onChange={(e) => {
                                        const value =
                                          e.target.value.trim() != ""
                                            ? +e.target.value
                                            : 0;
                                        handleInput(
                                          +e.target.value,
                                          "approvedFoodClaimAmount",
                                          data.claimID
                                        );
                                        if (e.target.value.trim() == "") {
                                          setFoodClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter a value",
                                          });
                                        } else if (
                                          value > data?.foodClaim?.amount
                                        ) {
                                          setFoodClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not more than claim amount !",
                                          });
                                        } else if (value < 0) {
                                          setFoodClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not be less that 0 !",
                                          });
                                        } else if (
                                          value < data?.foodClaim?.amount
                                        ) {
                                          setFoodClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter remarks",
                                          });
                                        } else {
                                          setFoodClaimAlertMsg({
                                            claimId: "",
                                            message: "",
                                          });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control form-control-sm remarks-td"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Remarks"
                                      value={data.inputState.foodClaimRemark}
                                      onChange={(e) =>
                                        handleInput(
                                          e.target.value,
                                          "foodClaimRemark",
                                          data.claimID
                                        )
                                      }
                                    />
                                    {foodClaimAlertMsg.claimId ==
                                      data.claimID ? (
                                      <span className="error-input-message">
                                        {data?.inputState?.foodClaimRemark ==
                                          "" ||
                                          data?.foodClaim?.amount <
                                          data.inputState
                                            .approvedFoodClaimAmount
                                          ? foodClaimAlertMsg?.message
                                          : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr className="pendingapproval-row-data">
                                  <td>Hotel</td>
                                  <td>
                                    <i
                                      className="fa fa-paperclip"
                                      onClick={() => {
                                        setClaimAttachmentmodel(
                                          !claimAttachmentmodel
                                        );
                                        setAttachmentUrl(
                                          data?.hotelClaim?.attachment ?? ""
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{data?.hotelClaim?.amount}</td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control input-approve-amount"
                                      type="number"
                                      name="hotel"
                                      value={
                                        data.inputState.approvedHotelClaimAmount
                                      }
                                      onChange={(e) => {
                                        const value =
                                          e.target.value.trim() != ""
                                            ? +e.target.value
                                            : 0;
                                        handleInput(
                                          +e.target.value,
                                          "approvedHotelClaimAmount",
                                          data.claimID
                                        );
                                        if (e.target.value.trim() == "") {
                                          setHotelClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter a value",
                                          });
                                        } else if (
                                          value > data?.hotelClaim?.amount
                                        ) {
                                          setHotelClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not more than claim amount !",
                                          });
                                        } else if (
                                          value < data?.hotelClaim?.amount
                                        ) {
                                          setHotelClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter remarks",
                                          });
                                        } else if (value < 0) {
                                          setHotelClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not be less that 0 !",
                                          });
                                        } else {
                                          setHotelClaimAlertMsg({
                                            claimId: "",
                                            message: "",
                                          });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control form-control-sm remarks-td"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Remarks"
                                      value={data?.inputState?.hotelClaimRemark}
                                      onChange={(e) =>
                                        handleInput(
                                          e.target.value,
                                          "hotelClaimRemark",
                                          data.claimID
                                        )
                                      }
                                    />
                                    {hotelClaimAlertMsg.claimId ==
                                      data.claimID ? (
                                      <span className="error-input-message">
                                        {data?.inputState?.hotelClaimRemark ==
                                          "" ||
                                          data?.hotelClaim?.amount <
                                          data?.inputState
                                            ?.approvedHotelClaimAmount
                                          ? hotelClaimAlertMsg.message
                                          : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr className="pendingapproval-row-data">
                                  <td>Others</td>
                                  <td>
                                    <i
                                      className="fa fa-paperclip"
                                      onClick={() => {
                                        setClaimAttachmentmodel(
                                          !claimAttachmentmodel
                                        );
                                        setAttachmentUrl(
                                          data?.othersClaim?.attachment ?? ""
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{data?.othersClaim?.amount}</td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control input-approve-amount"
                                      type="number"
                                      name="others"
                                      value={
                                        data.inputState
                                          .approvedOthersClaimAmount
                                      }
                                      onChange={(e) => {
                                        const value =
                                          e.target.value.trim() != ""
                                            ? +e.target.value
                                            : 0;
                                        handleInput(
                                          +e.target.value,
                                          "approvedOthersClaimAmount",
                                          data.claimID
                                        );
                                        if (e.target.value.trim() == "") {
                                          setOtherClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter a value",
                                          });
                                        } else if (
                                          value > data?.othersClaim?.amount
                                        ) {
                                          setOtherClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not more than claim amount !",
                                          });
                                        } else if (
                                          value < data?.othersClaim?.amount
                                        ) {
                                          setOtherClaimAlertMsg({
                                            claimId: data.claimID,
                                            message: "Please enter remarks",
                                          });
                                        } else if (value < 0) {
                                          setOtherClaimAlertMsg({
                                            claimId: data.claimID,
                                            message:
                                              "Amount should not be less that 0 !",
                                          });
                                        } else {
                                          setOtherClaimAlertMsg({
                                            claimId: "",
                                            message: "",
                                          });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={data?.claimID}
                                      className="form-control form-control-sm remarks-td"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Remarks"
                                      value={
                                        data?.inputState?.othersClaimRemark
                                      }
                                      onChange={(e) =>
                                        handleInput(
                                          e.target.value,
                                          "othersClaimRemark",
                                          data.claimID
                                        )
                                      }
                                    />
                                    {otherClaimAlertMsg.claimId ==
                                      data.claimID ? (
                                      <span className="error-input-message">
                                        {data?.inputState?.othersClaimRemark ==
                                          "" ||
                                          data?.othersClaim?.amount <
                                          data.inputState
                                            .approvedOthersClaimAmount
                                          ? otherClaimAlertMsg?.message
                                          : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                {/* <tr className="pendingapproval-row-data">
                                  <td>Remarks</td>
                                  <td colSpan={4}>
                                    <input
                                      id={data?.claimID}
                                      className="form-control form-control-sm remarks-td"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Remarks"
                                      value={data.inputState.remark}
                                      onChange={(e) =>
                                        handleInput(
                                          e.target.value,
                                          "remark",
                                          data.claimID
                                        )
                                      }
                                    />
                                    {data?.totalAmount !==
                                    data?.inputState
                                      ?.approvedTotalClaimAmount ? (
                                      data?.inputState?.remark === "" ? (
                                        <span className="error-input-message">
                                          Please enter remarks
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr> */}
                                <tr className="pendingapproval-row-data total-value">
                                  <td></td>
                                  <td>
                                    <b>TOTAL</b>
                                  </td>
                                  <td>
                                    <b>{data?.totalAmount}</b>
                                  </td>
                                  <td>
                                    <b>
                                      {
                                        data?.inputState
                                          .approvedTotalClaimAmount
                                      }
                                    </b>
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </>
                        </>
                      </table>
                    ))
                  ) : (
                    "------No Data Found------"
                  )}
                </div>
              </div>
            </div>
            {/* PENDING CLAIM ADMIN TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
                onClick={exportExcelClaim}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
              <button
                type="button"
                className="btn btn-approve-claim"
                onClick={(e) => approveClaimAmount(e)}
              >
                <i className="fa fa-check" /> &nbsp; Approve
              </button>
              <span className="user-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </span>
            </div>
            {/* PAGE CONTENT END*/}

            {/* MAIN BODY END */}
            {claimAttachmentmodel ? (
              <ViewClaimAttachmentmodel
                claimAttachmentmodel={claimAttachmentmodel}
                handleCloseviewattachment={handleCloseviewattachment}
                attachmentUrl={attachmentUrl}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {visitDetailsModel ? (
        <MeetingDetailsModel
          visitDetailsModel={visitDetailsModel}
          UserId={UserId}
          ViewDate={ViewDate}
          closeVisitModel={closeVisitModel}
        />
      ) : (
        ""
      )}

      {meeterReadingModel ? (
        <MeeterReadingModel
          meeterReadingModel={meeterReadingModel}
          UserId={UserId}
          ViewDate={ViewDate}
          closeMeetrReadModel={closeMeetrReadModel}
        />
      ) : (
        ""
      )}
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default PendingClaimAdmin;
