import React from 'react';
import ReactApexChart from 'react-apexcharts';

function DrillChartMeeting(props) {
  const chartData = props.series;
  const chartOptions = {
    chart: {
      type: 'donut',
      width: "100%",
    },
    series: chartData,
    labels: ['Direct Visit', 'Sheduled Visit', 'My Visit'],
    legend: {
      show: false,
    },
    colors: ['#04A96D', '#74d9b4', '#aec3bb'],
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            name: {
              fontSize: '14px',
            },
            value: {
              fontSize: '16px',
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
  };

  return (
    <div>
      <ReactApexChart options={chartOptions} series={chartData} type="donut" />
    </div>
  );
};

export default DrillChartMeeting
