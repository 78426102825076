import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonTableData(props) {
  return (
    <>
      <tr>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        {props.tdata != 7 ? (
          <td>
            <Skeleton />
          </td>
        ) : (
          ""
        )}
      </tr>
    </>
  );
}

export default SkeletonTableData;
