import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";

// import { travelizeAuth } from "../HeaderAuthentication";

function EditBaseFareModel(props) {
  const [MOTName, setMOTName] = useState("");
  const [baseFare, setBaseFare] = useState("");
  const [baseFareUnit, setBaseFareUnit] = useState("");
  const [unitVal, setUnitVal] = useState("");
  const [motTypeVal, setMotTypeVal] = useState("");
  const [MOTType, setMOTType] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  useEffect(() => {
    fetchEditableData();
  }, [props.EditmotID]);

  const fetchEditableData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Mot/Get/${props.EditmotID}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        setMOTName(res?.result?.motName);
        setBaseFare(res?.result?.baseFare);
        setBaseFareUnit(res?.result?.motUnit);
        setMOTType(res?.result?.motType);
        setEnableStatus(res?.result?.status);
      });
  };

  useEffect(() => {
    if (baseFareUnit !== null && baseFareUnit !== "") {
      optionsUnitList.map((unitlist) => {
        if (baseFareUnit == unitlist.value) {
          setUnitVal(unitlist);
        }
      });
    }
    if (MOTType !== null && MOTType !== "") {
      optionsMOTTypes.map((motTypelist) => {
        if (MOTType == motTypelist.value) {
          setMotTypeVal(motTypelist);
        }
      });
    }
  }, [baseFareUnit, MOTType, optionsUnitList, optionsMOTTypes]);

  const editBaseFare = () => {
    if (baseFareUnit && baseFare && MOTName && MOTType) {
      const editBaseFareDetails = {
        motID: props.EditmotID,
        motName: MOTName,
        baseFare: baseFare,
        motUnit: baseFareUnit,
        motType: MOTType,
        status: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(editBaseFareDetails),
      };
      fetch(`${baseURL}/api/Mot/UpdateMot`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setShowAlert(true);
            setAlertMessage({
              alertType: "success",
              message: res.message,
            });
            setTimeout(() => {
              props.handleClose();
              props.getBaseFarebyMOT();
            }, 2000);
          } else {
            setShowAlert(true);
            setAlertMessage({
              alertType: "fails",
              message: res.errorMessage,
            });
          }
        });
    } else {
      setShowAlert(true);
      setAlertMessage({
        alertType: "fails",
        message: "Please enter all fields",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.editbasefaremodel}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">EDIT BASEFARE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form onSubmit={editBaseFare}>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  placeholder="MOT Name"
                  value={MOTName}
                  onChange={(e) => setMOTName(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <input
                  type="number"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  placeholder="BaseFare"
                  value={baseFare}
                  onChange={(e) => setBaseFare(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  defaultValue={optionsMOTTypes[null]}
                  options={optionsMOTTypes}
                  placeholder="MOT Type"
                  value={motTypeVal}
                  onInputChange={optionsMOTTypes.label}
                  onChange={(data) => setMOTType(data.value)}
                  isSearchable={true}
                />
              </div>

              <div className="mb-2">
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  defaultValue={optionsUnitList[null]}
                  options={optionsUnitList}
                  placeholder="Unit"
                  value={unitVal}
                  onInputChange={optionsUnitList.label}
                  onChange={(data) => setBaseFareUnit(data.value)}
                  isSearchable={true}
                />
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="basefare-save-button">
            <button
              onClick={editBaseFare}
              type="submit"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="products-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default EditBaseFareModel;
