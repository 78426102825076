import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/MeetingPurpose.scss";
import { Form } from "react-bootstrap";
import AddPurosemodel from "./AddPurosemodel";
import EditPurposeModel from "./EditPurposeModel";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import {
  optionsMeetStatusList,
  optionsPageSizeList,
} from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";

function MeetingPurpose() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editpurposemodel, setEditpurposemodel] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [meetPurposeId, setMeetPurposeId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(2);
  const [pageSize, setPageSize] = useState(100);
  const [purposeId, setPurposeId] = useState("");
  const [meetPurpose, setMeetPurpose] = useState([]);
  const [meetPurposeDropdown, setMeetPurposeDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
    setEditpurposemodel(false);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    fetchMeetingPurposeData();
  }, [meetPurposeId, activeStatus, pageSize]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/MeetingPurpose/BindPurposeDropdown?status=${1}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("MEET-PURPOSE-DROPDOWN::", res);
          setMeetPurposeDropdown(
            res.result.map((data) => {
              return { value: data.purposeId, label: data.purpose };
            })
          );
        } else {
          setMeetPurposeDropdown([]);
        }
      });
  };

  //  MEETING PURPOSE TABLE START
  const fetchMeetingPurposeData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/MeetingPurpose/Get?PageSize=${pageSize}&CurrentPage=${1}&PurposeId=${meetPurposeId}&Status=${activeStatus}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("MEET-PURPOSE-RES::", res);
        if (res.success) {
          setMeetPurpose(res.result);
          setIsLoading(false);
        } else {
          setMeetPurpose("");
          setIsLoading(false);
        }
      });
  };
  //  MEETING PURPOSE TABLE END

  const clearSerachBySelect = () => {
    setMeetPurposeId(0);
    setActiveStatus(0);
  };

  const exportExcelMeetingPurpose = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingPurposeExcelExport?PurposeId=${meetPurposeId}&Status=${activeStatus}&PageSize=${pageSize}&CurrentPage=1`,
      "MeetingPurpose.xlsx"
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="meetingpurpose-table">
                  <div className="seleting-menus">
                    <div className="page-title">MEETING PURPOSES</div>
                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={meetPurposeDropdown}
                        placeholder="Select Purpose"
                        value={
                          meetPurposeId === 0 ? "" : meetPurposeDropdown.value
                        }
                        onInputChange={meetPurposeDropdown.label}
                        onChange={(data) => setMeetPurposeId(data.value)}
                        isSearchable={true}
                      />

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsMeetStatusList}
                        placeholder="Select Status"
                        value={
                          activeStatus === 0 ? "" : optionsMeetStatusList.value
                        }
                        onInputChange={optionsMeetStatusList.label}
                        onChange={(data) => setActiveStatus(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                        // isClearable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-add-purpose"
                        onClick={() => setShow(!show)}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-plus-square" /> &nbsp;
                          <h6>Add New</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive meetingpurpose-scroll">
                    <table className="table meeting-purpose-table">
                      <thead className="thead-light meeting-purpose-thead">
                        <tr>
                          <th>PURPOSE</th>
                          <th> ADDED ON</th>
                          <th>ADDED BY</th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                          </>
                        ) : meetPurpose !== "" ? (
                          meetPurpose.map((meet) => (
                            <tr className="meeting-purpose-row-data">
                              <td>{meet.purpose}</td>
                              <td>{meet.addedOn}</td>
                              <td>{meet.addedBy}</td>
                              <td>
                                {meet.status == 1 ? "Active" : "InActive"}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setEditpurposemodel(!editpurposemodel);
                                    setPurposeId(meet.purposeId);
                                  }}
                                  className="btn meeting-purpose-edit-link"
                                >
                                  <i className="bi bi-pencil-fill"></i> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          "----- NO DATA FOUND -----"
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <button
                    type="button"
                    className="btn btn-download-excel"
                    onClick={exportExcelMeetingPurpose}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                </div>

                {/* ADD PURPOSE MODEL START */}
                <AddPurosemodel
                  show={show}
                  handleClose={handleClose}
                  fetchMeetingPurposeData={fetchMeetingPurposeData}
                />
                {/* ADD PURPOSE MODEL END */}

                {/* EDIT PURPOSE MODEL START */}
                <EditPurposeModel
                  editpurposemodel={editpurposemodel}
                  purposeId={purposeId}
                  handleClose={handleClose}
                  fetchMeetingPurposeData={fetchMeetingPurposeData}
                />
                {/* EDIT PURPOSE MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default MeetingPurpose;
