import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/MeetingPurpose.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";

function AddPurosemodel(props) {
  const [meetPurpose, setMeetPurpose] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  //  ADDING MEETING PURPOSE START
  const addMeetingPurpose = () => {
    const purposeData = {
      purpose: meetPurpose,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(purposeData),
    };
    fetch(`${baseURL}/api/MeetingPurpose/AddPurpose`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        console.log("ADD-MEET-PURPOSE::", res);
        if (res.success) {
          setShowAlert(true);
          setAlertMessage({
            alertType: "success",
            message: res.message,
          });
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: res.errorMessage,
          });
        }
        props.fetchMeetingPurposeData();
      });
    setTimeout(() => {
      props.handleClose();
    }, 3000);
  };
  //  ADDING MEETING PURPOSE START END

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-meet-purpose-model"
      >
        <div className="add-meet-purpose-heading">
          <h6 className="add-edit-meetpurpose-head">ADD PURPOSE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-meetpurpose">
            <div className="company-name">
              <div className="row mb-2">
                <input
                  type="text"
                  className="form-control form add-meetpurpose-input"
                  id="exampleFormControlInput1"
                  placeholder="Meeting Purpose"
                  // value={meetPurpose}
                  onChange={(e) => setMeetPurpose(e.target.value)}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="meetpurpose-save-button">
            <button
              onClick={addMeetingPurpose}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="meetpurpose-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default AddPurosemodel;
