import React, { useState } from "react";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

let timeout = 1000 * 60 * 10;

function SessionTimeout() {
  let navigate = useNavigate();

  const [remaining, setRemaining] = useState(0);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const onIdle = () => {
    handleLogout();
  };

  const { idleTimeOut } = useIdleTimer({
    onIdle,
    // onActive,
    // onAction,
    timeout: timeout,
    // throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(idleTimeOut / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return <div></div>;
}

export default SessionTimeout;
