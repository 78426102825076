import React from 'react'

function SbDropDownBtn(props) {
  return (
    <div className="dropdown add-single-bulk-user">
      <button
        className="btn btn-add-user"
        id="adduser-single-blulk"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="btn-inline-item d-flex">
          <i className="fa fa-user-plus"> </i>
          {props.btnName}
        </span>
      </button>
      <ul
        className="dropdown-menu single-bulk-upload"
        aria-labelledby="adduser-single-blulk"
      >
        <li>
          <button
            className="dropdown-item single-user"
            onClick={() =>
              props.setAddusermodelshow(!props.addusermodelshow)
            }
          >
            <i className="fa fa-user-plus" /> &nbsp;
            Single {props.pageName}
          </button>
        </li>
        <li>
          <button
            className="dropdown-item bulk-user"
            onClick={() =>
              props.setAddBulkUserModel(!props.addBulkUserModel)
            }
          >
            <i className="fa fa-users" /> &nbsp; Bulk
            Upload
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SbDropDownBtn
