import React, { useState } from "react";
import "../styles/Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import travelizeSalesBeeLogo from "../images/NowTravelize-is-SalesBee.svg";
import AlertModel from "./AlertModel";
import { baseURL } from "./BaseUrl";

function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  // const baseURL = "https://api.travelize.in";

  var resPassData = JSON.parse(sessionStorage.getItem("forgotPassData"));

  let navigate = useNavigate();

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const sessionClear = () => {
    sessionStorage.clear("forgotPassData");
  };

  const resetPassword = (e) => {
    if (password === confirmPassword) {
      const resetPassDta = {
        UserName: resPassData.email,
        password: password,
        resetPswdFlag: resPassData.id,
      };

      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
        },
        body: JSON.stringify(resetPassDta),
      };
      fetch(`${baseURL}/api/Authentication/ResetPassword`, requestoption)
        .then((res) => res.json())
        .then((res) => {

          if (res.success) {
            setShowAlert(true);
            setAlertMessage({
              alertType: "success",
              message: `${res.message}`,
            });
            setTimeout(() => navigate("/"), sessionClear(), 1500);
          } else {
            setShowAlert(true);
            setAlertMessage({
              alertType: "fail",
              message: `${res.errorMessage}`,
            });
          }
        });
    } else {
      alert("Password Must be same");
    }
    e.preventDefault();
  };

  return (
    <div>
      <div>
        <div className="main-body">
          <div className="login-page-body">
            <Image
              src={travelizeSalesBeeLogo}
              alt="Now Travelize is SalesBee"
              className="travelize-salesbee-logo"
            />
            <div className="login-page">
              <div className="login-box">
                <h3 className="login-head-text">Reset Password</h3>
                <form onSubmit={resetPassword}>
                  <div className="userName-input">
                    <input
                      type="email"
                      className="form-control"
                      id="login-email-id"
                      placeholder="Email Id"
                      value={resPassData.email}
                      required
                    />
                  </div>
                  <div className="password-input">
                    <input
                      type="password"
                      className="form-control"
                      id="login-password"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      className="form-control"
                      id="login-password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="forget-pass-login-button">
                    <Link to="/" className="forgot-pass-text">
                      Remembered? Login again
                    </Link>
                    <Button
                      type="submit"
                      className="btn btn-login-button"
                    // onClick={(e) => resetPassword(e)}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default ResetPasswordPage;
