import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddmeetingModel.scss";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import viewattachment from "../../images/No-Image.jpg";

function Viewmeetgattachmodel(props) {
  const [profileImageUrl, setProfileImageUrl] = useState([]);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log("MEETING-ID:", props.meetingId);

  useEffect(() => {
    fetchMeetingAttachment();
  }, [props.meetingId]);

  const fetchMeetingAttachment = () => {
    let requestOptions = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetAttachment/${props.meetingId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("Meeting-Attachments::", res.result);
        if (res.success) {
          setProfileImageUrl(res?.result?.listAttachments);
        } else {
          setProfileImageUrl("");
        }
      });
  };

  return (
    <div>
      <div className="model-add-client-body">
        <Modal
          show={props.meetingattachmentmodel}
          onHide={props.handleCloseviewattachment}
          className="view-attachment-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">VIEW ATTACHMENT</h6>
            <i
              className="fa fa-times"
              onClick={props.handleCloseviewattachment}
            ></i>
          </div>
          {profileImageUrl !== "" && profileImageUrl !== null ? (
            profileImageUrl.map((data, i) => (
              <div
                className="meeting-attachment-image"
                ref={componentRef}
                key={i}
              >
                <img
                  src={`${baseURL}/${data}`}
                  // src={viewattachment}
                  alt="Attachment-Image"
                  className="img-thumbnail"
                />
              </div>
            ))
          ) : (
            <div className="meeting-attachment-image">
              <img
                src={viewattachment}
                // src={viewattachment}
                alt="Attachment-Image"
                className="img-thumbnail"
              />
            </div>
          )}

          <div className="attach-print-button">
            <button
              onClick={handlePrint}
              type="button"
              className="btn btn-print"
              data-bs-dismiss="modal"
            >
              <p>Print</p>
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Viewmeetgattachmodel;
