import React, { useState, useEffect } from "react";
import { Modal, Image } from "react-bootstrap";
import "../../styles/Spares.scss";
import viewattachment from "../../images/No-Image.jpg";
import { baseURL } from "../BaseUrl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import productimg from "../../images/products/SamsungBattries.png";
import productimg1 from "../../images/products/samsungbattery1.png";
import productimg2 from "../../images/products/battery1.png";
// import productimg3 from "../../images/products/headset11.png";
import productimg3 from "../../images/products/SamsungBattery.png";

export default function ViewSpareDetails(props) {
  return (
    <div>
      <div className="model-view-spare-details">
        <Modal
          show={props.viewProductDetail}
          onHide={props.handleClose}
          className="view-spares-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">SPARE DETAILS</h6>
            <i className="fa fa-times" onClick={props.handleClose}></i>
          </div>
          <div className="spare-details-model">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <div className="spare-detail-left-part">
                  <div className="spare-big-img">
                    <Image
                      src={productimg}
                      alt="attachment-photo"
                      className="spare-img"
                    />
                  </div>
                  <div className="spare-small-images-row">
                    <div className="spare-small-image">
                      <Image
                        src={productimg1}
                        alt="attachment-photo"
                        className="spare-img"
                      />
                    </div>
                    <div className="spare-small-image">
                      <Image
                        src={productimg2}
                        alt="attachment-photo"
                        className="spare-img"
                      />
                    </div>
                    <div className="spare-small-image">
                      <Image
                        src={productimg3}
                        alt="attachment-photo"
                        className="spare-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="spare-detail-right-part">
                  <div className="spare-name">
                    <h5>Samsung Battery A-5 4000mAh</h5>
                  </div>
                  <div className="spare-details-by-row">
                    <div className="spare-row">
                      <div className="pdh">Spare Name</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">
                        <b>Samsung Battery</b>
                      </div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">Spare ID</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">H-1234</div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">Product Name</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">Mobile</div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">Warranty</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">1 Year</div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">Dimension (H * W * L)</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">1800mm x 1236mm x 1356mm</div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">Stock</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">10 </div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">Weight</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">500gm</div>
                    </div>

                    <div className="spare-row">
                      <div className="pdh">MRP</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">
                        {" "}
                        <i className="fa fa-inr" />
                        &nbsp;
                        <b>1,100</b>
                      </div>
                    </div>
                  </div>
                  <div className="spare-description">
                    <div className="description-instruction">
                      <Tabs
                        defaultActiveKey="description"
                        id="uncontrolled-tab-example"
                        className="description-instruction-nav-tab"
                      >
                        <Tab eventKey="description" title="Description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="attach-print-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
