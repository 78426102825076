import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
// import { travelizeAuth } from "./HeaderAuthentication";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AccessDenied from "../../AccessDenied";
import ClaimManagerOutStation from "./ClaimManagerOutStation";
import ClaimAdminOutStation from "./ClaimAdminOutStation";

function OutStationClaim() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
  const loggedInRole = loginDetails?.roleId;

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="claims-table">
                  <Tabs
                    defaultActiveKey="manager"
                    id="uncontrolled-tab-example"
                    className="local-claim-nav-tab"
                  >
                    <Tab title="OUTSTATION CLAIMS" className="local-claim-head">
                      OUTSTATION CLAIMS
                    </Tab>
                    <Tab eventKey="manager" title="Manager">
                      <ClaimManagerOutStation />
                    </Tab>
                    {twoLevelClaim === "Yes" && loggedInRole !== 2 ? (
                      <Tab eventKey="admin" title="Admin">
                        <ClaimAdminOutStation />
                      </Tab>
                    ) : (
                      ""
                    )}
                  </Tabs>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default OutStationClaim;
