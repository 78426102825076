import { baseURL } from '../Components/BaseUrl';
import * as types from './actionType';
import * as HeaderAuthentication from '../Components/HeaderAuthentication';

const getSubscription = (subcriptionList) => ({
    type: types.Get_Subscription,
    payload: subcriptionList,
})
export const getSuccessMessage = (successMsg) => ({
    type: types.Get_Successs_Msg,
    payload: successMsg,
})

const getAllSubscriptions = (allSubcriptions) => ({
    type: types.Get_All_Subscription,
    payload: allSubcriptions,
})
const getMainModules = (mainModules) => ({
    type: types.Get_Main_Modules,
    payload: mainModules,
})
const getSubscriptionBySubscriber = (sbcrBySubscriber) => ({
    type: types.Get_Subscription_Module,
    payload: sbcrBySubscriber,
})
const getAllMenus = (allMenus) => ({
    type: types.Get_All_Menus,
    payload: allMenus,
})

const postMenuItems = () => ({
    type: types.Post_Menus_Subscription
})
const getTrackData = (trackData) => ({
    type: types.Get_Track_User,
    payload: trackData,
})
const getDistanceMatrix = (distanceMatrix) => ({
    type: types.Get_Distance_Matrix,
    payload: distanceMatrix,
})


export const loadSubscription = () => {
    return function (dispatch) {
        fetch(`${baseURL}/api/Subscription/GetAllSubscription`, HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                // console.log(resp.result, "resp")
                dispatch(getSubscription(resp.result))
            })
            .catch((err) => {

            })
    }
}

export const loadAllSubscriptions = () => {
    return function (dispatch) {
        fetch(`${baseURL}/api/SubscriptionModule/GetMainModule`, HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                // console.log(resp, "resp")
                dispatch(getAllSubscriptions(resp.result))
            })
            .catch((err) => {

            })
    }
}
export const loadMainModules = (SubscriptionID) => {
    return function (dispatch) {
        fetch(`${baseURL}/api/SubscriptionModule/GetSubscriptionMainModule?SubscriptionID=${SubscriptionID}`, HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                // console.log(resp, "resp")
                dispatch(getMainModules(resp.result))
            })
            .catch((err) => {

            })
    }
}
export const loadSubscriptionBySubscriber = (sbctptnId, moduleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/api/SubscriptionModule/GetSubscriptionModule?SubscriptionID=${sbctptnId}&MainModuleID=${moduleId}`,
            HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                // console.log(resp, "resp")
                dispatch(getSubscriptionBySubscriber(resp.result))
            })
            .catch((err) => {

            })
    }
}
export const loadAllMenus = (moduleId) => {
    return function (dispatch) {
        // fetch(`${baseURL}/api/SubscriptionModule/Get`,
        //  HeaderAuthentication.getRequestOptions)
        fetch(`${baseURL}/api/SubscriptionModule/GetSubscriptionModule?MainModuleID=${moduleId}`,
            HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                // console.log(resp, "resp")
                dispatch(getAllMenus(resp.result))
            })
            .catch((err) => {

            })
    }
}
export const loadPostMenuItems = (postData, setSuccessStatus) => {
    return function (dispatch) {
        // fetch(`${baseURL}/api/SubscriptionModule/Get`,
        //  HeaderAuthentication.getRequestOptions)
        fetch(`${baseURL}/api/SubscriptionModule/BulkAddSubscriptionModule`,
            postData)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === false) {
                    alert("Something went wrong...!")
                } else {

                    setSuccessStatus(true)
                    // dispatch(postMenuItems(resp.result))
                }

            })
            .catch((err) => {

            })
    }
}
export const loadTrackData = (tokenData) => {
    return function (dispatch) {
        // fetch(`${baseURL}/api/SubscriptionModule/Get`,
        //  HeaderAuthentication.getRequestOptions)
        fetch(`${baseURL}/api/Annonymus/GetCordsUserID?Token=${tokenData}`,
            HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getTrackData(resp.result))

            })
            .catch((err) => {

            })
    }
}
export const loadGetDistance = (subscriptionId) => {
    return function (dispatch) {
        fetch(`${baseURL}/api/Subscription/GetDistanceMatrix/${subscriptionId}`,
            HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                // console.log(resp, "distance")
                dispatch(getDistanceMatrix(resp))

            })
            .catch((err) => {

            })
    }
}
export const loadPostDistance = (postRequest) => {
    return function (dispatch) {
        fetch(`${baseURL}/api/Subscription/UpdateDistanceMatrix`,
            postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSuccessMessage(resp))
                setTimeout(() => {
                    dispatch(getSuccessMessage({}));
                }, 5000);

            })
            .catch((err) => {

            })
    }
}
export const loadNotification = (postRequest) => {
    return function (dispatch) {
        fetch(`${baseURL}/api/Subscription/UpdatePermissionStatus`,
            postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSuccessMessage(resp))
                setTimeout(() => {
                    dispatch(getSuccessMessage({}));
                }, 5000);

            })
            .catch((err) => {

            })
    }
}