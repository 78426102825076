import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
import "../../styles/Clients.scss";
import { Form } from "react-bootstrap";
import Select from "react-select";
// import { travelizeAuth } from "../HeaderAuthentication";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
import Geocode from "react-geocode";
import { React_Google_API_Key } from "../../GoogleApiKey";
import { LoadScript } from "@react-google-maps/api";
import AlertModel from "../AlertModel";
Geocode.setApiKey(React_Google_API_Key);

function EditclientsModel(props) {
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [clientName, setClientName] = useState("");
  const [contPersonName, setContPersonName] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [email, setEmail] = useState("");
  const [landLine, setLandLine] = useState("");
  const [country, setCountry] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [pinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");
  const [enableStatus, setEnableStatus] = useState();
  const [location, setLocation] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [countryVal, setCountyVal] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [cityValue, setCityValue] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });

  const [fieldReq, setFieldReq] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  let cntyList = [{ value: 1, label: "India" }];

  useEffect(() => {
    if (country !== null && country !== 0) {
      countryList.map((cnty) => {
        if (country == cnty.value) {
          setCountyVal(cnty);
        }
      });
    }
    if (zoneId !== null && zoneId !== 0) {
      zoneList.map((zone) => {
        if (zoneId == zone.value) {
          setZoneValue(zone);
        }
      });
    }
    if (stateId !== null && stateId !== 0) {
      stateList.map((state) => {
        if (stateId == state.value) {
          setStateValue(state);
        }
      });
    }
    if (cityId !== null && cityId !== 0) {
      cityList.map((city) => {
        if (cityId == city.value) {
          setCityValue(city);
        }
      });
    }
  }, [
    zoneId,
    stateId,
    cityId,
    country,
    zoneList,
    stateList,
    cityList,
    countryList,
  ]);

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const clientID = props.clientId;

  useEffect(() => {
    fetchDropdownData();
    setCountryList(cntyList);
  }, [clientID]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Client/Get/${clientID}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setClientName(res.result.clientName);
          setContPersonName(res.result.contactPersonName);
          setMobileNum(res.result.mobile);
          setEmail(res.result.email);
          setLandLine(res.result.landline);
          setLocation(res.result.clientLoc?.location);
          setEnableStatus(res.result.enabledStatus);
          setCountry(res.result.countryID);
          setStateId(res.result.stateID);
          setZoneId(res.result.zoneID);
          setCityId(res.result.cityID);
          setPinCode(res.result.pincode);
          setLatLan({
            lat: JSON.parse(res.result.clientLoc?.latitude),
            lng: JSON.parse(res.result.clientLoc?.longitude),
          });
        }
      });
    fetch(`${baseURL}/api/Zone/GetZones`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setZoneList(
            res.result.map((data) => {
              return { value: data.zoneId, label: data.zoneName };
            })
          );
        } else {
          setZoneList([]);
        }
      });
  };

  useEffect(() => {
    fetchDropdownByZone();
  }, [zoneId, stateId]);

  const fetchDropdownByZone = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Zone/GetStates?ZoneId=${zoneId}`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      });
    fetch(`${baseURL}/api/Zone/GetCities?StateId=${stateId}`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCityList(
            res.result.map((data) => {
              return { value: data.cityId, label: data.cityName };
            })
          );
        } else {
          setCityList([]);
        }
      });
  };

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
    setAddress("");
  };

  // VALIDATIONS START
  const checkValidation = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNum(e.target.value);
    } else {
      setShowAlert(true);
      setAlertMessage({ alertType: "fails", message: "Accept only numbers" });
    }
  };
  // VALIDATIONS END

  //  ADDING CLIENT START
  const editClientDetails = (e) => {
    e.preventDefault();
    if (
      clientName !== "" &&
      contPersonName !== "" &&
      mobileNum.toString().length == 10 &&
      location !== "" &&
      country !== 0 &&
      zoneId !== 0 &&
      stateId !== 0
    ) {
      const clientDetails = {
        client: {
          clientID: clientID,
          clientName: clientName,
          mobile: JSON.parse(mobileNum),
          enabledStatus: enableStatus,
          clientLoc: {
            latitude: JSON.stringify(latLan.lat),
            longitude: JSON.stringify(latLan.lng),
            location: location,
          },
          email: email,
          contactPersonName: contPersonName,
          landline: landLine,
          countryID: JSON.parse(country),
          zoneID: JSON.parse(zoneId),
          stateID: JSON.parse(stateId),
          cityID: JSON.parse(cityId),
          pincode: pinCode,
        },
        // clientMapping: userMapList,     /* Mapping will be done base on Zone */
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(clientDetails),
      };
      fetch(`${baseURL}/api/Client/EditClient`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setShowAlert(true);
            setAlertMessage({
              alertType: "success",
              message: res.message,
            });
            setTimeout(() => {
              props.handleClose();
              setLocation("");
              setZoneId(0);
              setStateId(0);
              setCityId(0);
            }, 2000);
            props.fetchClientDetails();
          } else {
            setShowAlert(true);
            setAlertMessage({
              alertType: "fails",
              message: res.errorMessage,
            });
            setLocation("");
            setZoneId(0);
            setStateId(0);
            setCityId(0);
          }
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };
  //  ADDINGCLIENT END

  // GOOGLE MAPS LOCATION START

  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLatLan({
            lat: lat,
            lng: lng,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan) {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  const addressFromGoogle = () => {
    openGoogleMaps();
  };

  // GOOGLE MAPS LOCATION END

  return (
    <div>
      <Modal
        show={props.editclientmodel}
        onHide={props.handleClose}
        className="main_model add-edit-clients-model"
      >
        <div className="add-edit-client-heading">
          <h6 className="add-edit-client-head">EDIT COMPANY</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form onSubmit={editClientDetails}>
          <div className="container add-edit-client edit-client">
            <div className="company-name">
              <div className="row">
                <div className="col-md-6 mb-2">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control form add-client-input"
                    id="exampleFormControlInput1"
                    placeholder="Company Name"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    required
                  />
                  {fieldReq && clientName === "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {alertMessage.message}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 mb-2">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control form add-client-input"
                    id="exampleFormControlInput1"
                    placeholder="Contact Person Name"
                    value={contPersonName}
                    onChange={(e) => setContPersonName(e.target.value)}
                  />
                  {fieldReq && contPersonName === "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {alertMessage.message}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <input
                    type="tel"
                    autoComplete="off"
                    className="form-control form add-client-input"
                    id="exampleFormControlInput1"
                    placeholder="Mobile Number"
                    maxLength={10}
                    value={mobileNum}
                    onChange={(e) => checkValidation(e)}
                  />
                  {fieldReq && mobileNum.length != 10 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message} - 10 Numbers`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 mb-2">
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control form add-client-input"
                    id="exampleFormControlInput1"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control form add-client-input"
                    id="exampleFormControlInput1"
                    placeholder="Landline"
                    value={landLine}
                    onChange={(e) => setLandLine(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={countryList[null]}
                    options={countryList}
                    placeholder="Select Country"
                    value={countryVal}
                    onInputChange={countryList.label}
                    onChange={(data) => setCountry(data.value)}
                    isSearchable={true}
                  />
                  {fieldReq && country === 0 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {alertMessage.message}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Select
                    className="select-add-user-model"
                    options={zoneList}
                    defaultValue={zoneList[null]}
                    placeholder="Select Zone"
                    value={zoneValue}
                    onInputChange={zoneList.label}
                    onChange={(data) => setZoneId(data.value)}
                    isSearchable={true}
                  />
                  {fieldReq && zoneId === 0 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {alertMessage.message}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 mb-2">
                  <Select
                    className="select-add-user-model"
                    options={stateList}
                    defaultValue={stateList[null]}
                    placeholder="Select State"
                    value={stateValue}
                    onInputChange={stateList.label}
                    onChange={(data) => setStateId(data.value)}
                    isSearchable={true}
                  />
                  {fieldReq && stateId === 0 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {alertMessage.message}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Select
                    className="select-add-user-model"
                    options={cityList}
                    defaultValue={cityList[null]}
                    placeholder="Select City"
                    value={cityValue}
                    onInputChange={cityList.label}
                    onChange={(data) => setCityId(data.value)}
                    isSearchable={true}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <input
                    type="number"
                    className="form-control form add-client-input"
                    id="exampleFormControlInput1"
                    placeholder="PinCode"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2 client-location-by-map">
                  <textarea
                    rows="3"
                    cols="20"
                    name="usrtxt"
                    wrap="hard"
                    className="form-control form add-client-input location"
                    id="exampleFormControlInput1"
                    value={location}
                    readOnly={true}
                    required
                  // onChange={(e) => setLocation(e)}
                  />
                  <i
                    className="bi bi-geo-alt"
                    title="Google Location"
                    onClick={openGoogleMaps}
                  />
                </div>
                {fieldReq && location === "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-2">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1}
                        onChange={() => {
                          setEnableStatus(1);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0}
                        onChange={() => {
                          setEnableStatus(0);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <div className="user-save-button">
              <button
                // onClick={editClientDetails}
                type="submit"
                className="btn btn-save-up"
              >
                Save
              </button>
            </div>
            <div className="user-cancel-button">
              <button
                onClick={props.handleClose}
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
      {googleMapsModel ? (
        <LoadScript googleMapsApiKey={React_Google_API_Key}>
          <GoogleMapAddressModel
            googleMapsModel={googleMapsModel}
            addressFromGoogle={addressFromGoogle}
            openGoogleMaps={openGoogleMaps}
            latLan={latLan}
            searchLocation={searchLocation}
            searchVal={searchVal}
            onMarkerDragEnd={onMarkerDragEnd}
            address={address}
          />
        </LoadScript>
      ) : (
        ""
      )}
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default EditclientsModel;
