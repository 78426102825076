export const optionsRoleList = [
  { value: "", label: "Select Role" },
  { value: "Manager", label: "Manager" },
  { value: "User", label: "User" },
];

export const optionsRoleListModel = [
  { value: "", label: "Select Role" },
  { value: 2, label: "Manager" },
  { value: 3, label: "User" },
];

export const optionsLeaveStatusUpdate = [
  { value: "", label: "Select Status" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
];

export const optionsMapByListModel = [
  { value: 1, label: "Map By Country" },
  { value: 2, label: "Map By Zone" },
  { value: 3, label: "Map By State" },
  // { value: 4, label: "Map By City" },
];

export const optionsStatusList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const optionsMeetStatusList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Active" },
  { value: 0, label: "InActive" },
];

export const optionsLeaveStatusList = [
  { value: "Requested", label: "Requested" },
  { value: "Approved", label: "Approved" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Rejected", label: "Rejected" },
];

export const optionsMOTTypes = [
  { value: "Local", label: "Local MOT" },
  { value: "OS", label: "Outstation MOT" },
];

export const optionsMeetingStatusList = [
  { value: "", label: "Select Type" },
  { value: "Completed", label: "Completed" },
  { value: "Follow up", label: "Follow-Up" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "PCancelled", label: "Pre-Cancelled" },
  { value: "ReSchedule", label: "Re-Scheduled" },
  { value: "Pending", label: "Pending" },
];

export const optionsMeetingTypeList = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Direct Visit", label: "Direct Visit" },
];

export const optionsUnitList = [
  { value: "Per KM", label: "Kilometer" },
  { value: "1", label: "Ticket" },
];

export const optionsPageSizeList = [
  { value: 20, label: "Show Per Page" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

export const optionsDistanceList = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "300" },
  { value: 400, label: "400" },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
];
