import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccessDenied from "../../AccessDenied";
import { exportExcel } from "../DownloadFile";

function GoogleAPIHitCount() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [allGoogleApiKeys, setAllGoogleApiKeys] = useState([]);
  const [searchApiKey, setSearchApiKey] = useState("");

  let userLoginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // GET GOOGLEAPI KEYS PAGE START
  useEffect(() => {
    fetchGoogleApisList();
  }, [searchApiKey, toDate, fromDate, PageSize, currentPage]);

  const fetchGoogleApisList = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/SupportTeam/GetGoogleApiKeyHitCount?Key=${searchApiKey}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=50&CurrentPage=1`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAllGoogleApiKeys(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
        } else {
          setAllGoogleApiKeys("");
        }
      });
  };

  // GET GOOGLEAPI KEYS PAGE END

  const clearSerachBySelect = () => {
    // setSearchApiKey("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelGoogleAPIHitCount = () => {
    exportExcel(
      `${baseURL}/api/Export/GoogleApiKeyHitCount?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&Key=${searchApiKey}`,
      "GoogleAPI-HitCounts.xlsx"
    );
  };

  return (
    <div id="main-page">
      {userLoginDetails?.subscriptionId == "SUB0000000001" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="googleapi-table">
                  <div className="seleting-menus">
                    <div className="page-title"> GOOGLE API KEYS </div>
                    <div className="page-top-bar-dropdowns">
                      <div className="apikey-search-input">
                        <input
                          type="search"
                          placeholder="Search API Key"
                          className="form-control input-search-api-key"
                          onChange={(e) => setSearchApiKey(e.target.value)}
                        />
                        <i className="fa fa-search" />
                      </div>

                      <div className="input-group from-to-date-select">
                        <span className="input-group-text" id="from-label">
                          From
                        </span>
                        <span className="date-picker-with-icon">
                          <DatePicker
                            selected={fromDate}
                            className="select from-date"
                            id="from-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            // startDate={fromDate}
                            endDate={toDate}
                            onChange={(date) => setFromDate(date)}
                            maxDate={new Date()}
                          />
                          <i className="fa fa-calendar-o calander-icon" />
                        </span>

                        <span className="input-group-text" id="to-label">
                          To
                        </span>
                        <span className="date-picker-with-icon">
                          <DatePicker
                            selected={toDate}
                            className="select to-date"
                            id="from-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            // startDate={fromDate}
                            endDate={toDate}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                            maxDate={new Date()}
                          />
                          <i className="fa fa-calendar-o calander-icon" />
                        </span>
                      </div>
                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="table-responsive google-apihitcount-scrollbar">
                    <table className="table apihitcount-list-table">
                      <thead className="thead-light meetings-list-thead">
                        <tr>
                          <th>API KEY</th>
                          <th>HIT COUNT</th>
                          <th>HIT DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allGoogleApiKeys !== "" ? (
                          allGoogleApiKeys.map((data, i) => (
                            <tr className="meeting-list-row-data" key={i}>
                              <td>{data?.googleApiKey}</td>
                              <td>{data?.hitCount}</td>
                              <td>{data?.hitDate}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="no-data-found">
                            ------ NO DATA FOUND ------
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      onClick={exportExcelGoogleAPIHitCount}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default GoogleAPIHitCount;
