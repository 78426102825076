import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Form } from "react-bootstrap";
import AddBaseFareModel from "./AddBaseFareModel";
import EditBaseFareModel from "./EditBaseFareModel";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import {
  optionsMeetStatusList,
  optionsPageSizeList,
} from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import Skeleton from "react-loading-skeleton";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";

function BaseFare() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editbasefaremodel, setEditBaseFareModel] = useState(false);
  const [baseFarebyMot, setBaseFarebyMot] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [MOTList, setMOTList] = useState([]);
  const [MotId, setMotId] = useState(0);
  const [EditmotID, setEditMotID] = useState(0);
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [PageSize, setPageSize] = useState(20);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
    setEditBaseFareModel(false);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Mot/BindMotDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("MOT-DROPDOWn::", res.result);
          setMOTList(
            res.result.map((data) => {
              return { value: data.motID, label: data.motName };
            })
          );
        } else {
          setMOTList([]);
        }
      });
  };

  useEffect(() => {
    getBaseFarebyMOT();
  }, [MotId]);

  // GET BASEFARE PAGE START
  const getBaseFarebyMOT = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Mot/Get?MotID=${MotId}&Type=&Status=${1}&PageSize=100&CurrentPage=1`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("BASE-FARE-PAGE::", res.result);
        if (res.success) {
          setBaseFarebyMot(res.result);
          setIsLoading(false);
        } else {
          setBaseFarebyMot("");
          setIsLoading(false);
        }
      });
  };
  // GET BASEFARE PAGE END

  const clearSerachBySelect = () => {
    setMotId(0);
  };

  const exportExcelBaseFare = () => {
    exportExcel(
      `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
      "BaseFare.xlsx"
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="basefare-table">
                  <div className="seleting-menus">
                    <div className="page-title">BASEFARE ({pageCount})</div>

                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={MOTList}
                        placeholder="Select MOT"
                        value={MotId === 0 ? "" : MOTList.value}
                        onInputChange={MOTList.label}
                        onChange={(data) => setMotId(data.value)}
                        isSearchable={true}
                        // isClearable={true}
                      />

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsMeetStatusList}
                        placeholder="Select Status"
                        value={
                          activeStatus === 0 ? "" : optionsMeetStatusList.value
                        }
                        onInputChange={optionsMeetStatusList.label}
                        onChange={(data) => setActiveStatus(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                        // isClearable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-add-basefare"
                        onClick={() => setShow(!show)}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-plus-square" /> &nbsp;
                          <h6>Add New</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive basefare-scrollbar">
                    <table className="table basefare-table-data">
                      <thead className="thead-light basefare-thead">
                        <tr>
                          <th>NAME</th>
                          <th>LEVEL</th>
                          <th>MOT TYPE</th>
                          <th>BASEFARE</th>
                          <th>UNIT</th>
                          <th>STATUS</th>
                          <th>ADDED BY</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                            <SkeletonMeetPurpose />
                          </>
                        ) : baseFarebyMot !== "" ? (
                          baseFarebyMot.map((data, i) => (
                            <tr key={i} className="basefare-table-row-data">
                              <td>{data.motName}</td>
                              <td>L1</td>
                              <td>{data.motType}</td>
                              <td>{data.baseFare}</td>
                              <td>{data.motUnit}</td>
                              <td>
                                {data.status == 1 ? "Active" : "InActive"}
                              </td>
                              <td>{data.userFullName}</td>
                              <td>
                                <button
                                  onClick={() => {
                                    setEditBaseFareModel(!editbasefaremodel);
                                    setEditMotID(JSON.parse(data.motID));
                                  }}
                                  className="btn basefare-edit-link"
                                >
                                  <i className="bi bi-pencil-fill"></i> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          "NO DATA FOUND"
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <button
                    type="button"
                    className="btn btn-download-excel"
                    onClick={exportExcelBaseFare}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                </div>

                {/* ADD PRODUCT MODEL START */}
                <AddBaseFareModel
                  show={show}
                  handleClose={handleClose}
                  getBaseFarebyMOT={getBaseFarebyMOT}
                />
                {/* ADD PRODUCT MODEL END */}

                {/* EDIT PRODUCT MODEL START */}
                <EditBaseFareModel
                  editbasefaremodel={editbasefaremodel}
                  EditmotID={EditmotID}
                  handleClose={handleClose}
                  getBaseFarebyMOT={getBaseFarebyMOT}
                />
                {/* EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default BaseFare;
