import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import Activities from "./Activities";
import "../../styles/Dashboard.scss";
import "../../styles/CommonStyle.scss";
import profileimg from "../../images/profile-1.png";
import { Link } from "react-router-dom";
import { baseURL } from "../BaseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonCompTData from "../Skeleton Loading/SkeletonCompTData";
import SkeletonActivity from "../Skeleton Loading/SkeletonActivity";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import moment from "moment";
import ProgressBar from "./ProgressBar";
import { Badge, Col, Row } from "react-bootstrap";
import DrilldownChartPage from "./DrilldownChartPage";
import BarChartGraph from "./BarChartGraph";
import DrillChartMeeting from "./DrillChartMeeting";
import GoogleMapComp from "../GoogleMapComp";

function Dashboard() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  let dslft = sessionStorage.getItem("usrlgndtls");
  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let daysLeft = JSON.parse(dslft)?.subscription?.daysleft;
  sessionStorage.setItem("daysLeft", daysLeft);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userId, setUserId] = useState("");
  const [dashBoardData, setDashBoardData] = useState("");
  const [selectBy, setSelectBy] = useState("By Day");
  const [selectByGrap, setSelectByGrap] = useState("By Day");
  const [userActivity, setUserActivity] = useState(false);
  const [dBMeetingDetails, setDBMeetingDetails] = useState([]);
  const [dBMeetingGrap, setDBMeetingGrap] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [directCount, setDirectCount] = useState('')
  const [scheduleCount, setScheduleCount] = useState('')
  const [myVisitCount, setMyVisitCount] = useState('')
  const [latLan, setLatLan] = useState({
    lat: 21.0000,
    lng: 78.0000,
  });

  useEffect(() => {
    if (dBMeetingGrap.length != 0) {
      let direct = 0
      let scheduled = 0
      let Myvisit = 0
      dBMeetingGrap.map(item => {
        if (item.directVisitMeetings) {
          direct += item.directVisitMeetings
        }
        if (item.scheduleMeetings) {
          scheduled += item.scheduleMeetings
        }
        if (item.myVisitMeetings) {
          Myvisit += item.myVisitMeetings
        }
      })
      setDirectCount(direct)
      setScheduleCount(scheduled)
      setMyVisitCount(Myvisit)
    }

  }, [dBMeetingGrap])

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const goBacktodashboard = () => {
    setUserActivity(!userActivity);
  };

  /* FETCH CARD DATA START  */
  useEffect(() => {
    getDashboardCounts();
  }, []);

  const getDashboardCounts = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Travelize_Authentication: userDetails,
      },
    };

    fetch(`${baseURL}/api/DashBoard/GetCounts`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDashBoardData(res.result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setDashBoardData("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH CARD DATA END  */

  /* FETCH MEETINGS COUNT DATA START  */
  useEffect(() => {
    sortMeetingBy();
    sortMeetingByGrap();
  }, [selectBy, selectByGrap]);

  const sortMeetingBy = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(
      `${baseURL}/api/DashBoard/GetContributions/${selectBy}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDBMeetingDetails(res.result);
          setIsLoading(false);
        } else {
          setDBMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH MEETINGS COUNT DATA END  */

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, []);

  const sortMeetingByGrap = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(
      `${baseURL}/api/DashBoard/GetContributions/${selectByGrap}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDBMeetingGrap(res.result);
          setIsLoading(false);
        } else {
          setDBMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH MEETINGS COUNT DATA END  */

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, []);

  const fetchRecentActivities = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(`${baseURL}/api/DashBoard/GetRecentActivities`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRecentActivities(res.result);
        } else {
          setRecentActivities("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH RECENT ACTIVITY DATA END  */

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}
              <div className="page-wrapper">
                {/* MAIN BODY START */}
                {!userActivity ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    {/* PAGE CONTENT START */}

                    <div className="page-content-dash">

                      {/* FIRST ROW START */}
                      <div className="sb-card-row">
                        {/* <div className="col-md-3"> */}
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/user.svg"
                                  alt="user"
                                  className="card-imag"
                                />

                                {/* <i className="fa fa-users card-imag" /> */}

                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <Link className="act-body" to="/users">
                                  <p className="card-head">Active Users</p>
                                  <p>
                                    <b className="act-num">
                                      {isLoading ? (
                                        <Skeleton />
                                      ) : (
                                        <>
                                          {dashBoardData?.totalCountOfUsers ??
                                            0}
                                          /{dashBoardData?.maxUsers ?? 0}
                                        </>
                                      )}
                                    </b>
                                  </p>
                                  <div style={{ padding: "10px 22px" }}>
                                    <ProgressBar completed={dashBoardData.totalCountOfUsers} />
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div>
                      <div className="col-md-3"> */}
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/meeting.svg"
                                  alt="user"
                                  className="card-imag"

                                />

                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <Link className="act-body" to="/meetings">
                                  <div >
                                    <Row >
                                      <Col md={5} className="p-0 d-flex align-items-center meeting-grap-section">
                                        <img
                                          src="../images/dashboard/meetingGraph.svg"
                                          alt="user"
                                          className="grap-imag"

                                        />
                                        <div className="sb-complete-meeting">
                                          <p>{dashBoardData.completedMeetings}</p>
                                          <p>Completed</p>
                                        </div>
                                      </Col>
                                      <Col md={7} className="p-0 d-flex align-items" style={{ textAlign: "left" }}>
                                        <div>
                                          <p className="card-head">Meeting</p>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box1"></span> &nbsp;&nbsp;<p>Completed</p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box2"></span> &nbsp;&nbsp;<p>Pending</p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box3"></span> &nbsp;&nbsp;<p>Not Start</p>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Link>
                              </div>
                            </div>

                          </div>
                        </div>
                        {/* </div>
                      <div className="col-md-3"> */}
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/attendance.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <Link className="act-body d-flex align-items-center" to="/meetings">
                                  <div className="attend-card">
                                    <p className="card-head">Attendance</p>

                                    <div className="d-flex align-items-center sb-grap-text">
                                      <span className="grap-box box1"></span> &nbsp;&nbsp;<p>Present - {dashBoardData.todayAttendanceCount}</p>
                                    </div>
                                    <div className="d-flex align-items-center sb-grap-text">
                                      <span className="grap-box box2"></span> &nbsp;&nbsp;<p>Absent - {dashBoardData.absentCount}</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/clients.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <div className="act-body">
                                  <div className="client-card">
                                    <Row>
                                      <Col md={6} className="p-0 d-flex align-items-center" >
                                        <div>
                                          <p className="card-head">Company</p>
                                          <p className="client-text">
                                            {dashBoardData.clientCount}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col md={6} className="p-0 d-flex align-items-center">
                                        <div>
                                          <p className="client-text">{dashBoardData.todaysNewClients}%</p>
                                          <p className="client-text">
                                            New Company
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                    <img
                                      src="../images/dashboard/clientsgraph.svg"
                                      alt="user"
                                      className="card-imag"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/claims.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <div className="act-body">
                                  <div >
                                    <Row >
                                      <Col md={5} className="p-2 d-flex align-items-center meeting-grap-section">
                                        <img
                                          src="../images/dashboard/claimsgrap.svg"
                                          alt="user"
                                          className="grap-imag"

                                        />
                                        <div className="sb-claims">
                                          <p>{dashBoardData.cliaimCount}</p>
                                        </div>
                                      </Col>
                                      <Col md={7} className="p-0 d-flex align-items-center" style={{ textAlign: "left" }}>
                                        <div>
                                          <p className="card-head">Claims</p>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <p>Completed</p>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* FIRST ROW END */}
                      {/* </div> */}

                      {/* SECOND ROW START */}
                      <div className="dashboard-user-details">
                        <div className="row sb-data-grap">
                          <div className="col-md-4 sb-data-col">
                            <div className="dash-board-left-side">
                              <div className="user-list-top-head">
                                <div className="sort-left-dash">
                                  SORT <i className="bi bi-sliders" />
                                </div>
                                <div className="selct-drop-by-dash">
                                  SELECT ONE
                                  <select
                                    className="meetings-sort select-by-dropdown"
                                    value={selectBy}
                                    onChange={(e) =>
                                      setSelectBy(e.target.value)
                                    }
                                  >
                                    <option value="By Day">By Day</option>
                                    <option value="By Week">By Week</option>
                                    <option value="By Month">By Month</option>
                                    <option value="By 3 Month">
                                      By 3 Month
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="user-activity-data">
                                <table className="table table-responsive dashboard user-meeting-dash-table">
                                  <thead className="thead-light userlist-thead">
                                    <tr >
                                      <th className="sb-db-table-th">User Name</th>
                                      <th className="text-center sch-meeting-head sb-db-table-th">
                                        <img
                                          src="../images/dashboard/Directvisit.svg"
                                          alt="schedule-meet-icon"
                                          title="Schedule Visit"
                                          className="fa-handshake"
                                        />&nbsp;Direct
                                      </th>
                                      <th className="text-center direct-visit-head sb-db-table-th">
                                        <img
                                          src="../images/dashboard/Scheduled.svg"
                                          alt="direct-meet-icon"
                                          title="Direct Visit"
                                          className="fa-handshake"
                                        />&nbsp;Scheduled
                                      </th>
                                      <th className="text-center direct-visit-head sb-db-table-th">
                                        <img
                                          src="../images/dashboard/Myvisit.svg"
                                          alt="direct-meet-icon"
                                          title="Direct Visit"
                                          className="fa-handshake"
                                        />&nbsp;My Visit
                                      </th>
                                      <th className="text-center">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody className="dashboard-tavle-tr">
                                    {isLoading ? (
                                      <>
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                        <SkeletonCompTData />
                                      </>
                                    ) : dBMeetingDetails !== "" ? (
                                      dBMeetingDetails.map((data, i) => (
                                        <tr key={i} className="sb-db-tbody" >
                                          <td>{data?.fullName}</td>
                                          <td>
                                            {
                                              data?.directVisitMeetings
                                            }
                                          </td>
                                          <td>
                                            {
                                              data?.scheduleMeetings
                                            }
                                          </td>
                                          <td>{data?.myVisitMeetings}</td>
                                          <td>{data?.totalMeeting}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr> ---- No Data Found ---- </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="see-all-user-lish-dash">
                                  <Link to="/meetings" className="see-all-link">
                                    See All
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 sb-data-col">
                            <div className="sb-meeting-grap-div">
                              <div className="meeting-grap-head">
                                <div className="sort-left-dash">
                                  SORT <i className="bi bi-sliders" />
                                </div>
                                <div className="selct-drop-by-dash">

                                  <select
                                    className="meetings-sort select-by-dropdown"
                                    value={selectByGrap}
                                    onChange={(e) =>
                                      setSelectByGrap(e.target.value)
                                    }
                                  >
                                    <option value="By Day">By Day</option>
                                    <option value="By Week">By Week</option>
                                    <option value="By Month">By Month</option>
                                    <option value="By 3 Month">
                                      By 3 Month
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="user-activity-data">
                                <div className="grap-img">
                                  {(directCount === 0 && scheduleCount === 0 && myVisitCount === 0) ?
                                    <div className="d-flex justify-content-center">
                                      <div>
                                        No Data Available
                                      </div>
                                    </div>
                                    :
                                    <DrillChartMeeting series={[directCount, scheduleCount, myVisitCount]} />
                                  }
                                </div>
                                {(directCount !== 0 || scheduleCount !== 0 || myVisitCount !== 0) &&
                                  <div>
                                    <div className="meeting-row-div">
                                      <Row className="sb-meeting-grap-row">
                                        <Col xs={2}>
                                          <img
                                            src="../images/dashboard/Directvisit.svg"
                                            alt="user"
                                            className="grap-imag" />
                                        </Col>
                                        <Col xs={7}>
                                          <p>Direct Visit</p>
                                        </Col>
                                        <Col xs={3}>

                                          <h5>
                                            <Badge className="bd1">{directCount}</Badge>
                                          </h5>
                                        </Col>
                                      </Row>
                                      <hr style={{ margin: "0px 5%", width: "90%" }} />
                                      <Row className="sb-meeting-grap-row">
                                        <Col xs={2}>
                                          <img
                                            src="../images/dashboard/Scheduled.svg"
                                            alt="user"
                                            className="grap-imag" />
                                        </Col>
                                        <Col xs={7}>
                                          <p>Scheduled Visit</p>
                                        </Col>
                                        <Col xs={3}>

                                          <h5>
                                            <Badge className="bd2">{scheduleCount}</Badge>
                                          </h5>
                                        </Col>
                                      </Row>
                                      <hr style={{ margin: "0px 5%", width: "90%" }} />
                                      <Row className="sb-meeting-grap-row">
                                        <Col xs={2}>
                                          <img
                                            src="../images/dashboard/Myvisit.svg"
                                            alt="user"
                                            className="grap-imag" />
                                        </Col>
                                        <Col xs={7}>
                                          <p>My Visit</p>
                                        </Col>
                                        <Col xs={3}>

                                          <h5>
                                            <Badge className="bd3">{myVisitCount}</Badge>
                                          </h5>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>

                          </div>
                          <div className="col-md-5">
                            <div className="user-activity-right-dash">
                              <div>
                                <div className="userlist-thead-activity-table">
                                  <div className="act-top-head">
                                    <div className="act-heading">
                                      ACTIVITIES
                                    </div>
                                    <div className="search-and-refresh-part">
                                      <div className="input-group input-group-sm activity-search">
                                        <button
                                          className="refresh-button"
                                          onClick={fetchRecentActivities}
                                        >
                                          <i className="fa fa-refresh refresh-activity" />
                                        </button>
                                        <input
                                          type="text"
                                          className="form-control "
                                          placeholder="Search...."
                                          aria-label="Sizing example input"
                                          aria-describedby="inputGroup-sizing-sm"
                                          onChange={(e) =>
                                            setSearchVal(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="user-activity-data table-responsive">
                                  <div className="user-activity-rowdata">
                                    {isLoading ? (
                                      <>
                                        <SkeletonActivity />
                                        <SkeletonActivity />
                                        <SkeletonActivity />
                                        <SkeletonActivity />
                                      </>
                                    ) : recentActivities !== "" ? (
                                      recentActivities
                                        .filter((val) =>
                                          val?.fullName
                                            .toLowerCase()
                                            .includes(searchVal)
                                        )
                                        .map((data, i) => (
                                          <div
                                            key={i}
                                            className="act-right-body"
                                          >
                                            <Row>
                                              <Col md={2}
                                                // style={{ maxWidth: "75px" }}
                                                className="td"
                                              >
                                                <div className="table-user-image">
                                                  <img
                                                    className="table-data-user-img"
                                                    src={`${baseURL}/${data?.profileIcon}`}
                                                    alt="John Doe"
                                                  />
                                                  <h6>{data.fullName}</h6>
                                                </div>
                                              </Col>
                                              <Col md={10} className="td">
                                                <div>
                                                  <div className="td-user-location">
                                                    {data?.activityType ===
                                                      "Re-Check-In" ||
                                                      data?.activityType ===
                                                      "Check-In" ? (
                                                      <>
                                                        <span>
                                                          <b>
                                                            {data?.activityType} @
                                                            &nbsp;
                                                            {data?.checkInTime}
                                                          </b>
                                                        </span>
                                                        <br />
                                                        <span>
                                                          {data?.cordLoc?.location}
                                                        </span>
                                                      </>
                                                    ) : data?.activityType ===
                                                      "Check-Out" ? (
                                                      <>
                                                        <span>
                                                          <b>
                                                            {data?.activityType} @
                                                            &nbsp;
                                                            {data?.checkOutTime}
                                                          </b>
                                                        </span>
                                                        <br />
                                                        <span>
                                                          {data?.cordLoc?.location}
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <span>
                                                          {data?.activityType != null && <b>
                                                            {data?.activityType}{" "}
                                                            with
                                                          </b>}
                                                          &nbsp;{" "}
                                                          <b>
                                                            {data?.clientName} @
                                                            {moment(
                                                              data?.date
                                                            ).format("hh:MM a")}
                                                          </b>{" "}
                                                        </span>
                                                        <br />
                                                        <span>
                                                          {data?.cordLoc?.location}
                                                        </span>
                                                      </>
                                                    )}
                                                  </div>
                                                  <span className="user-activity-images">
                                                    <span className="act-icon">
                                                      <Link
                                                        to={`/trackbyuser/${data?.userID}`}
                                                        className="activity-page-link sb-ds-link"
                                                        onClick={() => {
                                                          sessionStorage.setItem(
                                                            "userID",
                                                            data?.userID
                                                          );
                                                        }}
                                                      >
                                                        <div className="activity-icons">
                                                          <i className="bi bi-geo-alt-fill fa"></i>
                                                        </div>
                                                        <p>Live Location</p>
                                                      </Link>
                                                    </span>
                                                    <span className="act-icon">
                                                      <Link
                                                        to="/attendance/fullday"
                                                        className="activity-page-link sb-ds-link"
                                                      >
                                                        <div className="activity-icons">
                                                          <i className="fa fa-clock-o"></i>
                                                        </div>

                                                        <p>{data?.checkInTime}</p>
                                                      </Link>
                                                    </span>
                                                    <span className="act-icon sb-ds-link">
                                                      <div className="activity-icons">
                                                        <i className="fa fa-history"></i>
                                                      </div>
                                                      <p>{data?.minsHrsAgo}</p>
                                                    </span>
                                                    <span className="act-icon sb-ds-link">
                                                      <div className="activity-icons">
                                                        <i className="fa fa-signal"></i>
                                                      </div>
                                                      <p>{data?.mobileNetwork}</p>
                                                    </span>
                                                    <span className="act-icon sb-ds-link">
                                                      <div className="activity-icons">
                                                        <i className="fa fa-battery-full"></i>
                                                      </div>
                                                      <p>{data?.batteryStrength}</p>
                                                    </span>

                                                    <span className="act-icon">
                                                      <Link
                                                        to={`/meetings`}
                                                        className="attendance-page-link sb-ds-link"
                                                      >
                                                        <div className="activity-icons meeting">
                                                          <i className="fa fa-handshake-o"></i>
                                                        </div>

                                                        <p>Meetings Today</p>
                                                      </Link>
                                                      <span className="recent-activity-meeting-count">
                                                        {data?.meetingCount}
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))
                                    ) : (
                                      <div className="no-activity-found">
                                        ------ No Activities Found ------
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* SECOND ROW END */}
                      </div>

                      {/* Third Row India Map */}
                      <div className="dashboard-user-details">
                        <div className="sb-db-map">
                          <GoogleMapComp
                            pageType="Dashboard"
                            latLan={latLan}
                            activityData={recentActivities}
                          />
                        </div>
                      </div>
                      {/* Third Row India Map */}
                    </div>

                    {/* PAGE CONTENT END*/}
                    {/* MAIN BODY END */}
                  </div>
                ) : (
                  <>
                    <Activities
                      userId={userId}
                      userActivity={userActivity}
                      toggleshift={toggleshift}
                      goBacktodashboard={goBacktodashboard}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
