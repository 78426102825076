import * as types from './actionType';

const initialState = {
    mainModules: [],
    sbcrBySubscriber: [],
    allMenus: [],
    subcriptionList: [],
    allSubcriptions: [],
    trackData: [],
    distanceMatrix: {},
    successMsg: {},
    loading: true,
};

const designReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.Add_Designation:
        case types.Get_Main_Modules:
            return {
                ...state,
                mainModules: action.payload,
                loading: false
            }
        case types.Get_Subscription_Module:
            return {
                ...state,
                sbcrBySubscriber: action.payload,
                loading: false
            }
        case types.Get_All_Menus:
            return {
                ...state,
                allMenus: action.payload,
                loading: false
            }
        case types.Post_Menus_Subscription:
        case types.Get_Subscription:
            return {
                ...state,
                subcriptionList: action.payload,
                loading: false
            }
        case types.Get_All_Subscription:
            return {
                ...state,
                allSubcriptions: action.payload,
                loading: false
            }
        case types.Get_Track_User:
            return {
                ...state,
                trackData: action.payload,
                loading: false
            }
        case types.Get_Distance_Matrix:
            return {
                ...state,
                distanceMatrix: action.payload,
                loading: false
            }
        case types.Get_Successs_Msg:
            return {
                ...state,
                successMsg: action.payload,
                loading: false
            }
        default:
            return state;
    }
};
export default designReducers;