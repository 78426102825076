import React, { useRef, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import userimg from "../images/no_img.png";
import "../styles/Topbar.scss";
import profileimg from "../images/admin-1.png";
import { baseURL } from "./BaseUrl";

function Topbar(props) {
  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  
  const companyName = userLgnDetails?.subscription?.company?.companyName;

  const menuIconClick = props.menuIconClick;
  const menuCollapse = props.menuCollapse;
  const toggleshift = props.toggleshift;
  const toggleMenu = props.toggleMenu;

  let navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  let daysLeft = sessionStorage.getItem("daysLeft");

  return (
    <div>
      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <div className="dropdown list-unstyled topbar-nav float-end">
            <span className="your-days-left">
              <Link to="/settings/account" className="go-to-accounts">
                {daysLeft < 30 ? (
                  daysLeft == 0 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription has Expired.
                    </small>
                  ) : daysLeft == 1 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end <b>Today</b>
                    </small>
                  ) : daysLeft < 10 ? (
                    <small className="days-left-in-number red">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end in : <b>{daysLeft} days</b>
                    </small>
                  ) : (
                    <small className="days-left-in-number">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end in : <b>{daysLeft} days</b>
                    </small>
                  )
                ) : (
                  ""
                )}
              </Link>
            </span>
            <div className="dropdown header-item">
              <button type="button" className="btn navdrp" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="d-flex align-items-center">
                  <div style={{ width: "50px", marginRight: "10px" }}>
                    <img
                      className="sidbar logo-img rounded-circle header-profile-user"
                      style={{ width: "50px" }}
                      src={
                        userLgnDetails?.profilePic !== "" &&
                          userLgnDetails?.profilePic !== null &&
                          userLgnDetails?.profilePic !== "no_img.png"
                          ? `${baseURL}/${userLgnDetails?.profilePic}`
                          : profileimg
                      }
                      alt="Profile Image"
                    />
                  </div>

                  <span className="text-start ms-xl-2 d-flex align-items-center">
                    <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16"><span><span className="sb-user-name">{userLgnDetails?.fullName}</span> <br />
                      <span className="sb-company-name">{companyName}</span></span></span>
                    <span>&nbsp;&nbsp;<i className="fa fa-angle-down fs-12 ms-1" style={{ fontSize: "20px" }}></i></span>
                  </span>
                </span>
              </button>

              <div className="dropdown-menu dropdown-menu-end custom-sb-drop" data-popper-placement="bottom-end" >

                <a className="dropdown-item" href="#"><i className="bx bx-user fs-15 align-middle me-1"></i> <span key="t-profile">Profile</span></a>
                <Link className="dropdown-item" to="/dashboard"><i className="bx bx-wallet fs-15 align-middle me-1"></i> <span key="t-my-wallet">Settings</span></Link>
                {/* <a className="dropdown-item d-block" href="#"><span className="badge bg-success float-end">11</span><i className="bx bx-wrench fs-15 align-middle me-1"></i> <span key="t-settings">Settings</span></a>
                <a className="dropdown-item" href="auth-lockscreen.html"><i className="bx bx-lock-open fs-15 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a> */}
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item logout-text" to="/" onClick={logout}><i className="fa fa-power-off fs-15 align-middle me-1"></i> <span key="t-logout">Logout</span></Link>
              </div>
            </div>
          </div>

          <div className="list-unstyled topbar-nav">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
                onClick={toggleMenu}
              >
                <div
                  className={`closemenu ${toggleshift.style}`}
                  onClick={menuIconClick}
                >
                  {menuCollapse ? (
                    <span className="fa fa-indent"></span>
                  ) : (
                    <span className="fa fa-dedent"></span>
                  )}
                </div>
              </button>
            </li>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Topbar;
