import React, { useState, useEffect } from "react";
import { Modal, Image } from "react-bootstrap";
import "../../styles/Products.scss";
import viewattachment from "../../images/No-Image.jpg";
import { baseURL } from "../BaseUrl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import productimg from "../../images/products/headset.png";
import productimg1 from "../../images/products/haedsetyellow.png";
import productimg2 from "../../images/products/headsetblue1.png";
import productimg3 from "../../images/products/Redheadset.png";
// import productimgM from "../../images/products/MouseWired.png";
// import productimgM1 from "../../images/products/mouseblue.png";
// import productimgM2 from "../../images/products/mousewire.png";
// import productimgM3 from "../../images/products/mousebluetooth.png";

export default function ViewProductDetails(props) {
  return (
    <div>
      <div className="model-view-product-details">
        <Modal
          show={props.viewProductDetail}
          onHide={props.handleClose}
          className="view-productdetails-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">PRODUCT DETAILS</h6>
            <i className="fa fa-times" onClick={props.handleClose}></i>
          </div>
          <div className="product-details-model">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <div className="product-detail-left-part">
                  <div className="product-big-img">
                    <Image
                      src={productimg}
                      alt="attachment-photo"
                      className="product-img"
                    />
                  </div>
                  <div className="product-small-images-row">
                    <div className="product-small-image">
                      <Image
                        src={productimg1}
                        alt="attachment-photo"
                        className="product-img"
                      />
                    </div>
                    <div className="product-small-image">
                      <Image
                        src={productimg2}
                        alt="attachment-photo"
                        className="product-img"
                      />
                    </div>
                    <div className="product-small-image">
                      <Image
                        src={productimg3}
                        alt="attachment-photo"
                        className="product-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="product-detail-right-part">
                  <div className="product-name">
                    <h5>JBL Quantum 100</h5>
                  </div>
                  <div className="product-details-by-row">
                    <div className="product-row">
                      <div className="pdh">Product Name</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">
                        <b>JBL Headset Q056</b>
                      </div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">Product ID</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">H-1234</div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">Category</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">Electronics</div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">Sub-Category</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">Headset</div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">Dimension (H * W * L)</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">1800mm x 1236mm x 1356mm</div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">Size</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">Big </div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">Weight</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">500gm</div>
                    </div>

                    <div className="product-row">
                      <div className="pdh">MRP</div>
                      <div className="divide-icon">:</div> &nbsp; &nbsp; &nbsp;
                      <div className="pdd">
                        {" "}
                        <i className="fa fa-inr" />
                        &nbsp;
                        <b>10,800</b>
                      </div>
                    </div>
                  </div>
                  <div className="product-description">
                    <div className="description-instruction">
                      <Tabs
                        defaultActiveKey="description"
                        id="uncontrolled-tab-example"
                        className="description-instruction-nav-tab"
                      >
                        <Tab eventKey="description" title="Description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged.
                          </p>
                        </Tab>
                        <Tab eventKey="instruction" title="Instruction">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English. Many desktop publishing packages
                            and web page editors now use Lorem Ipsum as their
                            default model text, and a search for 'lorem ipsum'
                            will uncover many web sites still in their infancy.
                            Various versions have evolved over the years,
                            sometimes by accident, sometimes on purpose
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="attach-print-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
