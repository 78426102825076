import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "../../styles/CommonStyle.scss";
import "../../styles/Tracker.scss";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import profileimg from "../../images/profile-1.png";
import GoogleMapComp from "../GoogleMapComp";

function TrackuserRoute(props) {
  const [meetingRouteDetails, setMeetingRouteDetails] = useState([]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [routelatLan, setRouteLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [startLatLan, setStartLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [endLatLan, setEndLatLan] = useState({
    lat: null,
    lng: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchMeetingsRoute();
  }, []);

  const fetchMeetingsRoute = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetMeetingActivities/${props.meetingId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("MEETING-ROUTE-RESPONSE::", res.result);
          setMeetingRouteDetails(res.result);
          setRouteLatLan({
            lat: parseFloat(res.result?.mapCordinates[0]?.cordLoc?.latitude),
            lng: parseFloat(res.result?.mapCordinates[1]?.cordLoc?.longitude),
          });
          if (
            res.result?.mapCordinates[0]?.startPoint.trim() === "Yes" &&
            res.result?.mapCordinates[0]?.endPoint.trim() === "No"
          ) {
            setStartLatLan({
              lat: parseFloat(res.result?.mapCordinates[0]?.cordLoc?.latitude),
              lng: parseFloat(res.result?.mapCordinates[0]?.cordLoc?.longitude),
            });
          }
          if (
            res.result?.mapCordinates[1]?.startPoint.trim() == "No" &&
            res.result?.mapCordinates[1]?.endPoint.trim() == "Yes"
          ) {
            setEndLatLan({
              lat: parseFloat(res.result?.mapCordinates[1]?.cordLoc?.latitude),
              lng: parseFloat(res.result?.mapCordinates[1]?.cordLoc?.longitude),
            });
          } else {
            setStartLatLan({ lat: "", lng: "" });
            setEndLatLan({ lat: "", lng: "" });
          }
        } else {
          setMeetingRouteDetails("");
        }
      });
  };

  return (
    <div id="main-page">
      <>
        {/* MAIN BODY START */}
        <div className="page-wrapper">
          {/* PAGE CONTENT START */}
          {props.viewonuserroute ? (
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">
                {/* TRACKER TABLE START */}
                <div className="tracker-table">
                  <span className="seleting-menus track-user-route">
                    <span className="page-title"> TRACK USER ROUTE </span>

                    <button
                      type="button"
                      className="btn btn-go-back"
                      onClick={props.goBacktomeetingsdetails}
                    >
                      <span className="btn-inline-item-back d-flex">
                        <i className="fa fa-arrow-left"></i>
                        <h6>Back</h6>
                      </span>
                    </button>
                  </span>
                  <div className="table-responsive tracker-tabel-scroll">
                    <table className="table trackers-list-table">
                      <thead className="thead-light trackers-list-thead">
                        <tr>
                          <th>NAME</th>
                          <th>MOBILE</th>
                          <th>DATE</th>
                          <th>KMS. TRAVELLED</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>

                      <tbody>
                        {meetingRouteDetails !== "" ? (
                          <tr
                            key={meetingRouteDetails?.meetingID}
                            className="track-list-row-data"
                          >
                            <td>
                              <span className="track-list-name d-flex">
                                <img
                                  className="table-data-track-imgages"
                                  src={profileimg}
                                  alt="John Doe"
                                />
                                <h6>{meetingRouteDetails?.fullName}</h6>
                              </span>
                            </td>
                            <td>{meetingRouteDetails?.mobile}</td>
                            <td>
                              {meetingRouteDetails?.mapCordinates?.length > 0
                                ? meetingRouteDetails?.mapCordinates[0]
                                    ?.cordDate
                                : ""}
                            </td>
                            <td>{meetingRouteDetails?.distanceTravelled}</td>
                            <td>
                              <span
                                className="td-print-column d-flex"
                                onClick={handlePrint}
                              >
                                <i className="bi bi-printer" />
                                Print
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <tr>-----NO DATA FOUND-----</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* TRACKER TABLE END */}
                <div className="trakuser-route-map">
                  <div className="row view-on-map">
                    <div className="col-md-12 track-user-google-map">
                      <div className="google-map-box" ref={componentRef}>
                        <GoogleMapComp
                          routelatLan={routelatLan}
                          startLatLan={startLatLan}
                          endLatLan={endLatLan}
                          pageType="TrackuserRoute"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* PAGE CONTENT END*/}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* MAIN BODY END */}
        </div>
      </>
    </div>
  );
}

export default TrackuserRoute;
