import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import profileimg from "../../images/profile-1.png";
import Leaveupdatemodel from "./Leaveupdatemodel";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsLeaveStatusList, optionsPageSizeList } from "../DropdownsData";
import {
  SkeletonStdTableBody,
  SkeletonStdTableHead,
} from "../Skeleton Loading/SkeletonStdTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";

function Leaves() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [leaveUpdatestatus, setLeaveUpdatestatus] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [status, setStatus] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  let tableHeader = [
    "NAME",
    "TYPE",
    "FROM",
    "TO",
    "DAY(S)",
    "REASON",
    "STATUS",
    "ACTIONS"
  ]

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const presentDate = new Date();
  const currentDate = moment(presentDate).format("DD/MM/YYYY");

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  var pageDataFrom, pageDataTill;

  pageDataFrom = currentPage * PageSize - PageSize + 1;
  pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const handleClose = () => setLeaveUpdatestatus(!leaveUpdatestatus);

  // FETCH SELECT DROPDOWN DATA
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN DATA

  // FETCH LEAVE DETAILS START
  useEffect(() => {
    fetchLeaveDetails();
  }, [filterUserId, fromDate, toDate, status, currentPage, PageSize]);

  const fetchLeaveDetails = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Leave/GetLeaves?PageSize=${PageSize}&CurrentPage=${currentPage}&status=${status}&userId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format("DD/MM/YYYY")}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveDetails(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setLeaveDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH LEAVE DETAILS END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setStatus("");
    setFromDate(new Date());
    setToDate(new Date());
    setTotalData(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelActiivity = () => {
    exportExcel(
      `${baseURL}/api/Export/LeaveExcelExport?UserID=${filterUserId}&LeaveType=${leaveId}&Status=${status}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Leave.xlsx"
    );
  };
  //* EXPORT FILE END *//


  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* USER TABLE START */}
                    <div className="leaves-table">
                      <div className="seleting-menus">
                        <div className="page-title">LEAVES</div>

                        <div className="page-top-bar-dropdowns">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userList}
                            placeholder="Select User"
                            value={filterUserId === "" ? "" : userList.value}
                            onInputChange={userList.label}
                            onChange={(data) => setFilterUserId(data.value)}
                            isSearchable={true}
                          />

                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            // defaultValue={optionsRoleList[0].label}
                            options={optionsLeaveStatusList}
                            placeholder="Select Status"
                            value={
                              status === "" ? "" : optionsLeaveStatusList.value
                            }
                            onInputChange={optionsLeaveStatusList.label}
                            onChange={(data) => setStatus(data.value)}
                            isSearchable={true}
                          />

                          <div className="input-group from-to-date-select">
                            <span className="input-group-text" id="from-label">
                              From
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date"
                                id="from-datepicker"
                                dateFormat="dd/MM/yyyy"
                                // selectsEnd
                                // startDate={toDate}
                                // endDate={toDate}
                                onChange={(date) => setFromDate(date)}
                                maxDate={toDate}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </span>

                            <span className="input-group-text" id="to-label">
                              To
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={toDate}
                                className="select to-date"
                                id="from-datepicker"
                                dateFormat="dd/MM/yyyy"
                                // selectsEnd
                                // startDate={fromDate}
                                // endDate={toDate}
                                minDate={fromDate}
                                onChange={(date) => setToDate(date)}
                              // maxDate={new Date()}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </span>
                          </div>

                          <button
                            type="button"
                            className="btn btn-search-track"
                            onClick={clearSerachBySelect}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-eraser"> </i>
                              <h6>Clear</h6>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive leaves-scroll">
                        <table className="table leaves-table">
                          <SbTableHeader tableHeader={tableHeader} />
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                              </>
                            ) : leaveDetails !== "" ? (
                              leaveDetails?.map((data, i) => (
                                <tr className="leaves-list-row-data" key={i}>
                                  <td>
                                    <span className="leaves-list-names d-flex">
                                      <img
                                        className="table-data-leaves-imgages"
                                        src={profileimg}
                                        alt="profile-img"
                                      />
                                      <div>
                                        <p className="name">{data?.fullName}</p>
                                        <p>{data?.userCode}</p>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.typeName}</td>
                                  <td>{data?.fromdate}</td>
                                  <td>{data?.toDate} </td>

                                  <td>{data?.noOfdays}</td>
                                  <td style={{ maxWidth: "200px" }}>
                                    {data?.lessRemarks === "NA" ? (
                                      data?.remarks ?? "NA"
                                    ) : (
                                      <OverlayTrigger
                                        placement={"bottom"}
                                        delay={{ show: 250, hide: 300 }}
                                        overlay={
                                          <Tooltip id={`tooltip-remarks`}>
                                            {data?.remarks}
                                          </Tooltip>
                                        }
                                      >
                                        <div>{data?.lessRemarks}</div>
                                      </OverlayTrigger>
                                    )}
                                  </td>

                                  <td
                                    className={
                                      data?.status === "Approved"
                                        ? "leave-status approved"
                                        : data?.status === "Rejected"
                                          ? "leave-status rejected"
                                          : data?.status === "Requested"
                                            ? "leave-status requested"
                                            : "leave-status"
                                    }
                                  >
                                    {data?.status}
                                  </td>

                                  <td>
                                    <button
                                      className={
                                        data?.status == "Rejected" ||
                                          data?.status == "Cancelled"
                                          ? "btn leave-update-status hide"
                                          : data?.status == "Requested"
                                            ? "btn leave-update-status"
                                            : "btn leave-update-status"
                                      }
                                      style={{
                                        visibility:
                                          (data?.toDate <= currentDate &&
                                            data?.status == "Approved") ||
                                            (data?.toDate <= currentDate &&
                                              data?.status == "Rejected") ||
                                            (data?.toDate <= currentDate &&
                                              data?.status == "Cancelled")
                                            ? "hidden"
                                            : "visible",
                                      }}
                                      onClick={() => {
                                        setLeaveUpdatestatus(
                                          !leaveUpdatestatus
                                        );
                                        setLeaveId(data.leaveID);
                                      }}
                                    >
                                      <i className="bi bi-pencil-fill" />
                                      Update Status
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                <td colSpan={8}>------ NO DATA FOUND ------</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    <div className="download-and-pagination">
                      <div className="data-count-per-page">
                        {totalData == 0 ? 0 : pageDataFrom} -{" "}
                        {totalData == 0 ? 0 : pageDataTill} of {totalData}
                      </div>
                      <button
                        type="button"
                        className="btn btn-download-excel"
                        onClick={exportExcelActiivity}
                      >
                        <i className="fa fa-download" /> &nbsp; Download
                        {/* <img src={excelimg} alt="excel" className="thumbnail" /> */}
                      </button>
                      <span className="user-pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fa fa-angle-double-left" />
                          }
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />
                      </span>
                    </div>
                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* MAIN BODY END */}
              </div>
              {/* ADD LEAVE UPDATE STATUS MODEL START */}
              {leaveUpdatestatus ? (
                <Leaveupdatemodel
                  fetchLeaveDetails={fetchLeaveDetails}
                  leaveUpdatestatus={leaveUpdatestatus}
                  handleClose={handleClose}
                  leaveId={leaveId}
                />
              ) : (
                ""
              )}
              {/* /* ADD LEAVE UPDATE STATUS MODEL END */}
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Leaves;
