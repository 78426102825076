import React from 'react'

function SbTableHeader(props) {
  return (
    <thead className="thead-light users-list-thead">
        <tr>
        {props.tableHeader.map((item, i) => (
        <th key={i}>{item === "MRP" && <span>(
            <i className="fa fa-inr" style={{ fontSize: "12px" }} />
            )</span>}{item}</th>
        ))}
        </tr>
    </thead>
  )
}

export default SbTableHeader
