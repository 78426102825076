import React, { useState, useEffect } from "react";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { exportExcel } from "../DownloadFile";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import {
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import SkeletonApproved from "../Skeleton Loading/SkeletonApproved";
import Accordion from "react-bootstrap/Accordion";

function ApprovedClaimbyManager(props) {
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [approvedClaimDeatils, setApprovedClaimDeatils] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expander, setExpander] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    fetchApprovedClaimData();
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS START
  const fetchApprovedClaimData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/OSClaim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusIDList=43,47,48&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("APPROVED-CLAIM::", res.result);
        if (res.success) {
          setApprovedClaimDeatils(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setApprovedClaimDeatils("");
          setIsLoading(false);
        }
      });
  };
  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFromDate(new Date());
    setToDate(new Date());
  };

  // const exportExcelClaim = () => {
  //   exportExcel(
  //     `${baseURL}/api/Export/ClaimDetailExcelExport?UserID=${
  //       props.claimUserId
  //     }&StatusIDList=43,47&SearchStatusType=Claim&FromDate=${moment(
  //       fromDate
  //     ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
  //       "DD/MM/YYYY"
  //     )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
  //     "Approved-ClaimData.xlsx"
  //   );
  // };

  // const exportUserwiseReport = () => {
  //   exportExcel(
  //     `${baseURL}/api/Export/UserWiseClaimExcelExport?UserID=${
  //       props.claimUserId
  //     }&StatusIDList=43,47&SearchStatusType=Claim&FromDate=${moment(
  //       fromDate
  //     ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
  //       "DD/MM/YYYY"
  //     )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
  //     "ApprovedClaim-UserwiseReport.xlsx"
  //   );
  // };

  return (
    <div id="main-page">
      {props.approvedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* USER TABLE START */}
            <div className="approvedclaim-table">
              <div className="seleting-menus">
                <div className="page-title claim-approval">
                  Approved Claim by Manager
                </div>
                <div className="page-top-bar-dropdowns">
                  <div className="input-group from-to-date-select">
                    <span className="input-group-text" id="from-label">
                      From
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>

                    <span className="input-group-text" id="to-label">
                      To
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-search-track"
                    onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-download-userwise-report"
                    // onClick={exportUserwiseReport}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-download"></i>
                      <h6>Userwise Report</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-user"
                    onClick={props.goBackToClaimfromApprovedPage}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-arrow-left"> </i>
                      <h6>Back</h6>
                    </span>
                  </button>
                </div>
              </div>
              <div className="pendingfor-approval">
                {isLoading ? (
                  <SkeletonPendingClaimHead />
                ) : (
                  <div className="pendingapproval-records">
                    <div className="claim-user-details-img">
                      <img
                        className="pendingapproval-user-imgage"
                        src={profileimg}
                        alt="User Image"
                      />
                      <div className="pendingapproval-username-details">
                        <span>
                          <b>User Name :</b> &nbsp;{" "}
                          {approvedClaimDeatils[0]?.fullName}
                        </span>
                        <span>
                          <b>Email ID :</b> &nbsp;{" "}
                          {approvedClaimDeatils[0]?.email}
                        </span>
                        <span>
                          <b>Contact No.:</b> &nbsp;{" "}
                          {approvedClaimDeatils[0]?.countryCode}{" "}
                          {approvedClaimDeatils[0]?.contact}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="table-responsive approvedclaim-scroll">
                  {isLoading ? (
                    <SkeletonPendingTableData />
                  ) : approvedClaimDeatils !== "" &&
                    approvedClaimDeatils.length > 0 ? (
                    approvedClaimDeatils?.map((data, i) => (
                      <table className="table attendance-list-table">
                        <thead className="thead-light approved-claim-thead">
                          <tr>
                            <th>
                              {i + 1}. AppliedDate :{" "}
                              {moment(data?.claimDate).format("DD/MM/YYYY")}
                            </th>
                            <th>No. of Days : {data?.noOfDays}</th>
                            <th>
                              From Date :{" "}
                              {moment(data?.fromDate).format("DD/MM/YYYY")}
                            </th>
                            <th>
                              To Date :{" "}
                              {moment(data?.toDate).format("DD/MM/YYYY")}
                            </th>
                            <th>From Location : {data?.fromLocation}</th>
                            <th>To Location : {data?.toLocation}</th>
                            <th>Status : {data?.byManager?.status}</th>
                          </tr>
                          <tr>
                            <th>Type</th>
                            <th>Attachment</th>
                            <th>Applied</th>
                            <th>Approved</th>
                            <th>Approved By</th>
                            <th colSpan={2}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr className="attendance-list-row-data">
                              <td>{data?.fareClaim?.mot} Fair</td>
                              <td>
                                <i
                                  className="fa fa-paperclip"
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.fareClaim?.attachment ?? ""
                                    );
                                  }}
                                />
                              </td>
                              <td>{data?.fareClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedOSClaimAmount ?? 0}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.fareClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Lodging</td>
                              <td>
                                <i
                                  className="fa fa-paperclip"
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.hotelClaim?.attachment ?? ""
                                    );
                                  }}
                                />
                              </td>
                              <td>{data?.hotelClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedHotelClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.hotelClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Food</td>
                              <td>
                                <i
                                  className="fa fa-paperclip"
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.foodClaim?.attachment ?? ""
                                    );
                                  }}
                                />
                              </td>
                              <td>{data?.foodClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedFoodClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.foodClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Local Convayence</td>
                              <td>
                                <i
                                  className="fa fa-paperclip"
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.localClaim?.attachment ?? ""
                                    );
                                  }}
                                />
                              </td>
                              <td>{data?.localClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedLocalClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.localClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Others</td>
                              <td>
                                <i
                                  className="fa fa-paperclip"
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.othersClaim?.attachment ?? ""
                                    );
                                  }}
                                />
                              </td>
                              <td>{data?.othersClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedOthersClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.othersClaim?.remark}</td>
                            </tr>
                            <tr className="pendingapproval-row-data total-value">
                              <td>
                                {" "}
                                &nbsp; &nbsp;
                                <b>
                                  Approved Date :{" "}
                                  {moment(data?.byManager?.date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </b>
                              </td>
                              <td>
                                <b>TOTAL</b>
                              </td>
                              <td>
                                <b>{data?.totalAmount}</b>
                              </td>
                              <td>
                                <b>
                                  {data?.byManager?.approvedTotalClaimAmount}
                                </b>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    ))
                  ) : (
                    <>------No Data Found------</>
                  )}
                </div>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
                // onClick={exportExcelClaim}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
              <span className="user-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </span>
            </div>

            {/* PAGE CONTENT END*/}

            {/* MAIN BODY END */}

            {claimAttachmentmodel ? (
              <ViewClaimAttachmentmodel
                claimAttachmentmodel={claimAttachmentmodel}
                handleCloseviewattachment={handleCloseviewattachment}
                attachmentUrl={attachmentUrl}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ApprovedClaimbyManager;
