import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Tracker.scss";
import profileimg from "../../images/profile-1.png";
import NoDataImg from "../../images/no-data-found.gif";
import { useParams, useNavigate } from "react-router-dom";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import GoogleMapComp from "../GoogleMapComp";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";

function TrackByUser() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const { id } = useParams();
  let navigate = useNavigate();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [date, setDate] = useState(new Date());
  const [dateTime, setDateTime] = useState("");
  const [trackUserDetails, setTrackUserDetails] = useState([]);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const currentTimeNow = `${new Date().toTimeString().slice(0, 5)}`;
  const [time, setTime] = useState(currentTimeNow);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  /* TIME 24HRS TO  12 HRS START */
  useEffect(() => {
    const currentTime = time.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = time.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setDateTime("0" + exactTime + " " + "PM");
      } else {
        let str = time.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setDateTime(exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      setDateTime(time + " " + "PM");
    } else if (currentTime === 0) {
      let str = time.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      setDateTime(exactTime + " " + "AM");
    } else {
      setDateTime(time + " " + "AM");
    }
  }, [time]);
  /* TIME 24HRS TO  12 HRS END */

  /* FETCH TRACK USER DATA START */
  useEffect(() => {
    {
      id && date && fetchTrackUser();
    }
  }, [id, date, dateTime]);

  const fetchTrackUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetCordsUserID?Date=${moment(date).format(
        "DD/MM/YYYY"
      )} ${dateTime}&UserID=${id}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setTrackUserDetails(res.result[0]);
          setLatLan({
            lat: parseFloat(res.result[0]?.cordLoc?.latitude),
            lng: parseFloat(res.result[0]?.cordLoc?.longitude),
          });
        } else {
          setTrackUserDetails(res.message);
          setLatLan({ lat: null, lng: null });
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };
  /* FETCH TRACK USER DATA START */

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* TRACKER TABLE START */}
                    <div className="tracker-table">
                      <span className="seleting-menus">
                        <span className="page-title">TRACK BY USER</span>

                        {/* <TimePicker
                  onChange={setTime}
                  value={time}
                  className="time-select"
                /> */}

                        <button
                          type="button"
                          className="btn btn-go-back"
                          onClick={navigateToDashboard}
                        >
                          <span className="btn-inline-item d-flex">
                            <i className="fa fa-arrow-left"> </i>
                            <h6>Back</h6>
                          </span>
                        </button>
                      </span>
                      <div className="table-responsive tracker-tabel-scroll">
                        <table className="table trackers-list-table">
                          <thead className="thead-light trackers-list-thead">
                            <tr>
                              <th>NAME</th>
                              <th>MOBILE</th>
                              <th>LOCATION</th>
                              <th>DATE</th>
                              <th>WHEN</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trackUserDetails !== "" &&
                              id !== "" &&
                              date !== "" ? (
                              <tr className="track-list-row-data">
                                <td>
                                  <span className="track-list-name d-flex">
                                    <img
                                      className="table-data-track-imgages"
                                      // src={`${filterUserId.BaseURL}${filterUserId.profileIcon}`}
                                      src={profileimg}
                                      alt="John Doe"
                                    />
                                    <h6>{trackUserDetails?.fullName}</h6>
                                  </span>
                                </td>
                                <td>{trackUserDetails?.mobile}</td>
                                <td style={{ maxWidth: "200px" }}>
                                  {trackUserDetails?.cordLoc?.location}
                                </td>
                                <td>
                                  {moment(trackUserDetails?.date).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </td>
                                <td>{trackUserDetails?.minsHrsAgo}</td>
                              </tr>
                            ) : (
                              <h6 className="no-data-found">
                                ------ NO DATA FOUND ------
                              </h6>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* TRACKER TABLE END */}
                    <div className="tracker-page-google-map">
                      <div className="google-map-box">
                        {trackUserDetails !== "" && id !== "" && date !== "" ? (
                          <GoogleMapComp
                            className="google-component"
                            pageType="Tracker"
                            latLan={latLan}
                            trackUserDetails={trackUserDetails}
                          />
                        ) : (
                          <>
                            <img
                              src={NoDataImg}
                              className="mCS_img_loaded"
                              style={{ width: "98%" }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default TrackByUser;
