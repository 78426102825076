import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import { Form } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import Select from "react-select";
import AlertModel from "../AlertModel";
import { optionsLeaveStatusUpdate } from "../DropdownsData";

function Leaveupdatemodel(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [status, setStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  // const closeAlertModel = () => {
  //   setShowAlert(!showAlert);
  // };

  // UPDATE LEAVE STATUS START
  const updateLeaveStatus = () => {
    const leaveStatus = {
      leaveID: props.leaveId,
      status: status,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(leaveStatus),
    };
    fetch(`${baseURL}/api/Leave/UpdateLeaveStatus`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShowAlert(true);
          setAlertMessage({
            alertType: "success",
            message: res.message,
          });
          setTimeout(() => {
            props.handleClose();
            props.fetchLeaveDetails();
          }, 1000);
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: res.errorMessage,
          });
        }
      });
  };
  // UPDATE LEAVE STATUS END

  return (
    <div>
      <Modal
        show={props.leaveUpdatestatus}
        // onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="update-leavesta-head">UPDATE STATUS</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="row update-leave-status">
              <div className="col-md-12">
                <div className="select-status mb-2 d-flex">
                  <span className="col-md-3">
                    <h6>Status</h6>
                  </span>
                  <span className="col-md-9">
                    <Select
                      className="select-leave-update-status"
                      options={optionsLeaveStatusUpdate}
                      placeholder="Select Status"
                      value={optionsLeaveStatusUpdate.value}
                      onInputChange={optionsLeaveStatusUpdate.label}
                      onChange={(data) => setStatus(data.value)}
                      isSearchable={true}
                    />
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <div className="lead-save-button">
            <button
              onClick={updateLeaveStatus}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="lead-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
          // closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default Leaveupdatemodel;
