import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Activities.scss";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import AndroidPhone from "../../images/android-mobile.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { travelizeAuth } from "./HeaderAuthentication";
import { exportExcel } from "../DownloadFile";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";

function Activities() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userList, setUserList] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState("");
  const [date, setDate] = useState(new Date());
  const [activitiesByUser, setActivitiesByUser] = useState([]);
  const [filterUserId, setFilterUserId] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  let navigate = useNavigate();

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  //* FETCH SELECT DROPDOWN START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH SELECT DROPDOWN END *//

  //* FETCH DEVICE INFO START *//
  useEffect(() => {
    fetchDeviceInfo();
  }, [filterUserId]);

  const fetchDeviceInfo = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(`${baseURL}/api/User/GetDeviceInfo/${filterUserId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        setDeviceInfo(res.result);
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DEVICE INFO END *//

  //* FETCH ACTIVITIES START *//
  useEffect(() => {
    fetchActivitiesByNetwork();
  }, [filterUserId, date, PageSize, currentPage]);

  const fetchActivitiesByNetwork = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetActivities?userId=${filterUserId}&Date=${moment(
        date
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setActivitiesByUser(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
        } else {
          setActivitiesByUser("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ACTIVITIES END *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setDate(new Date());
    setPageSize(20);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelActiivity = () => {
    exportExcel(
      `${baseURL}/api/Export/ActivtyExcelExport?UserID=${filterUserId}&Date=${moment(
        date
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Activites.xlsx"
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* ACTIVITY TABLE START */}
                    <div className="user-activity-n-device-table">
                      <div className="seleting-menus">
                        <div className="page-title">ACTIVITIES</div>
                        <div className="page-top-bar-dropdowns">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userList}
                            placeholder="Select User"
                            value={userList.value}
                            onInputChange={userList.label}
                            onChange={(data) => setFilterUserId(data.value)}
                            isSearchable={true}
                            // isClearable={true}
                          />

                          <div className="date-picker-with-icon">
                            <DatePicker
                              selected={date}
                              className="select single-date"
                              dateFormat="dd/MM/yyyy"
                              // selectsEnd
                              // startDate={date}
                              // endDate={toDate}
                              // minDate={fromDate}
                              onChange={(date) => setDate(date)}
                              maxDate={new Date()}
                            />
                            <i className="fa fa-calendar-o calander-icon" />
                          </div>

                          <button
                            type="button"
                            className="btn btn-search-track"
                            onClick={clearSerachBySelect}
                            style={{ marginLeft: "15px" }}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-eraser"> </i>
                              <h6>Clear</h6>
                            </span>
                          </button>

                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            // defaultValue={optionsRoleList[0].label}
                            options={optionsPageSizeList}
                            placeholder="Show Per Page"
                            value={optionsPageSizeList.value}
                            onInputChange={optionsPageSizeList.label}
                            onChange={(data) => setPageSize(data.value)}
                            isSearchable={true}
                            // isClearable={true}
                          />
                          <button
                            type="button"
                            className="btn btn-go-back"
                            onClick={navigateToDashboard}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-arrow-left"> </i>
                              <h6>Back</h6>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* ACTIVITY TABLE END */}
                    <div className="row user-activity-location-device">
                      <div className="col-md-3 device-info-part">
                        <div className="device-info-head">
                          <h6 className="device-info-heading">DEVICE INFO </h6>
                        </div>

                        <div className="mobile-device-img-part">
                          <img
                            className="android-mobile-img"
                            src={AndroidPhone}
                            alt="android-mobile"
                          />
                        </div>
                        <div className="device-info-table">
                          <table className="table ">
                            <tbody className="device-info-table-data">
                              <tr>
                                <th scope="row">Device</th>
                                <td>{deviceInfo?.name}</td>
                              </tr>
                              <tr>
                                <th scope="row">Model</th>
                                <td>{deviceInfo?.modal ?? "NA"}</td>
                              </tr>
                              <tr>
                                <th scope="row">OS</th>
                                <td>{deviceInfo?.os}</td>
                              </tr>
                              <tr>
                                <th scope="row">Version</th>
                                <td>{deviceInfo?.osVersion}</td>
                              </tr>
                              <tr>
                                <th scope="row">RAM</th>
                                <td>{deviceInfo?.ram}</td>
                              </tr>
                              <tr>
                                <th scope="row">Battery Tech</th>
                                <td>{deviceInfo?.batteryTech}</td>
                              </tr>
                              <tr>
                                <th scope="row">Battery Power</th>
                                <td>{deviceInfo?.batteryPower}</td>
                              </tr>
                              <tr>
                                <th scope="row">Battery Health</th>
                                <td>{deviceInfo?.batteryHealth}</td>
                              </tr>
                              <tr>
                                <th scope="row">Rooted</th>
                                <td>{deviceInfo?.rooted}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-9 user-activity-details-part">
                        <div className="user-activity-details user-activity-data-scroll">
                          <table className="table user-activity-details-table ">
                            <thead className="thead-light user-activity-thead">
                              <tr>
                                <th>TIME</th>
                                <th>LOCATION</th>
                                <th>BATTERY</th>
                                <th>SIGNAL</th>
                                <th>MOCK</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activitiesByUser !== "" && activitiesByUser ? (
                                activitiesByUser.map((data, i) => (
                                  <tr
                                    className="user-activity-row-data"
                                    key={i}
                                  >
                                    <td>{data.onlyTime}</td>
                                    <td style={{ maxWidth: "200px" }}>
                                      {data.cordLoc.location}
                                    </td>
                                    <td>
                                      {data.batteryStrength} |{" "}
                                      {data.batteryTemp}
                                    </td>
                                    <td>
                                      {data.mobileNetwork} | {data.networkType}
                                    </td>

                                    <td>
                                      {data.mockLocation} | &nbsp;
                                      <i className="fa fa-male" />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>------- NO DATA FOUND -------</tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="download-and-pagination">
                      <div className="data-count-per-page">
                        {pageDataFrom} - {pageDataTill} of {totalData}
                      </div>
                      <button
                        type="button"
                        className="btn btn-download-excel"
                        onClick={exportExcelActiivity}
                      >
                        <i className="fa fa-download" /> &nbsp; Download
                      </button>
                      <span className="user-pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fa fa-angle-double-left" />
                          }
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />
                      </span>
                    </div>

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Activities;
