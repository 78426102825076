import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import AccessDenied from "../../AccessDenied";

function KilometerRefreshPage() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  //   const [meetingdetails, setMeetingdetails] = useState(false);
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [manager, setManager] = useState("");
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [meetingsData, setMeetingsData] = useState([]);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [selectAllCheck, setSelectAllCheck] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  let subscriptionId = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [userID, setUserID] = useState("");
  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
      // body: JSON.stringify(userNamePassword),
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
    fetch(`${baseURL}/api/User/BindManagerDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("Manager-List::", res.result);
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      });
  };

  useEffect(() => {
    getMeetings();
  }, [PageSize, currentPage, filterUserId, fromDate, toDate, subscriptionId]);

  // GET MEETINGS PAGE START
  const getMeetings = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/SupportTeam/GetAllMeetingWith0KM?SubscriptionID=${subscriptionId}&PageSize=${PageSize}&CurrentPage=${currentPage}&UserId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format("DD/MM/YYYY")}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("MEETINGS-REFRESH-PAGE::", res.result);
        if (res.success) {
          setMeetingsData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          res.result.forEach((element) => {
            element.inputState = {
              chkSelectRefresh: false,
              meetingID: "String",
              startLocLatitude: 0,
              startLocLongitude: 0,
              startLocLocation: 0,
              endLocLocLatitude: 0,
              endLocLocLongitude: 0,
              endLocLocLocation: 0,
            };
          });
        } else {
          setMeetingsData("");
        }
        setSelectAllCheck(false);
      });
  };
  // GET MEETINGS PAGE END

  const setMeetingIdforRefresh = (state, meetingID) => {
    if (meetingID !== 0) {
      let refreshKm = meetingsData.filter((x) => x.meetingID == meetingID);
      console.log("REFRESH-KM***", refreshKm);
      if (refreshKm.length > 0) {
        refreshKm[0].inputState.chkSelectRefresh = state;
        refreshKm[0].inputState.meetingID = refreshKm[0].meetingID;
        refreshKm[0].inputState.startLocLatitude =
          refreshKm[0].startLoc.latitude;
        refreshKm[0].inputState.startLocLongitude =
          refreshKm[0].startLoc.longitude;
        refreshKm[0].inputState.startLocLocation =
          refreshKm[0].startLoc.location;
        refreshKm[0].inputState.endLocLocLatitude =
          refreshKm[0].endLoc.latitude;
        refreshKm[0].inputState.endLocLocLongitude =
          refreshKm[0].endLoc.longitude;
        refreshKm[0].inputState.endLocLocLocation =
          refreshKm[0].endLoc.location;
      }
      setSelectAllCheck(false);
    } else {
      meetingsData.forEach((data) => {
        data.inputState.chkSelectRefresh = state;
        data.inputState.meetingID = data.meetingID;
        data.inputState.startLocLatitude = data.startLoc.latitude;
        data.inputState.startLocLongitude = data.startLoc.longitude;
        data.inputState.startLocLocation = data.startLoc.location;
        data.inputState.endLocLocLatitude = data.endLoc.latitude;
        data.inputState.endLocLocLongitude = data.endLoc.longitude;
        data.inputState.endLocLocLocation = data.endLoc.location;
      });
    }
    setMeetingsData([...meetingsData]);
  };

  const selectAllStatus = (event) => {
    if (meetingsData.map((data) => data.inputState.chkSelectRefresh == event)) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  };

  const postRefreshKiloMeters = () => {
    let singleRefreshData = meetingsData.filter(
      (data) => data.inputState.chkSelectRefresh == true
    );
    console.log("SINGLE-REFRESH*****", singleRefreshData);
    if (singleRefreshData.length == 1) {
      let refreshData = [
        {
          meetingID: singleRefreshData[0].inputState.meetingID,
          attendanceID: "",
          startLoc: {
            latitude: singleRefreshData[0].inputState.startLocLatitude,
            longitude: singleRefreshData[0].inputState.startLocLongitude,
            location: singleRefreshData[0].inputState.startLocLocation,
          },
          endLoc: {
            latitude: singleRefreshData[0].inputState.endLocLocLatitude,
            longitude: singleRefreshData[0].inputState.endLocLocLongitude,
            location: singleRefreshData[0].inputState.endLocLocLocation,
          },
        },
      ];

      // console.log("REFRESH-DATA==1:", refreshData);

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(refreshData),
      };
      fetch(
        `${baseURL}/api/SupportTeam/UpdateKmMeetingsAttandance`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("POST-REFRESH-KM::", res);
          if (res.success) {
            alert(res.message);
          } else {
            alert(res.errorMessage);
          }
        });
    } else {
      let refreshData = [];
      for (let i = 0; i < singleRefreshData.length; i++) {
        refreshData.push({
          meetingID: singleRefreshData[i].inputState.meetingID,
          attendanceID: "",
          startLoc: {
            latitude: singleRefreshData[i].inputState.startLocLatitude,
            longitude: singleRefreshData[i].inputState.startLocLongitude,
            location: singleRefreshData[i].inputState.startLocLocation,
          },
          endLoc: {
            latitude: singleRefreshData[i].inputState.endLocLocLatitude,
            longitude: singleRefreshData[i].inputState.endLocLocLongitude,
            location: singleRefreshData[i].inputState.endLocLocLocation,
          },
        });
      }
      // console.log("REFRESH-DATA > 1:", refreshData);

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(refreshData),
      };
      fetch(
        `${baseURL}/api/SupportTeam/UpdateKmMeetingsAttandance`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("POST-REFRESH-KM::", res);
          if (res.success) {
            alert(res.message);
          } else {
            alert(res.errorMessage);
          }
        });
    }
  };

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManager("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="meetings-table">
                  <div className="seleting-menus">
                    <div className="page-title"> KM REFRESH (MEETINGS)</div>
                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={userList}
                        placeholder="Select User"
                        value={userList.value}
                        onInputChange={userList.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                        // isClearable={true}
                      />

                      <div className="input-group from-to-date-select">
                        <span className="input-group-text" id="from-label">
                          From
                        </span>
                        <span className="date-picker-with-icon">
                          <DatePicker
                            selected={fromDate}
                            className="select from-date"
                            id="from-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            // startDate={fromDate}
                            endDate={toDate}
                            onChange={(date) => setFromDate(date)}
                            maxDate={new Date()}
                          />
                          <i className="fa fa-calendar-o calander-icon" />
                        </span>

                        <span className="input-group-text" id="to-label">
                          To
                        </span>
                        <span className="date-picker-with-icon">
                          <DatePicker
                            selected={toDate}
                            className="select to-date"
                            id="from-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            // startDate={fromDate}
                            endDate={toDate}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                            maxDate={new Date()}
                          />
                          <i className="fa fa-calendar-o calander-icon" />
                        </span>
                      </div>

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={postRefreshKiloMeters}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-refresh"> </i>
                          <h6>Refresh</h6>
                        </span>
                      </button>

                      <Select
                        className="react-select-container-list per-page"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                        // isClearable={true}
                      />
                    </div>
                  </div>

                  <div className="table-responsive meetings-scrollbar">
                    <table className="table meetings-list-table">
                      <thead className="thead-light meetings-list-thead">
                        <tr>
                          <th>
                            <span className="select-all-check-box-text">
                              <input
                                className="select-all-checkbox"
                                type="checkbox"
                                value=""
                                id="0"
                                onClick={(e) => {
                                  setMeetingIdforRefresh(e.target.checked, 0);
                                  selectAllStatus(e.target.checked);
                                }}
                                checked={selectAllCheck}
                              />
                              <span>Select All </span>
                              <span style={{ paddingLeft: "15px" }}>NAME </span>
                            </span>
                          </th>
                          <th>MOBILE</th>
                          <th>DATE</th>
                          <th>FROM</th>
                          <th>TO</th>
                          <th>DISTANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {meetingsData != "" ? (
                          meetingsData.map((data, i) => (
                            <tr className="meeting-list-row-data" key={i}>
                              <td>
                                <span className="meeting-list-name d-flex">
                                  <input
                                    className="select-single-checkbox"
                                    type="checkbox"
                                    value=""
                                    id={data?.meetingID}
                                    onClick={(e) => {
                                      setMeetingIdforRefresh(
                                        e.target.checked,
                                        data?.meetingID
                                      );
                                    }}
                                    checked={data?.inputState?.chkSelectRefresh}
                                  />

                                  <h6>{data?.fullName}</h6>
                                </span>
                              </td>
                              <td>{data?.contact}</td>
                              <td>{data?.meetingDate}</td>
                              <td style={{ maxWidth: "200px" }}>
                                {data?.startLoc?.location}
                                <span className="client-meet-location">
                                  <p className="client-meet-lat">
                                    <i className="bi bi-geo-alt" />
                                    {data?.startLoc?.latitude},
                                    {data?.startLoc?.longitude}
                                  </p>
                                </span>
                              </td>
                              <td style={{ maxWidth: "200px" }}>
                                {data?.endLoc?.location}
                                <span className="client-meet-location">
                                  <p className="client-meet-lat">
                                    <i className="bi bi-geo-alt" />
                                    {data?.endLoc?.latitude},
                                    {data?.endLoc?.longitude}
                                  </p>
                                </span>
                              </td>
                              <td>{data?.distanceTravelled}</td>
                            </tr>
                          ))
                        ) : (
                          <h6 className="no-data-found">
                            ------ NO DATA FOUND ------
                          </h6>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default KilometerRefreshPage;
