import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Spares.scss";
import productimg1 from "../../images/products/battery1.png";
import productimg2 from "../../images/products/SamsungBattery.png";
import productimg3 from "../../images/products/samsungbattery1.png";

function EditSpare(props) {
  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [imgFile, setImgFile] = useState("");

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <Modal
        show={props.editproductmodel}
        onHide={props.handleClose}
        className="main_model add-edit-spare-model"
      >
        <div className="add-edit-spare-heading">
          <h6 className="add-edit-spare-head">EDIT SPARE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-spare">
            <div className="spare-inputs">
              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-spare-input"
                  placeholder="Spare Name"
                // readOnly={true}
                // value={fullname}
                // onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-spare-input"
                  placeholder="Spare Code"
                // readOnly={true}
                // value={fullname}
                // onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-spare-input"
                  placeholder="Product Name"
                // readOnly={true}
                // value={fullname}
                // onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-spare-input"
                  placeholder="Warranty"
                // readOnly={true}
                // value={fullname}
                // onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className="mb-2 d-flex">
                <div style={{ marginRight: "10px" }}>
                  <input
                    type="number"
                    className="form-control form add-spare-input"
                    placeholder="Stocks"
                  // readOnly={true}
                  // value={fullname}
                  // onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
                <div className="ml-1">
                  <input
                    type="text"
                    className="form-control form add-spare-input"
                    placeholder="Dimension"
                  // readOnly={true}
                  // value={fullname}
                  // onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-2 d-flex">
                <div style={{ marginRight: "10px" }}>
                  <input
                    type="text"
                    className="form-control form add-spare-input"
                    placeholder="Weight"
                  // readOnly={true}
                  // value={fullname}
                  // onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
                <div className="ml-1">
                  <input
                    type="number"
                    className="form-control form add-spare-input"
                    placeholder="MRP"
                  // readOnly={true}
                  // value={fullname}
                  // onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-2">
                <textarea
                  rows="3"
                  cols="20"
                  name="usrtxt"
                  wrap="hard"
                  className="form-control form add-spare-input"
                  id="descriptionArea"
                  placeholder="Description"
                  required
                // value={location}
                // onChange={(e) => setLocation(e)}
                />
              </div>

              <div className="mb-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-add-spare-img"
                    onClick={uploadClick}
                  >
                    Upload Product Images
                  </button>
                  <input
                    id="getFile"
                    type="file"
                    name="filetobase64"
                    accept="image/*"
                    className="btn-primary upload"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="d-flex">
                  <div className="spare-image-box">
                    <img
                      className="spare-images"
                      src={localImage ? localImage : productimg1}
                      alt="Spare Images"
                    />
                  </div>
                  <div className="spare-image-box">
                    <img
                      className="spare-images"
                      src={localImage ? localImage : productimg2}
                      alt="Spare Images"
                    />
                  </div>
                  <div className="spare-image-box">
                    <img
                      className="spare-images"
                      src={localImage ? localImage : productimg3}
                      alt="Spare Images"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="spares-save-button">
            <button
              //   onClick={postinvoicedata}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Update
            </button>
          </div>
          <div className="spare-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditSpare;
