import React from "react";
import { Modal, Image } from "react-bootstrap";
import "../../styles/AddmeetingModel.scss";
import viewattachment from "../../images/No-Image.jpg";
import { baseURL } from "../BaseUrl";

function ViewClaimAttachmentmodel(props) {
  // console.log("ATTACHMENT-URL:::", props.attachmentUrl);
  return (
    <div>
      <div className="model-view-claim-attachment">
        <Modal
          show={props.claimAttachmentmodel}
          onHide={props.handleCloseviewattachment}
          className="view-attachment-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">VIEW ATTACHMENT</h6>
            <i
              className="fa fa-times"
              onClick={props.handleCloseviewattachment}
            ></i>
          </div>
          <div className="meeting-attachment-image">
            <Image
              src={
                props.attachmentUrl !== ""
                  ? `${baseURL}/${props.attachmentUrl}`
                  : viewattachment
              }
              alt="attachment-photo"
              className="img-thumbnail"
            />
          </div>

          <div className="attach-print-button">
            <button
              onClick={props.handleCloseviewattachment}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              <p>Close</p>
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ViewClaimAttachmentmodel;
