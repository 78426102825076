import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/MeetingPurpose.scss";
import { Form } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import AlertModel from "../../AlertModel";

function EditPurposeModel(props) {
  const [enableStatus, setEnableStatus] = useState(null);
  const [meetPurpose, setMeetPurpose] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const purposeID = props.purposeId;

  useEffect(() => {
    fetchByEditpurposeId();
  }, [purposeID]);

  const fetchByEditpurposeId = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/MeetingPurpose/Get/${purposeID}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        console.log("EDIT-BY-ID-MEET-PURPOSE::", res);
        setMeetPurpose(res.result.purpose);
        setEnableStatus(res.result.status);
      });
  };

  //  EDIT MEETING PURPOSE START
  const editMeetingPurpose = () => {
    const purposeData = {
      purpose: meetPurpose,
      purposeId: purposeID,
      status: JSON.parse(enableStatus),
    };
    console.log("EDIT-PURPOSE_DATA:::::", purposeData);
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(purposeData),
    };
    fetch(`${baseURL}/api/MeetingPurpose/EditPurpose`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        console.log("EDIT-MEET-PURPOSE::", res);
        if (res.success == true) {
          setShowAlert(true);
          setAlertMessage({
            alertType: "success",
            message: res.message,
          });
        } else {
          setShowAlert(true);
          setAlertMessage({
            alertType: "fails",
            message: res.errorMessage,
          });
        }
        props.fetchMeetingPurposeData();
      });
    setTimeout(() => {
      props.handleClose();
    }, 3000);
  };
  //  EDIT MEETING PURPOSE START END

  return (
    <div>
      <Modal
        show={props.editpurposemodel}
        onHide={props.handleClose}
        className="main_model add-meet-purpose-model"
      >
        <div className="add-meet-purpose-heading">
          <h6 className="add-edit-meetpurpose-head">EDIT PURPOSE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-meetpurpose">
            <div className="company-name">
              <div className="mb-2">
                <input
                  type="text"
                  className="form-control form add-meetpurpose-input"
                  id="exampleFormControlInput1"
                  placeholder="Company Name"
                  value={meetPurpose}
                  onChange={(e) => setMeetPurpose(e.target.value)}
                />
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => {
                          setEnableStatus(1);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => {
                          setEnableStatus(0);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="meetpurpose-save-button">
            <button
              onClick={editMeetingPurpose}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Update
            </button>
          </div>
          <div className="meetpurpose-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default EditPurposeModel;
