import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddUserModel.scss";
import profileimg from "../../images/profile-1.png";
import Multiselect from "multiselect-react-dropdown";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth, salesbeeImgAuth } from "./HeaderAuthentication";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
import Geocode from "react-geocode";
import { React_Google_API_Key } from "../../GoogleApiKey";
// import { LoadScript } from "@react-google-maps/api";
import Select from "react-select";
import { optionsRoleListModel, optionsMapByListModel } from "../DropdownsData";
import AlertModel from "../AlertModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InternetIssue from "../InternetIssue";
Geocode.setApiKey(React_Google_API_Key);

function Addusermodel(props) {
  /* HEADER AUTH START */
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  const salesbeeImgAuth = { Travelize_Authentication: userDetails };
  /* HEADER AUTH END */

  const [fullname, setFullname] = useState("");
  const [usercode, setUserCode] = useState("");
  const [emailaddress, setEmailAddress] = useState("");
  const [mobilenum, setMobileNum] = useState("");
  const [location, setLocation] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [manager, setManager] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [imgDirectory, setImgDirectory] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [country, setCountry] = useState("");
  const [localImage, setLocalImage] = useState("");
  const [role, setRole] = useState(0);
  // const [shift, setShift] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [zone, setZone] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [zoneIds, setZoneIds] = useState([]);
  const [stateIds, setStateIds] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const [departmentId, setDepartmentId] = useState(0);
  const [address, setAddress] = useState(null);
  const [mapById, setMapById] = useState(3);
  const [userRegionResult, setUserRegionResult] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [fieldReq, setFieldReq] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);

  /*  GET LIVE LOCATION BY SYSTEM START  */
  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported for your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLan({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };
  /*  GET LIVE LOCATION BY SYSTEM END  */

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
    setAddress("");
  };

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, [reportDesignation]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Zone/GetUserRegionsResult`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // let regionResult = res.result;
          setUserRegionResult(res.result);
          setCountryCodeList(
            res.result?.allCountryCode.map((data) => {
              return { value: data?.code, label: data?.code };
            })
          );
          setCountryList(
            res.result?.allCountry.map((data) => {
              return {
                value: data.countryID,
                label: data.countryName,
              };
            })
          );
          setZoneList(res.result?.allZones);
          setShiftList(res.result?.allShift);
        } else {
          setUserRegionResult("");
          setCountryCodeList([]);
          setZoneList([]);
          setShiftList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return { value: data.departmentID, label: data.departmentName };
            })
          );
        } else {
          setDepartmentList([]);
        }
      });
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    fetchDropdownByZone();
  }, [zoneIds, stateIds]);

  const fetchDropdownByZone = () => {
    let stateResponse = userRegionResult?.allStates;
    setStateList(
      stateResponse
        ?.filter((z) => zoneIds.includes(z.zoneId))
        ?.map((item) => item) ?? []
    );
    let cityResponse = userRegionResult?.allCities;
    setCityList(
      cityResponse
        ?.filter((s) => stateIds.includes(s.stateId))
        ?.map((item) => item) ?? []
    );
  };
  //* FETCH DROPDOWN DATA END *//

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  //*  ADDING PROFILE IMAGE BY USER START *//
  const addProfileImg = (e) => {
    e.preventDefault();
    if (imgFile !== null && imgFile !== "") {
      const formData = new FormData();
      formData.append("image", imgFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Upload/UploadProfile?directory=Profile`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("IMAGE-USER-RES::", res);
          if (res.success) {
            var imgUrl = res.result[0];
            setShowAlert(true);
            setAlertMessage({
              alertType: "success",
              message: `Profile ${res.message}`,
            });
          } else {
            setLocalImage("");
            setShowAlert(true);
            setAlertMessage({
              alertType: "fail",
              message: `Profile ${res.errorMessage}`,
            });
          }
          addUser(imgUrl);
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    } else {
      addUser();
    }
  };
  //*  ADDING PROFILE IMAGE BY USER END  *//

  //*  ADDING USER START  *//
  const addUser = (imgUrl) => {
    if (
      countryCode !== "" &&
      mobilenum.length == 10 &&
      role !== "" &&
      location !== "" &&
      country !== "" &&
      stateIds !== "" &&
      stateIds.length > 0 &&
      zoneIds !== "" &&
      zoneIds.length > 0
    ) {
      const addUserDetails = {
        desingation: {
          designationID: reportDesignation,
        },
        fullName: fullname,
        email: emailaddress,
        profilePic: imgUrl,
        countryCode: countryCode,
        contact: JSON.parse(mobilenum),
        code: usercode,
        activeStatus: 1,
        enableStatus: enableStatus,
        allowMeetings: "YES",
        managerId: manager,
        shiftID: 1,
        mapType: mapById,
        baseLoc: {
          latitude: JSON.stringify(latLan.lat),
          longitude: JSON.stringify(latLan.lng),
          location: location,
        },
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(addUserDetails),
      };

      fetch(`${baseURL}/api/User/AddUser`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          console.log("ADD-USER-RES::", res);
          if (res.success) {
            let resUserId = res.result.replace("Success|", "");
            addZoneStateCity(resUserId);
            setShowAlert(true);
            setAlertMessage({ alertType: "success", message: res.message });
            setTimeout(() => {
              props.handleClose();
            }, 2000);
            props.getUserDetails();
          } else {
            setShowAlert(true);
            setAlertMessage({ alertType: "fails", message: res.message });
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };
  //*  ADDING USER END  *//

  //  ADDING ZONE STATE AND CITY BY USER START
  const addZoneStateCity = (resUserId) => {
    const zoneStateCity = city.map((item) => {
      return {
        ...item,
        userId: resUserId,
      };
    });
    state.forEach((s_state) => {
      if (
        zoneStateCity.filter((zsc) => zsc.stateId == s_state.stateId).length ==
        0
      ) {
        zoneStateCity.push({ ...s_state, userId: resUserId });
      }
    });
    zone.forEach((z_Zone) => {
      if (
        zoneStateCity.filter((zsc) => zsc.zoneId == z_Zone.zoneId).length == 0
      ) {
        zoneStateCity.push({ ...z_Zone, userId: resUserId });
      }
    });

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(zoneStateCity),
    };
    fetch(`${baseURL}/api/Zone/AddUserRegions`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        console.log("ZONEADDED-RES::", res);
      });
  };
  //  ADDING ZONE STATE AND CITY BY USER END

  // MULTI SELECT DROPDOWN FUNCTION START
  const handleSelectZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleRemoveZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleSelectState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleRemoveState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleSelectCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  const handleRemoveCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  // MULTI SELECT DROPDOWN FUNCTION END

  // VALIDATIONS START
  const checkValidation = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNum(e.target.value);
    } else {
      setShowAlert(true);
      setAlertMessage({ alertType: "fails", message: "Accept only numbers" });
    }
  };
  // VALIDATIONS END

  // GOOGLE MAPS LOCATION START
  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          // console.log("ADDRESS-RESPONSE:::", response.results[0]);
          // setAddress(response.results[0].formatted_address);
          setLatLan({
            lat: lat,
            lng: lng,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan) {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log("GOOGLE-ADDRESS-PLACEHOLDER:", address);
          setAddress(response.results[0].formatted_address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  const addressFromGoogle = () => {
    openGoogleMaps();
  };
  // GOOGLE MAPS LOCATION END

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <Modal
            show={props.addusermodelshow}
            // onHide={props.handleClose}
            className="main_model"
          >
            <div className="add-user-heading">
              <h6 className="add-user-head">ADD USER</h6>
              <i
                className="fa fa-times"
                onClick={() => {
                  props.handleClose();
                  setLocation("");
                }}
              />
            </div>
            <form onSubmit={addProfileImg}>
              <div className="container model-add-user-body">
                <div className="row add-user">
                  <div className="col-md-5">
                    <div className="img-box">
                      <div className="add_user-image">
                        <img
                          className="add-user-image"
                          src={localImage ? localImage : profileimg}
                          alt="Profile Image"
                        />
                        <button
                          type="button"
                          className="btn btn-add-user-img"
                          onClick={uploadClick}
                        >
                          <span className="btn-inline-items">
                            <i className="fa fa-user-plus"> </i>
                            <h6>Add Profile Picture</h6>
                          </span>
                        </button>
                        <input
                          id="getFile"
                          type="file"
                          name="filetobase64"
                          accept="image/*"
                          className="btn-primary upload"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                    <div className="img-file-name">{imgFileName}</div>
                  </div>

                  <div className="col-md-7">
                    <div className="mb-2">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control add-user-input"
                        id="exampleFormControlInput1"
                        placeholder="Full Name"
                        // value={fullname}
                        onChange={(e) => {
                          setFullname(e.target.value);
                          setImgDirectory(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control add-user-input"
                        id="exampleFormControlInput1"
                        placeholder="User Code"
                        // value={usercode}required
                        onChange={(e) => setUserCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        type="email"
                        autoComplete="off"
                        className="form-control add-user-input"
                        id="exampleFormControlInput1"
                        placeholder="E-Mail Address"
                        // value={emailaddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                    </div>
                    <span className="input-mobile-field d-flex mb-2">
                      <div className="country-code">
                        <Select
                          className="react-select-container-list code-select"
                          classNamePrefix="react-select-list"
                          defaultValue={countryCodeList[3]}
                          options={countryCodeList}
                          placeholder="Code"
                          value={countryCodeList.value}
                          onInputChange={countryCodeList.label}
                          onChange={(data) => setCountryCode(data.value)}
                          isSearchable={true}
                          required
                        />
                      </div>

                      <div className="mobile-num">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control add-user-input"
                          id="exampleFormControlInput1"
                          placeholder="Mobile"
                          maxLength={10}
                          onChange={(e) => checkValidation(e)}
                          required
                        />
                      </div>
                      {fieldReq && mobilenum.length !== 10 ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message} - 10 Numbers`}
                        </small>
                      ) : (
                        ""
                      )}
                    </span>

                    <div className="mb-2">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        defaultValue={designationList[null]}
                        options={designationList}
                        placeholder="Designation"
                        value={designationList.value}
                        onInputChange={designationList.label}
                        onChange={(data) => setReportDesignation(data.value)}
                        isSearchable={true}
                      />

                      {(fieldReq && reportDesignation === "") ||
                        (fieldReq && reportDesignation === 0) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* <div className="mb-2">
                      <Select
                        className="select-add-user-model"
                        defaultValue={roleList[null]}
                        options={roleList}
                        placeholder="Role"
                        value={roleList.value}
                        onInputChange={roleList.label}
                        onChange={(data) => setRole(data.value)}
                        isSearchable={true}
                      />
                    </div> */}

                    {/* <div className="mb-2">
                      <Select
                        className="react-select-container-list model-select"
                        defaultValue={departmentList[null]}
                        options={departmentList}
                        placeholder="Select Department"
                        value={departmentList.value}
                        onInputChange={departmentList.label}
                        onChange={(data) => setDepartmentId(data.value)}
                        isSearchable={true}
                      />
                    </div> */}
                    {/* {role == 3 ? ( */}
                    <div className="mb-2">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={managerList}
                        placeholder="Select Reporting"
                        value={managerList.value}
                        onInputChange={managerList.label}
                        onChange={(data) => setManager(data.value)}
                        isSearchable={true}
                        required
                      />
                    </div>
                    {/* ) : (
                      ""
                    )} */}
                    <div className="mb-2 input-address-location">
                      <textarea
                        rows="3"
                        cols="20"
                        name="usrtxt"
                        wrap="hard"
                        className="form-control form add-user-input"
                        id="exampleFormControlInput1"
                        placeholder="Location (Please click the icon for location)"
                        value={location}
                        readOnly={true}
                        required
                      // onChange={(e) => setLocation(e)}
                      />

                      {fieldReq && location === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={
                          <Tooltip id={`tooltip-googlemap`}>
                            Google Maps
                          </Tooltip>
                        }
                      >
                        <div>
                          <i
                            className="bi bi-geo-alt"
                            onClick={openGoogleMaps}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-2">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={countryList}
                      placeholder="Select Country"
                      value={countryList.value}
                      onInputChange={countryList.label}
                      onChange={(data) => setCountry(data.value)}
                      isSearchable={true}
                      required
                    />
                    {fieldReq && country === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <Multiselect
                      className="select-add-user-model zone"
                      options={zoneList} // Options to display in the dropdown
                      selectedValues={zone} // Preselected value to persist in dropdown
                      onSelect={handleSelectZone} // Function will trigger on select event
                      onRemove={handleRemoveZone} // Function will trigger on remove event
                      showCheckbox={true}
                      displayValue="zoneName" // Property name to display in the dropdown options
                      placeholder={"Select Zone"}
                      emptyRecordMsg="No options available"
                      avoidHighlightFirstOption={true}
                      closeIcon="cancel"
                      showArrow={true}
                      selectionLimit={2}
                      closeOnSelect={true}
                    />
                    {fieldReq && zoneIds.length <= 0 ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-2">
                    <Multiselect
                      className="select-add-user-model"
                      options={stateList} // Options to display in the dropdown
                      selectedValues={state} // Preselected value to persist in dropdown
                      onSelect={handleSelectState} // Function will trigger on select event
                      onRemove={handleRemoveState} // Function will trigger on remove event
                      showCheckbox={true}
                      displayValue="stateName" // Property name to display in the dropdown options
                      placeholder={"Select States"}
                      emptyRecordMsg="No options available"
                      avoidHighlightFirstOption={true}
                      closeIcon="cancel"
                      groupBy="zoneName"
                      showArrow={true}
                    />
                    {fieldReq && stateIds.length <= 0 ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-2">
                    <Multiselect
                      className="select-add-user-model"
                      options={cityList} // Options to display in the dropdown
                      selectedValues={city} // Preselected value to persist in dropdown
                      onSelect={handleSelectCity} // Function will trigger on select event
                      onRemove={handleRemoveCity} // Function will trigger on remove event
                      showCheckbox={true}
                      displayValue="cityName" // Property name to display in the dropdown options
                      placeholder={"Select Cities"}
                      emptyRecordMsg="No options available"
                      avoidHighlightFirstOption={true}
                      closeIcon="cancel"
                      groupBy="stateName"
                      showArrow={true}
                    />
                  </div>
                  {/* <div className="mb-2">
                <Form.Select
                  aria-label="Default select example"
                  name="select-shift"
                  className="form-control select-add-user-model"
                  placeholder="Select Shift"
                  value={shift}
                  onChange={(event) => setShift(event.target.value)}
                >
                  <option className="option-label" value={0}>
                    Select Shift
                  </option>
                  {shiftList.map((data) => (
                    <option value={data.shiftID}> {data.shiftName} </option>
                  ))}
                </Form.Select>
              </div> */}
                  <div className="mb-2">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={optionsMapByListModel[2]}
                      options={optionsMapByListModel}
                      placeholder="Map User By :"
                      value={optionsMapByListModel.value}
                      onInputChange={optionsMapByListModel.label}
                      onChange={(data) => setMapById(data.value)}
                      isSearchable={true}
                    />
                  </div>
                  <div className="mt-3">
                    <span className="enable-status d-flex">
                      <h6>Enable</h6>
                      <span className="enable-radio d-flex">
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={enableStatus == 1 ? true : false}
                            onChange={() => setEnableStatus(1)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={enableStatus == 0 ? true : false}
                            onChange={() => setEnableStatus(0)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            No
                          </label>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="btns-save-cancel">
                <div className="user-save-button">
                  <button
                    // onClick={addProfileImg}
                    type="submit"
                    className="btn btn-save-up"
                  >
                    Save
                  </button>
                </div>
                <div className="user-cancel-button">
                  <button
                    onClick={() => {
                      props.handleClose();
                      setLocation("");
                    }}
                    type="button"
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          {googleMapsModel ? (
            // <LoadScript googleMapsApiKey={React_Google_API_Key}>
            <GoogleMapAddressModel
              googleMapsModel={googleMapsModel}
              addressFromGoogle={addressFromGoogle}
              openGoogleMaps={openGoogleMaps}
              latLan={latLan}
              searchLocation={searchLocation}
              searchVal={searchVal}
              onMarkerDragEnd={onMarkerDragEnd}
              address={address}
            />
          ) : (
            // </LoadScript>
            ""
          )}
          <>
            {showAlert ? (
              <AlertModel
                showAlert={showAlert}
                alertMessage={alertMessage}
                closeAlertModel={closeAlertModel}
              />
            ) : (
              ""
            )}
          </>
        </>
      )}
    </div>
  );
}

export default Addusermodel;
