import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import Sidenavbar from '../Sidenavbar'
import Topbar from '../Topbar';
import '../../styles/subscriptionmodule.css'
import { loadAllSubscriptions, loadMainModules, loadSubscription } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MeetingManagement from './MeetingManagement';
import ProjectManagement from './ProjectManagement';

function SuscriberPage() {

    let dispatch = useDispatch()

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const { allSubcriptions } = useSelector(state => state.data)
    const { mainModules } = useSelector(state => state.data)

    const [menuItems, setMenuItems] = useState([])
    const [submenuAtt, setSubmenuAtt] = useState([])
    const [submenuClm, setSubmenuClm] = useState([])
    const [submenuStg, setSubmenuStg] = useState([])



    useEffect(() => {
        let activities = [];
        let subActAtt = [];
        let subActClm = [];
        let subActStng = [];
        let subscriptionList = [...allSubcriptions]
        if (subscriptionList?.length != 0) {

            subscriptionList?.map((item) => {
                if (item.parentModuleName === null) {
                    activities.push({ name: item.moduleName, subNames: [], status: false })
                }
                else if (item.parentModuleName === "Attendance") {
                    subActAtt.push({ name: item.moduleName, status: false })
                }
                else if (item.parentModuleName === "Claims") {
                    subActClm.push({ name: item.moduleName, status: false })
                }
                else if (item.parentModuleName === "Settings") {
                    subActStng.push({ name: item.moduleName, status: false })
                }
            })


        } else {
            activities.push({ name: "Dashboard", subNames: [] })
        }
        setMenuItems(activities)
        setSubmenuAtt(subActAtt)
        setSubmenuClm(subActClm)
        setSubmenuStg(subActStng)
    }, [allSubcriptions])

    useEffect(() => {
        if (menuItems) {
            // let list = [...menuItems]
            menuItems.map((activities) => {
                if (activities.name === "Attendance") {
                    activities.subNames = submenuAtt
                }
                if (activities.name === "Claims") {
                    activities.subNames = submenuClm
                }
                if (activities.name === "Settings") {
                    activities.subNames = submenuStg
                }
            })
            // setMenuItems(list)
        }
    }, [menuItems, submenuAtt, submenuClm, submenuStg])

    const { subcriptionList } = useSelector(state => state.data);
    // console.log(subcriptionList, "allSubcriptions")

    const [userList, setUserList] = useState([]);
    useEffect(() => {
        if (subcriptionList) {
            let optiondata = []
            subcriptionList.map((data) => {
                if (data.subscriptionId != null) {
                    optiondata.push({
                        value: data.subscriptionId, label: data.subscriptionId, client: data?.company?.companyName
                    });
                }
            })
            setUserList(optiondata)
        }
    }, [subcriptionList])

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const [selectedSubscribe, setSelectedSubscribe] = useState('')
    const setFilterUserId = (selected) => {
        // console.log(selected, "select")
        setSelectedSubscribe(selected)
    }

    useEffect(() => {
        dispatch(loadSubscription())
        dispatch(loadAllSubscriptions())
        if (selectedSubscribe) {
            dispatch(loadMainModules(selectedSubscribe.value))
        }
    }, [selectedSubscribe])

    const handleInputChange = (i, status, name) => {
        let list = [...menuItems];
        list[i].status = !status;
        let attMenu = [...submenuAtt]
        let clmMenu = [...submenuClm]
        let stgMenu = [...submenuStg]
        if (name === "Attendance" && !status == true) {
            attMenu.map(item => {
                item.status = true
            })
        } else {
            attMenu.map(item => {
                item.status = false
            })
        }
        if (name === "Claims" && !status == true) {
            clmMenu.map(item => {
                item.status = true
            })
        } else {
            clmMenu.map(item => {
                item.status = false
            })
        }
        if (name === "Settings" && !status == true) {
            stgMenu.map(item => {
                item.status = true
            })
        } else {
            stgMenu.map(item => {
                item.status = false
            })
        }
        setMenuItems(list)
    }

    const subMenuInputChange = (i, status, parent) => {
        let menulist = [...menuItems]
        if (parent === "Attendance") {
            let list = [...submenuAtt];
            list[i].status = !status;
            setSubmenuAtt(list)
            let isFound = list.some(element => {
                if (element.status === true) {
                    return true;
                }
                return false;
            });

            if (isFound) {

                menulist.map(item => {
                    if (item.name === parent && item.status === false) {
                        item.status = true;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.name === parent && item.status === true) {
                        item.status = false;
                    }
                })
            }
        } else if (parent === "Claims") {
            let list = [...submenuClm];
            list[i].status = !status;
            setSubmenuClm(list)
            let isFound = list.some(element => {
                if (element.status === true) {
                    return true;
                }
                return false;
            });

            if (isFound) {
                menulist.map(item => {
                    if (item.name === parent && item.status === false) {
                        item.status = true;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.name === parent && item.status === true) {
                        item.status = false;
                    }
                })
            }
        } else if (parent === "Settings") {
            let list = [...submenuStg];
            list[i].status = !status;
            setSubmenuStg(list)
            let isFound = list.some(element => {
                if (element.status === true) {
                    return true;
                }
                return false;
            });

            if (isFound) {
                menulist.map(item => {
                    if (item.name === parent && item.status === false) {
                        item.status = true;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.name === parent && item.status === true) {
                        item.status = false;
                    }
                })
            }
        }
    }

    let matrix = [
        "By google",
        "By Salesbee(GPS)",
        "By manual",
        "By odo meter"
    ]

    const [matrixValue, setMatrixValue] = useState('')
    const [moduleData, setModuleData] = useState('')
    const [moduleId, setModuleId] = useState('')

    const navigateToModules = (data, moduleId) => {
        setModuleData(data)
        setModuleId(moduleId)
    }

    return (
        <div id="main-page">
            {/* top bar and sidebar start */}
            <div className="top-and-sidebar">
                <Sidenavbar
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />

                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
            </div>
            {/* top bar and sidebar end */}

            {/* page content start */}
            {!moduleData ?
                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <div className="user-table">
                                <div className="module-page-header">
                                    <div className="page-title">
                                        Subscription Module
                                    </div>
                                    <div className="page-top-bar-dropdowns">
                                        <Select
                                            className="react-select-container-list"
                                            classNamePrefix="react-select-list"
                                            options={userList}
                                            placeholder="Select Subscription Id"
                                            // value={filterUserId === "" ? "" : userList.value}
                                            // onInputChange={userList.label}
                                            onChange={setFilterUserId}
                                            isSearchable={true}
                                        />
                                    </div>
                                </div>
                                {selectedSubscribe &&
                                    <div>
                                        <Row className="sb-subscription-module" >
                                            {mainModules && mainModules.map((modules, i) => (
                                                <Col md={4} style={{ padding: "0px 45px" }} key={i}>
                                                    <Card className='sb-module-card'>
                                                        <div onClick={(e) => navigateToModules("Meeting", modules.mainModuleID)} align="center">
                                                            <Card.Img className='sb-module-card-img' variant="top" src={modules.mainModuleIcon} />
                                                            <Card.Body>
                                                                <Card.Title className='sb-card-title'>{modules.mainModuleName}</Card.Title>
                                                                <Card.Text>
                                                                    {modules.mainModuleDescription}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </div>
                                                        <Card.Body>
                                                            <div className='sb-module-footer'>
                                                                <p><i className='fa fa-desktop sb-menu-count-icon'></i>&nbsp;&nbsp;{modules.pMenuCount}</p>
                                                                <span className='sb-menu-count-devider'></span>
                                                                <input id={"module-" + i} className="css-checkbox" type="checkbox" />
                                                                <label htmlFor="module-1" name="demo_lbl_2" className="css-label"></label>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    {moduleData === "Meeting" &&
                        <MeetingManagement moduleId={moduleId} selectedSubscribe={selectedSubscribe} setModuleData={setModuleData} />}
                    {moduleData === "Project" &&
                        <ProjectManagement />}
                </>
            }
            {/* page content end */}
        </div>
    )
}

export default SuscriberPage;
