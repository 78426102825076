import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
// import { travelizeAuth } from "./HeaderAuthentication";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import {
  optionsMeetingStatusList,
  optionsMeetingTypeList,
} from "../DropdownsData";
import { SkeletonUser } from "../Skeleton Loading/SkeletonUser";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InternetIssue from "../InternetIssue";

function UserHistory(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [userList, setUserList] = useState([]);
  const [userHistory, setUserHistory] = useState([]);
  const [MOTList, setMOTList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [meetPurposeDropdown, setMeetPurposeDropdown] = useState([]);
  const [MotId, setMotId] = useState(0);
  const [meetPurposeId, setMeetPurposeId] = useState(0);

  const [filterUserId, setFilterUserId] = useState(props.userId);
  const [clientId, setClientId] = useState(0);

  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );
  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date());
  const [meetingStatus, setMeetingStatus] = useState("");
  const [meetingType, setMeetingType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Mot/BindMotDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("MOT-DROPDOWn::", res.result);
          setMOTList(
            res.result.map((data) => {
              return { value: data.motID, label: data.motName };
            })
          );
        } else {
          setMOTList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(
      `${baseURL}/api/MeetingPurpose/BindPurposeDropdown?status=${1}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("MEET-PURPOSE-DROPDOWN::", res);
          setMeetPurposeDropdown(
            res.result.map((data) => {
              return { value: data.purposeId, label: data.purpose };
            })
          );
        } else {
          setMeetPurposeDropdown([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(
      `${baseURL}/api/Client/BindClientDropdown?userId=${filterUserId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CLIENT-LIST-DROPDOWN::", res.result);
          setClientList(
            res.result.map((data) => {
              return { value: data.clientId, label: data.clientName };
            })
          );
        } else {
          setClientList([]);
        }
      });
  };
  //* FETCH DROPDOWN DATA END *//

  // GET USER HISTORY DETAIL API START
  useEffect(() => {
    getUserDetails();
  }, [
    PageSize,
    currentPage,
    filterUserId,
    clientId,
    fromDate,
    toDate,
    meetingType,
    meetingStatus,
    MotId,
    meetPurposeId,
  ]);

  const getUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Client/GetClientUserHistory?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&PurposeID=${meetPurposeId}&MotID=${MotId}&type=${meetingType}&status=${meetingStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserHistory(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setUserHistory("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // GET USER HISTORY DETAIL API END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId(props.userId);
    setMeetingType("");
    setMeetingStatus("");
    setMotId(0);
    setMeetPurposeId(0);
    setClientId(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportUserHistory = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientUserHistoryExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&PurposeID=${meetPurposeId}&MotID=${MotId}&type=${meetingType}&status=${meetingStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "UserHistory.xlsx"
    );
  };
  //* EXPORT FILE END *//

  return (
    <div>
      <div id="main-page">
        {errorMessage ? (
          <InternetIssue />
        ) : (
          <>
            {/* MAIN BODY START */}
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}
              {props.userHistoryView ? (
                <div className={`main-body ${props.toggleshift.style}`}>
                  <div className="page-content">
                    {/* USER TABLE START */}
                    <div className="user-table">
                      <div className="seleting-menus">
                        <div className="page-title">USERS HISTORY</div>
                        <div className="page-top-bar-dropdowns-2line">
                          <div className="page-top-bar-dropdowns">
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userList}
                              placeholder="Select User"
                              value={
                                filterUserId === props.userId
                                  ? ""
                                  : userList.value
                              }
                              onInputChange={userList.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={clientList}
                              placeholder="Select Client"
                              value={clientId === 0 ? "" : clientList.value}
                              onInputChange={clientList.label}
                              onChange={(data) => setClientId(data.value)}
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={MOTList}
                              placeholder="Select MOT"
                              value={MotId === 0 ? "" : MOTList.value}
                              onInputChange={MOTList.label}
                              onChange={(data) => setMotId(data.value)}
                              isSearchable={true}
                            />
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={meetPurposeDropdown}
                              placeholder="Select Purpose"
                              value={
                                meetPurposeId === 0
                                  ? ""
                                  : meetPurposeDropdown.value
                              }
                              onInputChange={meetPurposeDropdown.label}
                              onChange={(data) => setMeetPurposeId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div className="page-top-bar-dropdowns">
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={optionsMeetingStatusList}
                              placeholder="Select Status"
                              value={
                                meetingStatus === ""
                                  ? ""
                                  : optionsMeetingStatusList.value
                              }
                              onInputChange={optionsMeetingStatusList.label}
                              onChange={(data) => setMeetingStatus(data.value)}
                              isSearchable={true}
                            />

                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={optionsMeetingTypeList}
                              placeholder="Meeting Type"
                              value={
                                meetingType === ""
                                  ? ""
                                  : optionsMeetingTypeList.value
                              }
                              onInputChange={optionsMeetingTypeList.label}
                              onChange={(data) => setMeetingType(data.value)}
                              isSearchable={true}
                            />

                            <div className="input-group from-to-date-select">
                              <span
                                className="input-group-text"
                                id="from-label"
                              >
                                From
                              </span>
                              <span className="date-picker-with-icon">
                                <DatePicker
                                  selected={fromDate}
                                  className="select from-date"
                                  id="from-datepicker"
                                  dateFormat="dd/MM/yyyy"
                                  selectsEnd
                                  // startDate={fromDate}
                                  endDate={toDate}
                                  onChange={(date) => setFromDate(date)}
                                  maxDate={new Date()}
                                />
                                <i className="fa fa-calendar-o calander-icon" />
                              </span>

                              <span className="input-group-text" id="to-label">
                                To
                              </span>
                              <span className="date-picker-with-icon">
                                <DatePicker
                                  selected={toDate}
                                  className="select to-date"
                                  id="from-datepicker"
                                  dateFormat="dd/MM/yyyy"
                                  selectsEnd
                                  // startDate={fromDate}
                                  endDate={toDate}
                                  minDate={fromDate}
                                  onChange={(date) => setToDate(date)}
                                  maxDate={new Date()}
                                />
                                <i className="fa fa-calendar-o calander-icon" />
                              </span>
                            </div>

                            <button
                              type="button"
                              className="btn btn-search-track"
                              onClick={clearSerachBySelect}
                            >
                              <span className="btn-inline-item d-flex">
                                <i className="fa fa-eraser"> </i>
                                <h6>Clear</h6>
                              </span>
                            </button>

                            <button
                              type="button"
                              className="btn btn-add-user"
                              onClick={props.userHistoryView}
                              style={{ marginLeft: "5px" }}
                            >
                              <span className="btn-inline-item d-flex">
                                <i className="fa fa-arrow-left"> </i>
                                <h6>Back</h6>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive users-scrollbar">
                        <table className="table users-list-table">
                          <thead className="thead-light users-list-thead">
                            <tr>
                              <th>USER NAME</th>
                              <th>MEETING</th>
                              <th>COMPANY</th>
                              <th>LOCATION</th>
                              <th>OTHERS</th>
                              <th>REMARKS</th>
                            </tr>
                          </thead>
                          <tbody className="user-table-data">
                            {isLoading ? (
                              <>
                                <SkeletonUser />
                                <SkeletonUser />
                                <SkeletonUser />
                                <SkeletonUser />
                              </>
                            ) : userHistory !== "" ? (
                              userHistory?.map((data, i) => (
                                <tr key={i}>
                                  <td>
                                    <span className="fz13">
                                      <b>{data?.userFullName}</b>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="tb-td-sh"> Date : </span>
                                    {data?.meetingDate}
                                    <br />
                                    <span className="tb-td-sh">Time : </span>
                                    {data?.meetingTime}
                                    <br />
                                    <span className="tb-td-sh">Type : </span>
                                    {data?.meetingType}
                                    <br />
                                    <span className="tb-td-sh">Status : </span>
                                    {data?.meetingStatus}
                                  </td>
                                  <td>
                                    <b>{data?.clientName}</b>
                                    <br />
                                    <span className="tb-td-sh">
                                      Person Name :{" "}
                                    </span>
                                    {data?.contactPersonName}
                                    <br />
                                    <span className="tb-td-sh">
                                      Person Number :{" "}
                                    </span>
                                    {data?.clientContact}
                                  </td>
                                  <td style={{ maxWidth: "150px" }}>
                                    <i className="bi bi-geo-alt" /> &nbsp;
                                    {data?.clientLocation}
                                  </td>
                                  <td>
                                    <span className="tb-td-sh">Purpose : </span>
                                    {data?.purpose}
                                    <br />
                                    <span className="tb-td-sh">MOT :</span>{" "}
                                    {data?.travelBy}
                                    <br />
                                    <span className="tb-td-sh">
                                      Distance :{" "}
                                    </span>
                                    {data?.kmsTravelled}
                                  </td>
                                  <td>
                                    {data?.lessRemarks === "NA" ? (
                                      data?.lessRemarks
                                    ) : (
                                      <OverlayTrigger
                                        placement={"left"}
                                        delay={{ show: 250, hide: 300 }}
                                        overlay={
                                          <Tooltip id={`tooltip-remarks`}>
                                            {data?.remarks}
                                          </Tooltip>
                                        }
                                      >
                                        <div>{data?.lessRemarks}</div>
                                      </OverlayTrigger>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <h6 className="no-data-found">
                                ------ NO DATA FOUND ------
                              </h6>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    <div className="download-and-pagination">
                      <div className="data-count-per-page">
                        {pageDataFrom} - {pageDataTill} of {totalData}
                      </div>
                      <button
                        type="button"
                        className="btn btn-download-excel"
                        onClick={exportUserHistory}
                      >
                        <i className="fa fa-download" /> &nbsp; Download
                      </button>
                      <span className="user-pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fa fa-angle-double-left" />
                          }
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />
                      </span>
                    </div>
                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserHistory;
